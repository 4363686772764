import icon from '../../../src/assets/images/ppi.png'

export interface Token {
  name: string
  symbol: string
  address: string
  icon: string
  decimals: number
  description?: string
  id?: number // An optional `id` can be used when the same ido token need auction more then once.
}

const PPI = {
  address: '0x09e3eF7df7e2466f0443154C7367e98237d45A22',
  name: 'The Swappi Token!!!',
  symbol: 'PPI',
  decimals: 18,
  icon,
  description: 'The next generation of Swappi token.',
  webUrl: 'https://swappi.io/',
  telegramUrl: 'https://t.me/Swappi_DEX',
  twitterUrl: 'https://twitter.com/SwappiDEX',
}

export const staticTokens = [PPI]

export enum AuctionStageEnum {
  'unknown' = 0,
  'upcoming',
  'privateSale',
  'publicSale',
  'ended',
}
