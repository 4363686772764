import { Trans } from '@lingui/macro'
import { usePPIPrice } from 'hooks/usePairPrice'
import { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { classNames } from 'utils'

import ppiIcon from '../../assets/images/ppi.png'
import swappiIcon from '../../assets/images/swappi.svg'
import swappiSingleIcon from '../../assets/images/swappi-single.png'
import { isMobile } from '../../utils/userAgent'
import Menu from '../Menu'
import Row from '../Row'
import Web3Status from '../Web3Status'
import MoreLinkSelector from './MoreLinkSelector'
import NavLinkSelector from './NavLinkSelector'
import NetworkSelector from './NetworkSelector'

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  padding: 6px 10px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    justify-content: center;
    color: #4daf9e;
    background-color: #f0fcf8;
  }
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 4px;
  border-radius: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

export default function Header() {
  const [hash, setHash] = useState<string>('')

  const updateHash = useCallback(() => {
    const path = window.location.hash
    if (!path.startsWith('#/')) setHash('')
    setHash(window.location.hash.substring(1))
  }, [])

  const PPIPrice = usePPIPrice()

  useEffect(() => {
    updateHash()
    window.addEventListener('hashchange', updateHash, false)
    return () => {
      window.removeEventListener('hashchange', updateHash, false)
    }
  }, [updateHash])

  return (
    <div className="flex w-full border-b border-[#D0EABC] px-2 sm:px-4 lg:px-20 py-3.5">
      <div className="flex justify-between w-full items-center">
        <div className="flex items-center">
          <Title href=".">
            <h1 className="text-xl lg:text-2xl sm:pr-4 lg:mr-2 font-medium dark:text-white text-ink-green">
              <div className="h-full min-w-[80px]">
                <img className="hidden sm:inline-block w-full h-full" src={swappiIcon} alt="logo" />
                <img className="sm:hidden h-[36px]" src={swappiSingleIcon} alt="logo" />
              </div>
            </h1>
          </Title>

          {isMobile && (
            <HeaderLinks className="block md:!hidden !w-[91%] !shadow">
              <StyledNavLink
                id={`swap-nav-link`}
                to={'/swap'}
                className={classNames(
                  'font-normal dark:text-white p-2 text-ink-green',
                  hash === '/swap' ? 'text-bluegreen' : ''
                )}
              >
                <Trans>Exchange</Trans>
              </StyledNavLink>
              <NavLinkSelector />
              <StyledNavLink
                id={`launchpad-nav-link`}
                to={'/launchpad'}
                className={classNames(
                  'font-normal dark:text-white text-ink-green justify-center',
                  hash === '/launchpad' ? 'text-bluegreen' : ''
                )}
              >
                <Trans>Launchpad</Trans>
              </StyledNavLink>
              <MoreLinkSelector />
            </HeaderLinks>
          )}

          {!isMobile && (
            <div className="hidden space-x-8 md:flex lg:space-x-11 ml-3 mr-6 items-center">
              <NavLink
                id={`swap-nav-link`}
                to={'/swap'}
                className={classNames(
                  'font-medium dark:text-white text-ink-green',
                  hash === '/swap' ? 'text-[#7fbf4e]' : ''
                )}
              >
                <Trans>Exchange</Trans>
              </NavLink>
              <NavLink
                id={`liquidity-nav-link`}
                to={'/pool/v2'}
                className={classNames(
                  'font-medium dark:text-white text-ink-green',
                  hash === '/pool/v2' ? 'text-[#7fbf4e]' : ''
                )}
              >
                <Trans>Liquidity</Trans>
              </NavLink>
              <NavLinkSelector />
              <NavLink
                id={`staking-nav-link`}
                to={'/staking'}
                className={classNames(
                  'font-medium dark:text-white text-ink-green',
                  hash === '/staking' ? 'text-[#7fbf4e]' : ''
                )}
              >
                <Trans>Staking</Trans>
              </NavLink>

              <NavLink
                id={`lottery-nav-link`}
                to={'/lottery'}
                className={classNames(
                  'font-medium dark:text-white text-ink-green',
                  hash === '/lottery' ? 'text-[#7fbf4e]' : ''
                )}
              >
                <Trans>Lottery</Trans>
              </NavLink>

              <NavLink
                id={`launchpad-nav-link`}
                to={'/launchpad'}
                className={classNames(
                  'font-medium dark:text-white text-ink-green',
                  hash === '/launchpad' ? 'text-[#7fbf4e]' : ''
                )}
              >
                <Trans>Launchpad</Trans>
              </NavLink>

              <div className="hidden">{!isMobile && <Menu />}</div>
            </div>
          )}
        </div>

        <HeaderControls className="flex items-center space-x-2">
          <HeaderElement>
            {!isMobile && (
              <div className="hidden sm:flex items-center min-w-[70px] md:min-w-[85px] mx-2">
                <img src={ppiIcon} alt="icon" className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full" />
                <span className="ml-2 font-medium text-ink-green text-[15px] whitespace-nowrap">
                  {PPIPrice ? `$ ${parseFloat(PPIPrice.toFixed(3))}` : '-'}
                </span>
              </div>
            )}
          </HeaderElement>
          <div className="sm:hidden mx-2">
            {isMobile && (
              <div className="bg-grass rounded-full w-8 h-8 relative items-center justify-center hidden">
                <Menu />
              </div>
            )}
          </div>
          <HeaderElement>
            {!isMobile && (
              <div className="hidden sm:flex ml-1.5">
                <NetworkSelector />
              </div>
            )}
            {isMobile && (
              <div className="bg-grass rounded-full w-8 h-8 relative flex items-center justify-center">
                <NetworkSelector />
              </div>
            )}
          </HeaderElement>
          <HeaderElement>
            <div>
              <Web3Status />
            </div>
          </HeaderElement>
        </HeaderControls>
      </div>
    </div>
  )
}
