import { Fragment } from 'react'
import { classNames } from 'utils'

const Timeline = ({ lineHeights, current }: { lineHeights: number[]; current: number }) => {
  return (
    <>
      <div className="flex flex-col items-center">
        {[...Array(lineHeights.length + 1).keys()].map((i: number) => (
          <Fragment key={i}>
            <div
              className={classNames(
                i === current ? 'w-[18px] h-[18px] border-[5px] border-grass' : 'w-[8px] h-[8px]',
                current !== 0 && i === 0 ? 'mt-[5px]' : '',
                'bg-bluegreen rounded-full '
              )}
            ></div>
            {i !== lineHeights.length && (
              <div
                className={classNames(
                  i < current ? 'border-solid border-bluegreen' : 'border-dashed border-grass',
                  'border-l-2'
                )}
                style={{ height: lineHeights[i] + (i === current ? 0 : 5) + (i + 1 === current ? 0 : 5) }}
              ></div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default Timeline
