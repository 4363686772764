export const transformTicketNumber = (n: number) => {
  return n === 0 ? 0 : n.toString().split('').slice(1).reverse().join('')
}

export const ballColorClasses = [
  'bg-[#71A957]',
  'bg-[#EBA337]',
  'bg-[#EEDA71]',
  'bg-[#5BD1A7]',
  'bg-[#8B9EE1]',
  'bg-[#B65FAD]',
]

const getMatches = (finalNumberArr: string[], numberArr: string[]) => {
  let matches = 0

  for (let i = 0; i < finalNumberArr.length; i++) {
    const item = finalNumberArr[i]
    if (+item === +numberArr[i]) {
      matches++
    } else {
      break
    }
  }

  return matches
}

export const getUserTickets = (userInfo: any, finalNumber?: any) => {
  const userTickets = [] as any
  const finalNumberArr = finalNumber ? finalNumber.toString().split('') : []

  userInfo[0].forEach((i: any, idx: number) => {
    const number = transformTicketNumber(+userInfo[1][idx])
    const o = {
      id: +i,
      number,
      claimed: userInfo[2][idx],
    } as any
    if (finalNumber) {
      o.matches = getMatches(finalNumberArr, number.toString().split(''))
    }
    userTickets.push(o)
  })

  return userTickets
}
