import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import QuestionHelper from 'components/QuestionHelper'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components/macro'
import { Pair } from 'uniswap-v2-sdk'

import { AutoColumn } from '../../components/Column'
import AnnouncementBannerBottom from '../../components/Header/AnnouncementBannerBottom'
import FullPositionCard from '../../components/PositionCard'
import { Dots } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { BIG_INT_ZERO } from '../../constants/misc'
import { useV2Pairs } from '../../hooks/useV2Pairs'
import { useStakingInfo } from '../../state/stake/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { ThemedText } from '../../theme'
import { isMobile } from '../../utils/userAgent'
import AppBody from '../AppBody'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function Pool() {
  const theme = useContext(ThemeContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { account, chainId } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <>
      <AppBody>
        <div className="main-container">
          <SwapHeader allowedSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} page="liquidity" />
          <div id="liquidity-page" className="bg-white p-4 rounded-3xl rounded-tr-none rounded-bl-none pt-8 relative">
            <AutoColumn gap="0" justify="center">
              <AutoColumn gap="md" style={{ width: '100%' }}>
                {!account ? (
                  <div className="mx-10 mt-2 mb-3">
                    <div className="border border-grass bg-linear-yellow py-9 rounded-xl">
                      <ThemedText.Body textAlign="center" color={theme.text3}>
                        <Trans>Oops, no liquidity found</Trans>
                      </ThemedText.Body>
                    </div>
                  </div>
                ) : v2IsLoading ? (
                  <div className="mx-10 mt-2 mb-3">
                    <div className="py-9">
                      <ThemedText.Body color={theme.text3} textAlign="center">
                        <Dots>
                          <Trans>Loading</Trans>
                        </Dots>
                      </ThemedText.Body>
                    </div>
                  </div>
                ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                  <>
                    {v2PairsWithoutStakedAmount.map((v2Pair) => (
                      <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                    ))}
                    {stakingPairs.map(
                      (stakingPair, i) =>
                        stakingPair[1] && ( // skip pairs that arent loaded
                          <FullPositionCard
                            key={stakingInfosWithBalance[i].stakingRewardAddress}
                            pair={stakingPair[1]}
                            stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                          />
                        )
                    )}
                  </>
                ) : (
                  <div className="mx-10 mt-2 mb-3">
                    <div className="border border-grass bg-linear-yellow py-9 rounded-xl">
                      <ThemedText.Body textAlign="center" color={theme.text3}>
                        <Trans>Oops, no liquidity found</Trans>
                      </ThemedText.Body>
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-4 mb-3 items-center mx-2 sm:mx-10 text-ink-green">
                  <span className="inline-flex mr-2">
                    <span className="font-medium">
                      <Trans>Don&apos;t see a pool you joined?</Trans>
                    </span>
                    <QuestionHelper
                      text={
                        <Trans>
                          If you have staked all your LP tokens in a farm, that LP pool will not show up here. To view,
                          unstake your LP token from the pool, or add more liquidity directly
                        </Trans>
                      }
                    />
                  </span>
                  <Link
                    id="find-pool-button"
                    to="/pool/v2/find"
                    className="btn btn-secondary py-0.5 px-6 !rounded-lg text-[15px]"
                  >
                    <Text fontWeight={500} fontSize={16} className="w-full text-center">
                      <Trans>Find</Trans>
                    </Text>
                  </Link>
                </div>
              </AutoColumn>
              <div className="flex col-span-1 w-full mt-4">
                <Link id="join-pool-button" to="/add/v2/CFX" className="btn btn-primary py-3 w-full">
                  <Text fontWeight={500} fontSize={16} className="w-full text-center">
                    <Trans>Add Liquidity</Trans>
                  </Text>
                </Link>
              </div>
            </AutoColumn>
            {isMobile && <AnnouncementBannerBottom />}
          </div>
        </div>
      </AppBody>
    </>
  )
}
