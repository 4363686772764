// import { formatUnits } from '@ethersproject/units'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { formatUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import FarmController from 'abi/swappi-farm/FarmController.sol/FarmController.json'
import VotingEscrow from 'abi/swappi-farm/VotingEscrow.sol/VotingEscrow.json'
import Spinner from 'components/Spinner'
import StakeModal from 'components/staking/StakeModal'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import dayjs from 'dayjs'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract, useTokenContract } from 'hooks/useContract'
import useGasPrice from 'hooks/useGasPrice'
import useIsPreview from 'hooks/useIsPreview'
import { usePPIPrice } from 'hooks/usePairPrice'
import { useScore } from 'pages/Launchpad/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { displayVal, getProviderOrSigner, numberWithCommas } from 'utils'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import CalculateIcon from '../../assets/images/calculate.png'
import AnnouncementBannerBottom from '../../components/Header/AnnouncementBannerBottom'
import { isMobile } from '../../utils/userAgent'
import AppBody from '../AppBody'

interface StakingTotalInfo {
  totalLocked: string
  totalSupply: string
  vePPITotalSupply: string
  maxTime: number
}

enum PersonalStakingStatus {
  UNKNOWN,
  UNLOCKED,
  LOCKED,
  TO_UNLOCK,
}

enum ModalMode {
  Unknown,
  CreateLock,
  IncreaseAmount,
  IncreaseUnlockTime,
}

export default function Staking() {
  const { account, chainId, library } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const gasPrice = useGasPrice()
  const farmControllerContract = useContract(
    EVM_SPACE[chainId as SupportedChainId]?.FarmController,
    FarmController.abi,
    false
  )
  const votingEscrowContract = useContract(
    EVM_SPACE[chainId as SupportedChainId]?.VotingEscrow,
    VotingEscrow.abi,
    false
  )
  const PPIContract = useTokenContract(EVM_SPACE[chainId as SupportedChainId]?.PPI, false)
  const [PPIDecimals, setDecimals] = useState(null as any)
  const [vePPIDecimals, setVeDecimals] = useState(null as any)
  const PPIPrice = usePPIPrice()

  const isPreview = useIsPreview()

  const { score: launchpadScore, setHasOutdated } = useScore(true)

  useEffect(() => {
    PPIContract?.decimals().then((res: any) => {
      setDecimals(res ? +res : null)
    })
    votingEscrowContract?.decimals().then((res: any) => {
      setVeDecimals(res ? +res : null)
    })
  }, [PPIContract, votingEscrowContract])

  const [stakingTotalInfo, setStakingTotalInfo] = useState({
    totalLocked: '0',
    totalSupply: '0',
    vePPITotalSupply: '0',
    maxTime: 0,
  } as StakingTotalInfo)

  const [lockedBalance, setLockedBalance] = useState(null as any)

  const getTotalData = useCallback(async () => {
    if (!votingEscrowContract || !PPIContract) {
      setStakingTotalInfo({
        totalLocked: '0',
        totalSupply: '0',
        vePPITotalSupply: '0',
        maxTime: 0,
      })
      return
    }
    try {
      const [totalSupply, vePPITotalSupply, maxTime, totalLocked] = await Promise.all([
        PPIContract?.totalSupply(),
        votingEscrowContract?.totalSupply(),
        votingEscrowContract?.maxTime(),
        chainId && chainId in SupportedChainId
          ? PPIContract?.balanceOf(EVM_SPACE[chainId as SupportedChainId]?.VotingEscrow)
          : Promise.resolve(Zero),
      ])
      setStakingTotalInfo({
        totalLocked: formatUnits(totalLocked.toString(), +(PPIDecimals as any)),
        totalSupply: formatUnits(totalSupply.toString(), +(PPIDecimals as any)),
        vePPITotalSupply: formatUnits(vePPITotalSupply.toString(), +(vePPIDecimals as any)),
        maxTime: +maxTime,
      })
    } catch (error) {}
  }, [votingEscrowContract, PPIContract, chainId, PPIDecimals, vePPIDecimals])

  const getLockedBalance = useCallback(async () => {
    if (!votingEscrowContract || !account) {
      setLockedBalance(null)
      return
    }
    try {
      const { amount, unlockTime } = await votingEscrowContract?.userInfo(account)
      setLockedBalance({
        lockedAmount: amount ? formatUnits(amount.toString(), PPIDecimals) : 0,
        unlockTime: +unlockTime,
      })
    } catch (error) {
      console.log(error)
    }
  }, [account, votingEscrowContract, PPIDecimals])

  useEffect(() => {
    getTotalData()
    getLockedBalance()
  }, [account, votingEscrowContract, PPIContract, PPIDecimals, getLockedBalance, getTotalData])

  const lockedBalanceUSD = useMemo(() => {
    return PPIPrice && lockedBalance?.lockedAmount
      ? numberWithCommas(parseFloat((+PPIPrice * +lockedBalance?.lockedAmount).toFixed(3).slice(0, -1)))
      : '-'
  }, [PPIPrice, lockedBalance?.lockedAmount])
  const totalLockedBalanceUSD = useMemo(() => {
    return PPIPrice && stakingTotalInfo?.totalLocked
      ? numberWithCommas(parseFloat((+PPIPrice * +stakingTotalInfo?.totalLocked).toFixed(3).slice(0, -1)))
      : ''
  }, [PPIPrice, stakingTotalInfo?.totalLocked])

  const circulatingRatio = useMemo(() => {
    const { totalLocked, totalSupply } = stakingTotalInfo || {}
    if (!+totalSupply) return '-'
    return `${((+totalLocked / +totalSupply) * 100).toFixed(2)} %`
  }, [stakingTotalInfo])

  const avgDuration = useMemo(() => {
    const { totalLocked, vePPITotalSupply, maxTime } = stakingTotalInfo || {}
    if (!+vePPITotalSupply || !+totalLocked || !maxTime) return '-'
    const avgValue: number = (+vePPITotalSupply * +maxTime) / +totalLocked
    const SECONDS_PER_DAY = 86400

    if (avgValue > SECONDS_PER_DAY * 30) return `${(avgValue / (SECONDS_PER_DAY * 30)).toFixed(2)} months`
    return `${(avgValue / (SECONDS_PER_DAY * 7)).toFixed(2)} weeks`
  }, [stakingTotalInfo])

  const stakingStatus = useMemo(() => {
    if (!lockedBalance) return PersonalStakingStatus.UNKNOWN
    const { lockedAmount, unlockTime } = lockedBalance
    if (!+lockedAmount) return PersonalStakingStatus.UNLOCKED
    if (+unlockTime <= new Date().valueOf() / 1000) return PersonalStakingStatus.TO_UNLOCK
    return PersonalStakingStatus.LOCKED
  }, [lockedBalance])
  const [isOpen, setOpenStatus] = useState(false)
  const [modalStatus, setModalStatus] = useState<ModalMode>(ModalMode.Unknown)
  const displayedUnlockedTime = useMemo(() => {
    return lockedBalance?.unlockTime ? dayjs.unix(lockedBalance.unlockTime).format('YYYY-MM-DD HH:mm:ss') : '-'
  }, [lockedBalance?.unlockTime])

  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const addTransaction = useTransactionAdder()

  const onConfirm = useCallback(
    (response, hideModal?: boolean) => {
      if (hideModal) {
        setOpenStatus(false)
        setTimeout(() => {
          setModalStatus(ModalMode.Unknown)
        }, 350)
      }
      if (response?.wait) {
        response?.wait().then(() => {
          getTotalData()
          getLockedBalance()
          setHasOutdated(true)
        })
      }
    },
    [getLockedBalance, getTotalData, setHasOutdated]
  )

  const handleUnlock = useCallback(async () => {
    const methodName = 'withdraw'
    const methodParams: any[] = []

    if (!library || !account) return

    const votingEscrowContractWriter = votingEscrowContract?.connect(getProviderOrSigner(library, account))

    const estimateGasFunction = votingEscrowContractWriter?.estimateGas[methodName]
    if (!estimateGasFunction) return
    const safeGasEstimate: BigNumber | undefined = await estimateGasFunction(...methodParams)
      .then(calculateGasMargin)
      .catch((err) => {
        console.error(`estimateGas failed`, methodName, methodParams, err)
        return undefined
      })

    setAttemptingTxn(true)
    await votingEscrowContractWriter?.[methodName](...methodParams, {
      gasLimit: safeGasEstimate,
      gasPrice,
    })
      .then((response: TransactionResponse) => {
        setAttemptingTxn(false)
        addTransaction(response, { type: TransactionType.UNLOCK })
        onConfirm(response)
      })
      .catch((err: Error) => {
        setAttemptingTxn(false)
        console.error(err)
      })
  }, [votingEscrowContract, gasPrice, addTransaction, onConfirm, library, account])

  return (
    <>
      <AppBody maxWidth="900px">
        <div className="main-container">
          <div className="flex pt-5 px-5 items-center pb-2">
            <h5 className="text-base uppercase tracking-widest text-ink-green mr-2 font-medium">
              <Trans>Staking</Trans>
            </h5>
            <p className="border-l-[3px] border-[#C8E3B3] h-3">&nbsp;</p>
            <p className="text-xs text-windy px-1 mt-[2px]">
              <Trans>Stake PPI to boost your farming.</Trans>
            </p>
          </div>

          <div id="farm-page" className="bg-white p-4 rounded-3xl rounded-tr-none rounded-bl-none relative">
            <div className="">
              <div className="py-5 px-6 border border-[#D9ECCA] flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4 rounded-xl from-[#ECFBFE] to-[#F8FECA] bg-gradient-to-r">
                <div className="flex w-full flex-1 flex-col">
                  <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                    <Trans>Total Locked PPI</Trans>
                  </label>
                  <span className="text-lg text-[#2A3D4A] font-medium break-all">
                    {numberWithCommas(parseFloat(parseFloat(stakingTotalInfo?.totalLocked).toFixed(5).slice(0, -1)))}
                  </span>
                  <span className="text-xs text-[#2A3D4A] font-medium">
                    {totalLockedBalanceUSD ? `~ $${totalLockedBalanceUSD}` : '-'}
                  </span>
                </div>
                <div className="flex w-full flex-1 items-center">
                  <div className="flex flex-col">
                    <span className="text-sm text-ink-green font-medium">
                      <Trans>{circulatingRatio} of all circulating PPI</Trans>
                    </span>
                    <span className="text-sm text-ink-green font-medium">
                      <Trans>Average lock duration: {avgDuration}</Trans>
                    </span>
                  </div>
                </div>
                {isPreview && (
                  <div className="flex flex-col justify-center w-full flex-1">
                    <div className="mx-2 flex items-center justify-between space-x-2">
                      <img className="h-16 -ml-4" src={CalculateIcon} alt="icon" />
                    </div>
                    <NavLink
                      id={`simulator`}
                      to={'/simulator'}
                      className="text-sm text-orange underline font-medium sm:mt-3"
                    >
                      <Trans>Simulate your staking strategy &rarr;</Trans>
                    </NavLink>
                  </div>
                )}
              </div>

              <div className="rounded-xl border border-[#D9ECCA] mt-4 overflow-hidden border-b-0">
                <ul>
                  <li className="text-sm border-b border-[#D9ECCA] text-center">
                    <div className="py-5 px-6 flex flex-wrap sm:flex-nowrap justify-between sm:items-center from-[#FAFAEA] to-[#FBFFDF] bg-gradient-to-r sm:space-x-4">
                      {stakingStatus === PersonalStakingStatus.LOCKED && (
                        <>
                          <div className="flex-1 flex flex-col justify-between h-auto sm:h-28">
                            <div className="flex flex-col space-between text-left space-y-0 mb-2">
                              <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                                <Trans>My Locked PPI</Trans>
                              </label>
                              <span className="text-lg text-[#2A3D4A] font-medium">
                                {numberWithCommas(
                                  parseFloat(parseFloat(lockedBalance?.lockedAmount).toFixed(5).slice(0, -1))
                                )}
                              </span>
                              <span className="text-xs text-[#2A3D4A] font-medium">
                                {lockedBalanceUSD ? `~ $${lockedBalanceUSD}` : '-'}
                              </span>
                            </div>
                            <button
                              id="staking-button-1"
                              className="btn text-xs leading-4 sm:leading-6 btn-default justify-center self-start sm:text-sm"
                              onClick={() => {
                                setModalStatus(ModalMode.IncreaseAmount)
                                setOpenStatus(true)
                              }}
                            >
                              <Trans>Lock More</Trans>
                            </button>
                          </div>
                          <div className="flex-1 flex flex-col justify-between h-auto sm:h-28">
                            <div className="flex flex-col space-between text-left space-y-1 mb-4 sm:mb-0">
                              <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                                <Trans>Unlock Time</Trans>
                              </label>
                              <span className="text-sm sm:text-md text-[#2A3D4A] font-medium">
                                {displayedUnlockedTime}
                              </span>
                            </div>
                            <button
                              id="staking-button-2"
                              className="btn text-xs leading-4 sm:leading-6 btn-default justify-center self-start sm:text-sm"
                              onClick={() => {
                                setModalStatus(ModalMode.IncreaseUnlockTime)
                                setOpenStatus(true)
                              }}
                            >
                              <Trans>Extend</Trans>
                            </button>
                          </div>
                          <div className="hidden flex-1 sm:flex justify-between ml-8 h-28">
                            {isPreview ? (
                              <div className="flex flex-col space-between text-left space-y-1 mb-4 sm:mb-0">
                                <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                                  <Trans>My Launchpad Score</Trans>
                                </label>
                                <span className="text-sm sm:text-md text-[#2A3D4A] font-medium">
                                  {displayVal(launchpadScore, 2)}
                                </span>
                                <span>Stake PPI for priority eligibility in Launchpad.</span>
                              </div>
                            ) : (
                              <div className="flex flex-col items-end justify-center">
                                <div className="mx-2 flex items-center justify-between space-x-2">
                                  <img className="h-16" src={CalculateIcon} alt="icon" />
                                </div>
                                <NavLink
                                  id={`simulator`}
                                  to={'/simulator'}
                                  className="text-sm text-orange underline font-medium mt-3"
                                >
                                  <Trans>Simulate your staking strategy &rarr;</Trans>
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {stakingStatus === PersonalStakingStatus.TO_UNLOCK && (
                        <>
                          <div className="p-2 w-1/3 flex flex-col justify-between h-auto sm:h-28">
                            <div className="flex flex-col space-between text-left space-y-1">
                              <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                                <Trans>My Locked PPI</Trans>
                              </label>
                              <span className="text-lg text-[#2A3D4A] font-medium">
                                {numberWithCommas(
                                  parseFloat(parseFloat(lockedBalance?.lockedAmount).toFixed(5).slice(0, -1))
                                )}
                              </span>
                              <span className="text-xs text-[#2A3D4A] font-medium">~${lockedBalanceUSD}</span>
                            </div>
                          </div>
                          <div className="p-2 w-2/3 sm:w-1/3 flex flex-col justify-between h-auto sm:h-28">
                            <div className="flex flex-col space-between text-left space-y-1 mb-4 sm:mb-0">
                              <label className="text-xs sm:text-sm text-ink-green font-medium">
                                <Trans>
                                  Your Staked PPI is ready to unlock. Please unlock and stake again to get boost on
                                  farming
                                </Trans>
                              </label>
                            </div>
                            <button
                              id="staking-button-3"
                              className="btn justify-center self-start bg-linear-orange-green text-[#ffffff] text-xs sm:text-sm leading-3 sm:leading-5"
                              onClick={() => handleUnlock()}
                            >
                              {attemptingTxn && <Spinner className="h-3 w-3 -ml-1 mr-1 sm:mr-3 text-white" />}
                              <Trans>Unlock</Trans>
                            </button>
                          </div>
                          <div className="hidden p-2 w-1/3 sm:flex justify-between ml-8 h-28">
                            <div className="flex flex-col items-end justify-center">
                              <div className="mx-2 flex items-center justify-between space-x-2">
                                <img className="" src={CalculateIcon} alt="icon" />
                              </div>
                              <NavLink
                                id={`simulator`}
                                to={'/simulator'}
                                className="text-sm text-orange underline font-medium mt-3"
                              >
                                <Trans>Simulate your staking strategy &rarr;</Trans>
                              </NavLink>
                            </div>
                          </div>
                        </>
                      )}
                      {(stakingStatus === PersonalStakingStatus.UNKNOWN ||
                        stakingStatus === PersonalStakingStatus.UNLOCKED) && (
                        <>
                          <div className="w-full sm:w-2/5 flex flex-col justify-between h-auto sm:h-28">
                            <div className="flex flex-col space-between text-left space-y-1 mb-4 sm:mb-0">
                              <label className="text-xs">
                                <Trans>Lock PPI token can accelerate your LP farming and get more rewards.</Trans>
                              </label>
                            </div>
                            {account ? (
                              <button
                                id="staking-button-4"
                                className="btn text-xs leading-4 sm:leading-6 flex justify-center bg-linear-orange-green text-white sm:text-[15px]"
                                onClick={() => {
                                  setModalStatus(ModalMode.CreateLock)
                                  setOpenStatus(true)
                                }}
                              >
                                <Trans>Lock PPI to boost your farming!</Trans>
                              </button>
                            ) : (
                              <button
                                id="staking-button-5"
                                className="btn text-xs leading-4 sm:leading-6 flex justify-center bg-linear-orange-green text-white sm:text-sm"
                                onClick={() => toggleWalletModal()}
                              >
                                <Trans>Connect Wallet</Trans>
                              </button>
                            )}
                          </div>
                          <div className="hidden w-3/5 sm:w-1/3 sm:flex justify-between ml-8 h-auto sm:h-28">
                            {isPreview ? (
                              <div className="flex flex-col space-between text-left space-y-1 mb-4 sm:mb-0">
                                <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                                  <Trans>My Launchpad Score</Trans>
                                </label>
                                <span className="text-sm sm:text-md text-[#2A3D4A] font-medium">
                                  {displayVal(launchpadScore, 2)}
                                </span>
                                <span>Simulate your staking strategy to boost your Launchpad score.</span>
                              </div>
                            ) : (
                              <div className="flex flex-col items-end justify-center">
                                <div className="mx-2 flex items-center justify-between space-x-2">
                                  <img className="h-16" src={CalculateIcon} alt="icon" />
                                </div>
                                <NavLink
                                  id={`simulator`}
                                  to={'/simulator'}
                                  className="text-sm text-orange underline font-medium mt-3"
                                >
                                  <Trans>Simulate your staking strategy &rarr;</Trans>
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {isMobile && (
                        <>
                          {isPreview ? (
                            <div className="sm:hidde flex flex-col space-between text-left space-y-1 mt-6">
                              <label className="text-xs sm:text-sm text-[#2A3D4A] opacity-60">
                                <Trans>My Launchpad Score</Trans>
                              </label>
                              <span className="text-sm sm:text-md text-[#2A3D4A] font-medium">
                                {displayVal(launchpadScore, 2)}
                              </span>
                              <span>Stake PPI for priority eligibility in Launchpad.</span>
                            </div>
                          ) : (
                            <div className="text-left sm:hidden w-full my-2 sm:my-0">
                              <NavLink
                                id={`simulator`}
                                to={'/simulator'}
                                className="text-sm text-orange underline font-medium mt-3"
                              >
                                <Trans>Simulate your staking strategy &rarr;</Trans>
                              </NavLink>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {isMobile && <AnnouncementBannerBottom />}
          </div>
        </div>
      </AppBody>
      <StakeModal
        isOpen={isOpen}
        disabledAmount={modalStatus === ModalMode.IncreaseUnlockTime}
        disabledLocktime={modalStatus === ModalMode.IncreaseAmount}
        currentUnlockTime={lockedBalance?.unlockTime}
        farmControllerContract={farmControllerContract}
        votingEscrowContract={votingEscrowContract}
        onConfirm={(response, hideModal) => onConfirm(response, hideModal)}
        onDismiss={() => {
          setOpenStatus(false)
          setTimeout(() => {
            setModalStatus(ModalMode.Unknown)
          }, 350)
        }}
      />
    </>
  )
}
