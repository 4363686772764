import { isESpaceMainnet, SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }


const eSpaceMainnetAddress = {
  LHKD: '0x3d712265d6969F24D42c3C7e82DDc8642053Dfe8',
  SHUI: '0xF1F6e3Aa98Bac6C13230051e452065DF299a78A7',
  sCFX: '0x1858a8d367e69cd9E23d0Da4169885a47F05f1bE',
  CFXs: '0xb747a3317259e0aafE5A242C8E3f042a4B83627a',
  UTS: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
  CFL: '0x8CBB19d5a1eBB5436795d96a9ED6D9fa0D4D0e8e',
  USX: '0x422a86f57b6b6F1e557d406331c25EEeD075E7aA',
  WMT: '0xF102A59574d58626C2C4a6c1Dce8831fE408f890',
  PHX: '0x13db4686f3D1D9ec918A70AE8fbd52f82949906C',
  cCFX: '0xA11AD495c3bF53c19368313A894bA49bc26e7f92',
  FLOKI: '0x9B36f165baB9ebe611d491180418d8De4b8f3a1f',
  HYT: '0x72952D09C19044059Ce48007B289570b3320C8b6',
  MIT: '0xD3cF900B0EC2194B418760E1494653449327692c',
  HSF: '0x0737daceA5f4E212525E7FbA2E7ECAa069DEDd28',
  NEKO: '0xBEcD75Bde87020A4f0D3084bCCE9cde794547660',
  ABC: '0x905f2202003453006EAF975699545F2e909079B8',
  XCFX: '0x889138644274a7Dc602f25A7e7D53fF40E6d0091',
  NUT: '0xFE197E7968807B311D476915DB585831B43A7E3b',
  GOL: '0x19AAe9E4269AB47FF291125b5C0c2f7296A635AB',
  WCFX: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
  FaucetETH: '0xa47f43DE2f9623aCb395CA4905746496D2014d57',
  FaucetBTC: '0x1F545487c62e5ACfEa45dcAdd9c627361d1616D8',
  FaucetUSDT: '0xfe97E85d13ABD9c1c33384E796F10B73905637cE',
  FaucetUSDC: '0x6963efed0ab40f6c3d7bda44a05dcf1437c44372',
  FaucetBNB: '0x94bd7a37d2ce24cc597e158facaa8d601083ffec',
  FaucetMULTI: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
  FaucetTAD: '0x6138c1073e3cD4a4CF71BcbC8DbF0AAF0CD8E0CA',
  FaucetAS: '0x0641422bd6d14912d2EB7977e96AB355DEAD8108',
  Multicall: '0x9f208d7226f05b4f43d0d36eb21d8545c3143685',
  Timelock: '0x114e1766968fbc8d68da268e93951524bcdf0e32',
  SwappiFactory: '0xe2a6f7c0ce4d5d300f97aa7e125455f5cd3342f5',
  SwappiRouter: '0x62b0873055bf896dd869e172119871ac24aea305',
  PPI: '0x22f41abf77905f50df398f21213290597e7414dd',
  VotingEscrowImpl: '0x5df1673fccdbfe9754469d2df890f6363a80118b',
  VotingEscrowBeacon: '0x6f52bd24a0fa500cc34fb05ad86c9ca403e851b3',
  VotingEscrow: '0xf270e44105c1270bc7a4ffedbcb699486ada7a6a',
  PPIRateImpl: '0xcba8ada2d98254440a0adfafe2481db8469fa1cc',
  PPIRateBeacon: '0xa30db713f7d09f8ce9c54c84a8ed8401b4f49547',
  PPIRate: '0x71f3136cf723825913bb2cf42b0f87f75223dfad',
  FarmControllerImpl: '0x8f706f265fa9fcdce66f106792c6a10fcb77ef98',
  FarmControllerBeacon: '0x7c9a545d4e950697954d67821beab9b9d72044fa',
  FarmController: '0xca49dbc049fca1916a1e51315b992a0d1eb308e7',
  MultiRewardPoolImpl: '0x8b10e42c45c322a5bfb9d56ac18988e0eed74d45',
  MultiRewardPoolBeacon: '0x9fe3986650c13bdf0c5d320559895da31b96ea2a',
  MultiRewardPool: '0x0e8aa3481aac931d80366494a207094f22c5f5d1',
  SwappiNFT: '0xbbdba5043a73e87533b9378e58dea577a872dc04',
  SwappiLottery: '0xa592a383d6854f89cbe6b86cf46e73527fccb8ae',
  SwappiIdoplatform: '0xF0687E877f956E347f186FcB63a4f9aD5e57a25b', // fake address

  POOLS: {
    'PPI-LP PPI-USDT PID-6': '0x2ddf0a20b99ad70aee0760f476c24a6568216ed4', // pid 6
    'PPI-LP PPI-USDC PID-9': '0x31e6ef78c73db56aab43109d50249cab1b0635ef', // pid 9
    'PPI-LP PPI-ETH PID-10': '0x4812be910bd44d0320f5966ba0e6941a7aaeccc8', // pid 10
    'PPI-LP CFX-PPI PID-3': '0x1112a6c61a2eec4bd3aec78bd5bf3396bdd37d57', // pid 3
    'PPI-LP CFX-WBTC PID-0': '0x8BBBd6150C933fcd790B4a00baB23826912c192c', // pid 0
    'PPI-LP CFX-ETH PID-1': '0x8ea70966e8F14337657BFF7f40cFB9648f79530b', // pid 1
    'PPI-LP CFX-USDT PID-2': '0x8fCf9c586D45ce7Fcf6d714CB8b6b21a13111e0B', // pid 2
    'PPI-LP CFX-USDC PID-4': '0x0736b3384531cda2f545f5449e84c6c6bcd6f01b', // pid 4
    'PPI-LP CFX-BNB PID-5': '0x8a61e6cd8eeb564ff66459a2614ce98177f48ca9', // pid 5
    'PPI-LP USDC-USDT PID-7': '0xa5dcd75c853dad730bef4ece3d20f0be7e297a6a', // pid 7
    'PPI-LP WBTC-USDT PID-8': '0x9B2e43277238d4C6a9534CAA84cf80cB076810eA', // pid 8
    'PPI-LP ETH-USDT PID-11': '0xa6943647F22Cb9De7a80D1f447dB48B0209a812A', // pid 11
    // 'PPI-LP CFX-GOL PID-13': '0x93D4Be3C0B11fe52818cD96A5686Db1E21D749ce',
    'PPI-LP CFX-NUT PID-14': '0xd9d5748CB36a81FE58F91844F4A0412502FD3105',
    'PPI-LP CFX-ABC PID-15': '0x700d841e087f4038639b214e849beab622f178c6',
    'PPI-LP PPI-ABC PID-16': '0xd3c067f9a54d4e2def17e1e827b200bde04af204',
    'PPI-LP FLOKI-USDT PID-17': '0x267698dbadc9347b8bbd78d1972cd8614c4bac83',
    'PPI-LP ABC-MIT PID-18': '0x4f2fb607FFCF336bd2936d49399f974619412AAf',
    'PPI-LP CFX-MIT PID-19': '0xbCEB03d464f0cECd9d2409e7d7514D18F78AfD7a',
    'PPI-LP CFX-HYT PID-20': '0x1E4D8E1C0a82c6e2bEAdF28C3348E1AFcd65234a',
    'PPI-LP CFX-WMT PID-21': '0xb26cf61ade2cef606c798c396b6ed82a655361e8',
    'PPI-LP USX-USDC PID-22': '0xa98b140e5612bcabcc089609d910edb31abadeaa',
    'PPI-LP CFX-GOL PID-23': '0x157D7fCcF8067eb1444c5D57b063b1F1D8C903Ad',
    'PPI-LP XCFX-PPI PID-24': '0x1a381114c948b5fc20f23702d8411ef837ca7f1d',
  },

  DUAL_POOLS: {
    'PPI-LP CFX-MIT PID-2': '0xbCEB03d464f0cECd9d2409e7d7514D18F78AfD7a',
    'PPI-LP CFX-HYT PID-3': '0x1E4D8E1C0a82c6e2bEAdF28C3348E1AFcd65234a',
    'PPI-LP CFX-MULTI PID-0': '0xC563a5d73091afA6E9AFeBc03002ED5FEB2a5c37',
    'PPI-LP CFX-CFXs PID-4': '0xf7eBc2027Ea6103EC3E57622c8E0173940e29B67',
  },
}

const eSpaceTestnetAddress = {
  LHKD: '0x3d712265d6969F24D42c3C7e82DDc8642053Dfe8',
  SHUI: '0xF1F6e3Aa98Bac6C13230051e452065DF299a78A7',
  sCFX: '0x1858a8d367e69cd9E23d0Da4169885a47F05f1bE',
  CFXs: '0xb747a3317259e0aafE5A242C8E3f042a4B83627a',
  UTS: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
  CFL: '0x8CBB19d5a1eBB5436795d96a9ED6D9fa0D4D0e8e',
  USX: '0x422a86f57b6b6F1e557d406331c25EEeD075E7aA',
  WMT: '0xF102A59574d58626C2C4a6c1Dce8831fE408f890',
  PHX: '0x13db4686f3D1D9ec918A70AE8fbd52f82949906C',
  cCFX: '0xA11AD495c3bF53c19368313A894bA49bc26e7f92',
  FLOKI: '0x9B36f165baB9ebe611d491180418d8De4b8f3a1f',
  HYT: '0x72952D09C19044059Ce48007B289570b3320C8b6',
  MIT: '0xD3cF900B0EC2194B418760E1494653449327692c',
  HSF: '0x0737daceA5f4E212525E7FbA2E7ECAa069DEDd28',
  NEKO: '0xBEcD75Bde87020A4f0D3084bCCE9cde794547660',
  ABC: '0x905f2202003453006EAF975699545F2e909079B8',
  XCFX: '0x092690013ef7aF87eaf45030906baa86b8fAA411',
  NUT: '0x48EE76131e70762DB59a37e6008ccE082805aB00',
  GOL: '0x19AAe9E4269AB47FF291125b5C0c2f7296A635AB',
  WCFX: '0x2ed3dddae5b2f321af0806181fbfa6d049be47d8',
  FaucetETH: '0xcd71270f82f319e0498ff98af8269c3f0d547c65',
  FaucetBTC: '0x54593e02c39aeff52b166bd036797d2b1478de8d',
  FaucetUSDT: '0x7d682e65efc5c13bf4e394b8f376c48e6bae0355',
  Multicall: '0xd59149a01f910c3c448e41718134baeae55fa784',
  SwappiFactory: '0x36b83e0d41d1dd9c73a006f0c1cbc1f096e69e34',
  SwappiRouter: '0x873789aaf553fd0b4252d0d2b72c6331c47aff2e',
  PPI: '0x49916ba65d0048c4bbb0a786a527d98d10a1cd2d',
  VotingEscrowImpl: '0x22f8f1963f2f0d586f931106f5dab674a4e6e529',
  VotingEscrowBeacon: '0x44ba5b080dbed9d507883590f7bac575017b5595',
  VotingEscrow: '0xb2459c6445fe94cc2d2d2aff9ffc70157f77c649',
  PPIRate: '0xf256ea0bcb5a3a3a2cef0af4d927b1900f9fc18b',
  FarmControllerImpl: '0x2e479856ac7b7d2f9e483cae23cc3293b72bc629',
  FarmControllerBeacon: '0xb076c53f5c7e493fd4cb479ba53bc42245639b15',
  FarmController: '0x13319d155e2d8f26f421214111b5592a86088878',
  Timelock: '0x76db726c7a05980f92325171b5cb9d8abbd6ec13',
  PPIRateImpl: '0x9c4ec401a6b8c44efb22821b45e37c596381ad8e',
  PPIRateBeacon: '0xc809138e70d026229a451a26e78cc8fe2f74eac1',
  FaucetUSDC: '0x34e676cC66DB8Ea20C2a42a1939b5bcf303CED72', // fake address
  FaucetBNB: '0x34e676cC66DB8Ea20C2a42a1939b5bcf303CED72', // fake address
  FaucetMULTI: '0x34e676cC66DB8Ea20C2a42a1939b5bcf303CED72', // fake address
  FaucetTAD: '0x34e676cC66DB8Ea20C2a42a1939b5bcf303CED72', // fake address
  FaucetAS: '0x34e676cC66DB8Ea20C2a42a1939b5bcf303CED72', // fake address
  MultiRewardPoolImpl: '0x6fb2f063a5f557a3c01e7466aa8547b833be7d54',
  MultiRewardPoolBeacon: '0x372d242534cdea469e38209434794a8262790975',
  MultiRewardPool: '0x7950a0f02463bcd42bd0a2932940eaed86512f81',
  SwappiNFT: '0x76d50e98bb22d8d7534293f0091ebe3642537165',
  SwappiLottery: '0x466241eb27177ae33ebad2868a07addff35681f1',
  SwappiIdoplatform: '0x336c47d196abf4a3be1e615dbc7776d0de5eff6a',

  POOLS: {
    'PPI-LP CFX-WBTC PID-0': '0x684ead642C4b77d535d76ba015690d4368217B52',
    'PPI-LP CFX-ETH PID-1': '0x21Ea4B3239912470b9200357Dda507f7C24053E2',
    'PPI-LP CFX-USDT PID-2': '0xa0897a616DeD6A5135d2ae0507bd66EA0AF1feC3',
  },
  DUAL_POOLS: {
    'PPI-LP CFX-USDT PID-0': '0xa0897a616ded6a5135d2ae0507bd66ea0af1fec3',
    'PPI-LP CFX-ETH PID-1': '0x21Ea4B3239912470b9200357Dda507f7C24053E2',
    'PPI-LP ETH-WBTC PID-2': '0x638D132F6347cbb8Db229d58F74A01F53cb5Cc84',
  },
}

export const EVM_SPACE = {
  [SupportedChainId.ESPACE]: isESpaceMainnet() ? eSpaceMainnetAddress : eSpaceTestnetAddress,
}

export const UNI_ADDRESS: AddressMap = {}
export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.ESPACE]: EVM_SPACE[SupportedChainId.ESPACE].Multicall,
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.ESPACE]: EVM_SPACE[SupportedChainId.ESPACE].SwappiFactory,
}
export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.ESPACE]: EVM_SPACE[SupportedChainId.ESPACE].SwappiRouter,
}

export const V3_ROUTER_ADDRESS: AddressMap = {}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = {}
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {}

export const TIMELOCK_ADDRESS: AddressMap = {}
export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {}
export const QUOTER_ADDRESSES: AddressMap = {}
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {}
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {}
