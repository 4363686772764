// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import HeadlessModal from 'components/Modal/HeadlessModal'

export default function Index({ onClose, item, userInfoItem, onAction }: any) {
  return (
    <>
      <HeadlessModal title={t`Round ${item.currentLotteryId}`} isOpen={true} onDismiss={onClose}>
        <span className="mt-6 text-ink-green text-opacity-60 font-normal">
          <Trans>Your ticket</Trans>
        </span>

        {userInfoItem.userTickets.map((i: any, idx: number) => (
          <div key={idx} className="border border-grass rounded-xl bg-linear-egg py-1 px-3 mt-4">
            <div className="flex items-center space-x-9">
              <span className="text-xs font-normal text-medigreen w-4">#{i.id}</span>
              <div className="flex space-x-6 sm:space-x-7 text-ink-green text-[22px] leading-[30px] font-medium">
                {[...Array(6).keys()].map((j: number) => (
                  <span key={j} className="w-[14px]">
                    {i.number.toString().split('')[j]}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}

        <span className="mt-12 text-ink-green font-normal text-base">
          <Trans>Wanna add more luck? Buy more tickets now.</Trans>
        </span>

        <button className="btn btn-primary mt-4 justify-center py-3 rounded-xl" onClick={onAction}>
          <Trans>Buy Ticket</Trans>
        </button>
      </HeadlessModal>
    </>
  )
}
