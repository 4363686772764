import { Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { classNames, displayVal } from 'utils'

import AvocadoIcon from '../../../assets/images/avocado.svg'
import AppBody from '../../../pages/AppBody'
import IdoToken from '../Components/IdoToken'
import { useIdoInfo, useScore } from '../hooks'
import { normalizeTokens, useTokens } from '../hooks/useTokens'
import type { Token } from '../utils'
import { AuctionStageEnum } from '../utils'

const PageTitle = styled.p`
  font-size: 26px;
  line-height: 48px;
  font-weight: 600;
  color: #2a3d4a;
  margin-top: -12px;
`

const MyScore = styled.p`
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #a6d379;
  &::before {
    content: '';
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background-image: url('${AvocadoIcon}');
    background-size: contain;
  }
`

const TokenList = styled.div`
  &:empty {
    &::before {
      content: 'No Result';
      font-size: 14px;
      line-height: 18px;
      color: #2a3d4a;
    }
  }
`

const Filters = ['Live', 'Upcoming', 'Finished'] as const
type Filter = typeof Filters[number]

const Launchpad = () => {
  const { data } = useTokens()
  const idoTokens = normalizeTokens(data)

  const score = useScore()
  const [currentFilter, _setCurrentFilter] = useState(() => {
    const currentFilter = localStorage.getItem('launchpad-filter') as Filter
    if (Filters.includes(currentFilter)) {
      return currentFilter
    }
    return 'Live'
  })

  const setCurrentFilter = useCallback((filter: Filter) => {
    _setCurrentFilter(filter)
    localStorage.setItem('launchpad-filter', filter)
  }, [])

  return (
    <AppBody maxWidth="900px">
      <div className="main-container shadow-none">
        <PageTitle>IDO LAUNCHPAD</PageTitle>
        <MyScore>My Launchpad Score: {displayVal(score, 2)}</MyScore>
        <div className="mt-2 flex flex-col sm:flex-row items-start sm:items-baseline text-sm text-ink-green text-opacity-60 space-y-1 sm:space-x-1 flex-wrap break-words">
          <span>
            <Trans>Your Launchpad Score is used to participate in token pre-sales.</Trans>
          </span>
          <span>
            <Link to="staking" className="text-[#EBA337] underline tracking-wide">
              Stake PPI to increase your score{`>`}
            </Link>
          </span>
        </div>
        <div className="mt-[24px] mb-[16px] inline-flex w-[300px] p-[4px] rounded-[12px] border-[1px] border-[#AAB1B7] bg-[#F6F6F6]">
          {Filters.map((filter) => (
            <div
              key={filter}
              className={classNames(
                'flex-1 text-center px-[14px] rounded-[8px] leading-[28px] cursor-pointer',
                filter === currentFilter ? 'text-white bg-[#2A3D4A] pointer-events-none' : 'text-[#2A3D4A]'
              )}
              onClick={() => setCurrentFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>

        <TokenList>
          {!!idoTokens &&
            idoTokens.map((token) => <IDOTokenGetter key={token.address} {...token} currentFilter={currentFilter} />)}
        </TokenList>
      </div>
    </AppBody>
  )
}

const IdoTokenWrapper = styled.div`
  box-shadow: 0px 4px 20px rgba(42, 61, 74, 0.1), 0px 40px 106px 11px rgba(159, 234, 225, 0.15);
  & + & {
    margin-top: 1.5rem;
  }
`

const IDOTokenGetter = ({ currentFilter, ...token }: Token & { currentFilter: Filter }) => {
  const idoInfo = useIdoInfo(token.address)

  if (
    currentFilter === 'Live' &&
    idoInfo?.idoState?.currentStage !== AuctionStageEnum.privateSale &&
    idoInfo?.idoState?.currentStage !== AuctionStageEnum.publicSale
  )
    return null
  if (currentFilter === 'Upcoming' && idoInfo?.idoState?.currentStage !== AuctionStageEnum.upcoming) return null
  if (currentFilter === 'Finished' && idoInfo?.idoState?.currentStage !== AuctionStageEnum.ended) return null

  return (
    <IdoTokenWrapper className="block rounded-2xl rounded-tr-none rounded-bl-none bg-transparent">
      <IdoToken {...idoInfo} source="List" />
    </IdoTokenWrapper>
  )
}

export default Launchpad
