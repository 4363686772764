import { BigNumber } from '@ethersproject/bignumber'
import { splitSignature } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Currency, Percent } from '@uniswap/sdk-core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useGasPrice from 'hooks/useGasPrice'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ArrowDown, Plus } from 'react-feather'
import { RouteComponentProps } from 'react-router'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components/macro'

import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencyLogo from '../../components/CurrencyLogo'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import Slider from '../../components/Slider'
import { Dots } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { usePairContract, useV2RouterContract } from '../../hooks/useContract'
import useDebouncedChangeHandler from '../../hooks/useDebouncedChangeHandler'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/burn/actions'
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../state/burn/hooks'
import { TransactionType } from '../../state/transactions/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { currencyMapping } from '../../utils'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../utils/calculateSlippageAmount'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { ClickableText, MaxButton } from '../Pool/styleds'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)
const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const [currencyA, currencyB] = [
    currencyMapping(useCurrency(currencyIdA), chainId) ?? undefined,
    currencyMapping(useCurrency(currencyIdB), chainId) ?? undefined,
  ]
  const [tokenA, tokenB] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped], [currencyA, currencyB])

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const gasPrice = useGasPrice()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE)

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  const router = useV2RouterContract()

  // allowance handling
  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const { gatherPermitSignature, signatureData } = useV2LiquidityTokenPermit(
  //   parsedAmounts[Field.LIQUIDITY],
  //   router?.address
  // )
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], router?.address)

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    // try to gather a signature for permission
    const nonce = await pairContract.nonces(account)

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ]
    const domain = {
      name: 'Swappi LPs',
      version: '1',
      chainId,
      verifyingContract: pair.liquidityToken.address,
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ]
    const message = {
      owner: account,
      spender: router?.address,
      value: liquidityAmount.quotient.toString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toNumber(),
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: 'Permit',
      message,
    })

    library
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then((signature) => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: deadline.toNumber(),
        })
      })
      .catch((err) => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (err?.code !== 4001) {
          approveCallback()
        }
      })
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, value: string) => {
      setSignatureData(null)
      return _onUserInput(field, value)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  )
  const onCurrencyAInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
    [onUserInput]
  )
  const onCurrencyBInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
    [onUserInput]
  )

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    if (!chainId || !library || !account || !deadline || !router) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB.isNative
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[], args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((err) => {
            console.error(`estimateGas failed`, methodName, args, err)
            return undefined
          })
      )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
        gasPrice,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            type: TransactionType.REMOVE_LIQUIDITY_V2,
            baseCurrencyId: currencyId(currencyA),
            quoteCurrencyId: currencyId(currencyB),
            expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? '0',
            expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? '0',
          })

          setTxHash(response.hash)
        })
        .catch((err: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(err)
        })
    }
  }

  function modalHeader() {
    return (
      <AutoColumn gap="20px">
        <div className="border border-grass bg-linear-yellow p-4 rounded-xl mt-5 flex flex-col space-y-1">
          <RowBetween align="flex-end">
            <Text fontSize={22} fontWeight={500} className="text-ink-green">
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </Text>
            <RowFixed gap="">
              <CurrencyLogo currency={currencyA} size={'18px'} />
              <Text
                className="text-ink-green text-opacity-80"
                fontSize={18}
                fontWeight={500}
                style={{ marginLeft: '10px' }}
              >
                {currencyA?.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowFixed>
            <Plus size="14" color={theme.text2} />
          </RowFixed>
          <RowBetween align="flex-end">
            <Text fontSize={22} fontWeight={500} className="text-ink-green">
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </Text>
            <RowFixed gap="">
              <CurrencyLogo currency={currencyB} size={'18px'} />
              <Text
                className="text-ink-green text-opacity-80"
                fontSize={18}
                fontWeight={500}
                style={{ marginLeft: '10px' }}
              >
                {currencyB?.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
        </div>

        <div className="text-xs text-ink-green">
          <Trans>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </Trans>
        </div>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
        <div className="border border-grass rounded-xl p-4 mt-4">
          <RowBetween>
            <Text className="text-ink-green text-opacity-100 text-sm" fontWeight={500}>
              <Trans>
                PPI {currencyA?.symbol}/{currencyB?.symbol} Burned
              </Trans>
            </Text>
            <RowFixed>
              <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin={true} />
              <Text className="text-ink-green text-opacity-100 text-sm !ml-1" fontWeight={500}>
                {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
              </Text>
            </RowFixed>
          </RowBetween>
          {pair && (
            <>
              <div className="text-sm text-ink-green flex justify-between mt-1">
                <Trans>Price</Trans>
                <div className="text-ink-green">
                  1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                  {currencyB?.symbol}
                </div>
              </div>
              <div className="text-sm text-ink-green flex justify-between">
                <div />
                <div className="text-ink-green">
                  1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                  {currencyA?.symbol}
                </div>
              </div>
            </>
          )}
        </div>
        <ButtonPrimary
          className="btn btn-primary"
          disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
          onClick={onRemove}
        >
          <Text>
            <Trans>Confirm</Trans>
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = (
    <Trans>
      Removing {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} {currencyA?.symbol} and
      {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} {currencyB?.symbol}
    </Trans>
  )

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      WRAPPED_NATIVE_CURRENCY[chainId] &&
      (currencyA?.equals(WRAPPED_NATIVE_CURRENCY[chainId]) || currencyB?.equals(WRAPPED_NATIVE_CURRENCY[chainId]))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/v2/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/v2/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/v2/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/v2/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  return (
    <>
      <AppBody>
        <div className="main-container">
          <SwapHeader allowedSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} page="liquidity" isShowIcon={false} />
          <div id="remove-liquidity-page" className="bg-white rounded-3xl rounded-tr-none rounded-bl-none">
            <AddRemoveTabs
              creating={false}
              adding={false}
              defaultSlippage={DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE}
            />
            <div className="px-5 py-1">
              <TransactionConfirmationModal
                isOpen={showConfirm}
                onDismiss={handleDismissConfirmation}
                attemptingTxn={attemptingTxn}
                hash={txHash ? txHash : ''}
                content={() => (
                  <ConfirmationModalContent
                    title={<Trans>You will receive</Trans>}
                    onDismiss={handleDismissConfirmation}
                    topContent={modalHeader}
                    bottomContent={modalBottom}
                  />
                )}
                pendingText={pendingText}
              />
              <AutoColumn gap="md">
                <div className="text-sm text-orange py-2">
                  <Trans>
                    <b>Tip:</b> Removing pool tokens converts your position back into underlying tokens at the current
                    rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.
                  </Trans>
                </div>
                <div className="border border-grass p-4 rounded-xl">
                  <AutoColumn gap="12px">
                    <RowBetween>
                      <Text fontWeight={500} className="text-sm">
                        <Trans>Remove Amount</Trans>
                      </Text>
                      <ClickableText
                        fontWeight={500}
                        onClick={() => {
                          setShowDetailed(!showDetailed)
                        }}
                        className="text-sm underline"
                      >
                        {showDetailed ? <Trans>Simple</Trans> : <Trans>Detailed</Trans>}
                      </ClickableText>
                    </RowBetween>
                    <Row style={{ alignItems: 'flex-end' }}>
                      <Text fontSize={24} fontWeight={600}>
                        {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                      </Text>
                    </Row>
                    {!showDetailed && (
                      <>
                        <Slider size={20} value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                        <RowBetween>
                          <MaxButton
                            onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                            width="20%"
                            className="btn btn-secondary"
                          >
                            25%
                          </MaxButton>
                          <MaxButton
                            onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                            width="20%"
                            className="btn btn-secondary"
                          >
                            50%
                          </MaxButton>
                          <MaxButton
                            onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                            width="20%"
                            className="btn btn-secondary"
                          >
                            75%
                          </MaxButton>
                          <MaxButton
                            onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                            width="20%"
                            className="btn btn-secondary"
                          >
                            Max
                          </MaxButton>
                        </RowBetween>
                      </>
                    )}
                  </AutoColumn>
                </div>
                {!showDetailed && (
                  <>
                    <ColumnCenter>
                      <ArrowDown size="16" color={theme.text2} />
                    </ColumnCenter>
                    <div className="border border-grass p-4 rounded-xl">
                      <AutoColumn gap="10px">
                        <RowBetween>
                          <RowFixed>
                            <CurrencyLogo size="18px" currency={currencyA} style={{ marginRight: '8px' }} />
                            <Text
                              fontSize={14}
                              fontWeight={500}
                              className="text-ink-green text-opacity-60"
                              id="remove-liquidity-tokena-symbol"
                            >
                              {currencyA?.symbol}
                            </Text>
                          </RowFixed>
                          <Text fontSize={14} fontWeight={500}>
                            {formattedAmounts[Field.CURRENCY_A] || '-'}
                          </Text>
                        </RowBetween>
                        <RowBetween>
                          <RowFixed>
                            <CurrencyLogo size="18px" currency={currencyB} style={{ marginRight: '8px' }} />
                            <Text
                              fontSize={14}
                              fontWeight={500}
                              className="text-ink-green text-opacity-60"
                              id="remove-liquidity-tokenb-symbol"
                            >
                              {currencyB?.symbol}
                            </Text>
                          </RowFixed>
                          <Text fontSize={14} fontWeight={500}>
                            {formattedAmounts[Field.CURRENCY_B] || '-'}
                          </Text>
                        </RowBetween>
                      </AutoColumn>
                    </div>
                  </>
                )}

                {showDetailed && (
                  <>
                    <CurrencyInputPanel
                      value={formattedAmounts[Field.LIQUIDITY]}
                      onUserInput={onLiquidityInput}
                      onMax={() => {
                        onUserInput(Field.LIQUIDITY_PERCENT, '100')
                      }}
                      showMaxButton={!atMaxAmount}
                      currency={pair?.liquidityToken}
                      pair={pair}
                      id="liquidity-amount"
                    />
                    <ColumnCenter>
                      <ArrowDown size="16" color={theme.text2} />
                    </ColumnCenter>
                    <CurrencyInputPanel
                      hideBalance={true}
                      value={formattedAmounts[Field.CURRENCY_A]}
                      onUserInput={onCurrencyAInput}
                      onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                      showMaxButton={!atMaxAmount}
                      currency={currencyA}
                      label={'Output'}
                      onCurrencySelect={handleSelectCurrencyA}
                      id="remove-liquidity-tokena"
                    />
                    <ColumnCenter>
                      <Plus size="16" color={theme.text2} />
                    </ColumnCenter>
                    <CurrencyInputPanel
                      hideBalance={true}
                      value={formattedAmounts[Field.CURRENCY_B]}
                      onUserInput={onCurrencyBInput}
                      onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                      showMaxButton={!atMaxAmount}
                      currency={currencyB}
                      label={'Output'}
                      onCurrencySelect={handleSelectCurrencyB}
                      id="remove-liquidity-tokenb"
                    />
                  </>
                )}
                {pair && (
                  <div style={{ padding: '4px 20px' }}>
                    <div className="text-sm text-ink-green text-opacity-60 flex justify-between">
                      <Trans>Price:</Trans>
                      <div className="text-ink-green text-opacity-100">
                        1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                        {currencyB?.symbol}
                      </div>
                    </div>
                    <div className="text-sm text-ink-green text-opacity-60 flex justify-between">
                      <div />
                      <div className="text-ink-green text-opacity-100">
                        1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                        {currencyA?.symbol}
                      </div>
                    </div>
                  </div>
                )}

                {pair ? (
                  <AutoColumn style={{ minWidth: '20rem', width: '100%' }} className="mb-4">
                    <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
                  </AutoColumn>
                ) : null}

                <div className="mb-4" style={{ position: 'relative' }}>
                  {!account ? (
                    <ButtonLight className="btn btn-primary" onClick={toggleWalletModal}>
                      <Trans>Connect Wallet</Trans>
                    </ButtonLight>
                  ) : (
                    <RowBetween>
                      <ButtonConfirmed
                        onClick={onAttemptToApprove}
                        confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                        disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                        mr="0.5rem"
                        fontWeight={500}
                        fontSize={16}
                        className="btn btn-primary"
                      >
                        {approval === ApprovalState.PENDING ? (
                          <Dots>
                            <Trans>Approving</Trans>
                          </Dots>
                        ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                          <Trans>Approved</Trans>
                        ) : (
                          <Trans>Approve</Trans>
                        )}
                      </ButtonConfirmed>
                      <ButtonError
                        className="btn btn-primary"
                        onClick={() => {
                          setShowConfirm(true)
                        }}
                        disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                        error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                      >
                        <Text>{error || <Trans>Remove</Trans>}</Text>
                      </ButtonError>
                    </RowBetween>
                  )}
                </div>
              </AutoColumn>
            </div>
          </div>
        </div>
      </AppBody>
    </>
  )
}
