import { AbstractConnector } from '@web3-react/abstract-connector'

import INJECTED_ICON_URL from '../assets/images/arrow-right.svg'
import FLUENT_ICON_URL from '../assets/images/fluent.svg'
// import COINBASE_ICON_URL from '../assets/images/coinbaseWalletIcon.svg'
// import FORTMATIC_ICON_URL from '../assets/images/fortmaticIcon.png'
import METAMASK_ICON_URL from '../assets/images/metamask-fox.svg'
import TOKENPOCKET_ICON_URL from '../assets/images/tokenpocket.svg'
// import PORTIS_ICON_URL from '../assets/images/portisIcon.png'
import WALLETCONNECT_ICON_URL from '../assets/images/walletConnectIcon.svg'
import OKX_ICON_URL from '../assets/images/okx.png'
import HALO_ICON_URL from '../assets/images/halo.jpg'
import { injected, walletconnect } from '../connectors'

interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
  tooltip?: string
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconURL: INJECTED_ICON_URL,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconURL: METAMASK_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  FLUENT: {
    connector: injected,
    name: 'Fluent',
    iconURL: FLUENT_ICON_URL,
    description: 'A simple and secure Conflux wallet built for Web 3',
    href: null,
    color: '#E8831D',
    tooltip:
      'MetaMask will be the first option when both Fluent and MetaMask are available. \n Enable "Priority Connections" in Fluent\'s advanced settings to make Fluent the first option.',
  },
  OKX: {
    connector: injected,
    name: 'OKX',
    iconURL: OKX_ICON_URL,
    description: 'Web3 portal, one is enough',
    href: null,
    color: '#E8831D',
    tooltip:
      'MetaMask will be the first option when both OKX and MetaMask are available. \n Enable "Default Wallet" in OKX to make OKX the first option.',
  },
  Halo: {
    connector: injected,
    name: 'Halo',
    iconURL: HALO_ICON_URL,
    description: 'Halo Wallet is a fast, secure, convenient and fun smart wallet',
    href: null,
    color: '#E8831D',
    tooltip:
      'MetaMask will be the first option when both Halo and MetaMask are available. \n Enable "Default Wallet" in Halo to make Halo the first option.',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconURL: WALLETCONNECT_ICON_URL,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  TOKEN_POCKET: {
    connector: injected,
    name: 'TokenPocket',
    iconURL: TOKENPOCKET_ICON_URL,
    description: 'Multi-chain, security and convenience, the portal of DApp.',
    href: null,
    color: '#E8831D',
  },

  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconURL: COINBASE_ICON_URL,
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5',
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconURL: COINBASE_ICON_URL,
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true,
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconURL: FORTMATIC_ICON_URL,
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconURL: PORTIS_ICON_URL,
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true,
  // },
}
