import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import { useEffect, useMemo, useState } from 'react'

import { anotherClient, blockClient, client } from '../apollo/client'

export const GET_BLOCK = gql`
  query blocks($timestampFrom: Int!, $timestampTo: Int!) {
    blocks(
      first: 1
      orderBy: timestamp
      orderDirection: asc
      where: { timestamp_gt: $timestampFrom, timestamp_lt: $timestampTo }
    ) {
      id
      number
      timestamp
    }
  }
`

const PairFields = `
  fragment PairFields on Pair {
    id
    txCount
    token0 {
      id
      symbol
      name
      totalLiquidity
      derivedETH
    }
    token1 {
      id
      symbol
      name
      totalLiquidity
      derivedETH
    }
    reserve0
    reserve1
    reserveUSD
    totalSupply
    trackedReserveETH
    reserveETH
    volumeUSD
    untrackedVolumeUSD
    token0Price
    token1Price
    createdAtTimestamp
  }
`

export const PAIRS_BULK = gql`
  ${PairFields}
  query pairs($allPairs: [Bytes]!) {
    pairs(first: 500, where: { id_in: $allPairs }, orderBy: trackedReserveETH, orderDirection: desc) {
      ...PairFields
    }
  }
`

export const PAIRS_HISTORICAL_BULK = (block: number, pairs: string[]) => {
  let pairsString = `[`
  pairs.map((pair) => {
    return (pairsString += `"${pair}"`)
  })
  pairsString += ']'
  const queryString = `
  query pairs {
    pairs(first: 200, where: {id_in: ${pairsString}}, block: {number: ${block}}, orderBy: trackedReserveETH, orderDirection: desc) {
      id
      reserveUSD
      trackedReserveETH
      volumeUSD
      untrackedVolumeUSD
    }
  }
  `

  return gql(queryString)
}

const getBlockFromTimestamp = async (timestamp: number) => {
  const result = await blockClient.query({
    query: GET_BLOCK,
    variables: {
      timestampFrom: timestamp,
      timestampTo: timestamp + 600,
    },
    fetchPolicy: 'cache-first',
  })
  return result?.data?.blocks?.[0]?.number
}

const getPairsDataByBlock = async (block: number, pairList: string[]) => {
  return await client.query({
    query: PAIRS_HISTORICAL_BULK(block, pairList),
    fetchPolicy: 'cache-first',
  })
}

const getPairsData = async (pairList: string[]) => {
  return await anotherClient.query({
    query: PAIRS_BULK,
    variables: {
      allPairs: pairList,
    },
    fetchPolicy: 'cache-first',
  })
}

export default function Index(pairsAddress: string[]) {
  const [data, setData] = useState({ pairsData: undefined } as any)

  useEffect(() => {
    ;(async () => {
      try {
        if (pairsAddress.length > 0) {
          const utcCurrentTime = dayjs()
          const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('second').unix()

          const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)

          const p0 = getPairsDataByBlock(oneDayBlock, pairsAddress)
          const p1 = getPairsData(pairsAddress)

          const [
            {
              data: { pairs: oneDayAgoPairsData },
            },
            {
              data: { pairs: currentPairsData },
            },
          ] = await Promise.all([p0, p1])

          const pairsData = currentPairsData.map((i: any) => {
            const foundInOneDayAgo = oneDayAgoPairsData.find((o: any) => o.id === i.id)
            if (foundInOneDayAgo) {
              return {
                id: i.id,
                oneDayVolumeUSD: new BigNumber(i.volumeUSD).minus(foundInOneDayAgo.volumeUSD).toNumber(),
              }
            } else {
              return {
                id: i.id,
                oneDayVolumeUSD: new BigNumber(i.volumeUSD).toNumber(),
              }
            }
          })

          setData({ pairsData })
        }
      } catch (error) {
        console.log(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => data, [data])
}
