import React from 'react'
import styled from 'styled-components/macro'
import { Z_INDEX } from 'theme'

export const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string }>`
  position: relative;
  flex: 1 1 auto;
  margin-top: ${({ margin }) => margin ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth ?? '485px'};
  width: 100%;
  background: transparent;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  z-index: ${Z_INDEX.deprecated_content};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, maxWidth, ...rest }: { children: React.ReactNode; maxWidth?: string }) {
  return (
    <BodyWrapper maxWidth={maxWidth} {...rest}>
      {children}
    </BodyWrapper>
  )
}
