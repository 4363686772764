import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import React, { useMemo } from 'react'
import { Fragment } from 'react'
import { classNames } from 'utils'

// import { isMobile } from '../../utils/userAgent'

interface ModalProps {
  isOpen: boolean
  onDismiss: () => void
  minHeight?: number | false
  maxHeight?: number
  initialFocusRef?: React.RefObject<any>
  title?: string
  footerSlot?: React.ReactNode
  children?: React.ReactNode
  onTitleClick?: null | (() => void)
}

export default function HeadlessModal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 92,
  initialFocusRef,
  title,
  children,
  onTitleClick = null,
}: ModalProps) {
  const dialogInnerStyles = useMemo(() => {
    return {
      minHeight: `${minHeight || 0}vh`,
      maxHeight: `${maxHeight}vh`,
    }
  }, [minHeight, maxHeight])
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          initialFocus={initialFocusRef}
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => onDismiss()}
        >
          <div className="min-h-screen px-4 text-center flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#12222E] bg-opacity-40 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  'text-[#2A3D4A] flex flex-col self-center w-full max-w-[425px] my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-tl-3xl overflow-y-auto',
                  title ? 'p-6' : ''
                )}
                style={dialogInnerStyles}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className={classNames(onTitleClick ? 'cursor-pointer' : '', 'text-base font-medium leading-6')}
                    onClick={() => {
                      onTitleClick && onTitleClick()
                    }}
                  >
                    {title}
                  </Dialog.Title>
                )}

                {children}
                {title && (
                  <button className="absolute top-4 right-4 btn text-sm p-2" onClick={() => onDismiss()}>
                    <XIcon className="w-5 h-5 font-light" />
                  </button>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
