import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { Pair } from 'uniswap-v2-sdk'

import PlaceholderIcon from '../../assets/images/avocado.svg'
import { BIG_INT_ZERO } from '../../constants/misc'
import { useTotalSupply } from '../../hooks/useTotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: CurrencyAmount<Token> // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.quotient, JSBI.BigInt(0)) ? (
        <div className="bg-linear-yellow border-grass text-sm rounded-xl p-4">
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={14}>
                  <Trans>Your position</Trans>
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={16} className="!ml-1">
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  {userPoolBalance ? userPoolBalance.toSignificant(8) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  <Trans>Your pool share:</Trans>
                </Text>
                <Text fontSize={14} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(8)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(8)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </div>
      ) : (
        <div className="bg-linear-yellow border-grass text-sm rounded-xl p-4 text-center">
          <div className="w-4 h-4 mx-1 inline-flex">
            <img src={PlaceholderIcon} alt="icon" />
          </div>
          <Trans>
            By adding liquidity you&apos;ll earn 0.17% of all trades on this pair proportional to your share of the
            pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </Trans>
        </div>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, stakedBalance }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <div className="border border-grass rounded-xl bg-linear-yellow p-4 overflow-hidden">
      <AutoColumn gap="12px">
        <Disclosure as="div" className="flex flex-col">
          {({ open }) => (
            <>
              <div className="flex justify-between">
                <div className="flex-1">
                  <div className="flex flex-col">
                    <div className="flex justify-between text-sm">
                      <span className="text-ink-green text-opacity-60">LP Balance</span>
                      <NavLink id="nav-to-farming" to={'/farming'} className="text-[#EBA337] underline">
                        Stake {`${currency0.symbol}/${currency1.symbol}`} LP to Earn
                      </NavLink>
                    </div>
                    <div className="flex justify-between items-center mt-0.5">
                      <span className="font-medium text-ink-green text-lg">
                        {userPoolBalance ? userPoolBalance.toSignificant(8) : '-'}
                      </span>
                      <div className="flex items-center">
                        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} />
                        <span className="text-sm text-ink-green text-opacity-60 ml-1.5">
                          {`${currency0.symbol}/${currency1.symbol}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex ml-1.5">
                  <Disclosure.Button className="btn btn-secondary text-sm !border-none !font-medium !bg-transparent !px-0.5">
                    <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5`} />
                  </Disclosure.Button>
                </div>
              </div>

              <Disclosure.Panel className="mt-4">
                <AutoColumn gap="4px">
                  {stakedBalance && (
                    <div className="flex justify-between">
                      <div className="flex">
                        <span className="text-sm text-ink-green text-opacity-60">
                          <Trans>Pool tokens in rewards pool</Trans>
                        </span>
                      </div>
                      <span className="text-sm text-ink-green">{stakedBalance.toSignificant(8)}</span>
                    </div>
                  )}

                  <div className="flex justify-between">
                    <div className="flex">
                      <CurrencyLogo size="16px" currency={currency0} />
                      <span className="text-sm text-ink-green text-opacity-60 ml-1">
                        <Trans>Pooled {currency0.symbol}</Trans>
                      </span>
                    </div>
                    <span className="text-sm text-ink-green">
                      {token0Deposited ? `${token0Deposited?.toSignificant(8)}` : '-'}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex">
                      <CurrencyLogo size="16px" currency={currency1} />
                      <span className="text-sm text-ink-green text-opacity-60 ml-1">
                        <Trans>Pooled {currency1.symbol}</Trans>
                      </span>
                    </div>
                    <span className="text-sm text-ink-green">
                      {token1Deposited ? `${token1Deposited?.toSignificant(8)}` : '-'}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex">
                      <span className="text-sm text-ink-green text-opacity-60">
                        <Trans>Share of pool</Trans>
                      </span>
                    </div>
                    <span className="text-sm text-ink-green">
                      {poolTokenPercentage ? (
                        <Trans>
                          {poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)} %
                        </Trans>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>

                  {stakedBalance && JSBI.greaterThan(stakedBalance.quotient, BIG_INT_ZERO) && (
                    <ButtonPrimary
                      padding="8px"
                      $borderRadius="8px"
                      as={Link}
                      to={`/uni/${currencyId(currency0)}/${currencyId(currency1)}`}
                      width="100%"
                    >
                      <Trans>Manage Liquidity in Rewards Pool</Trans>
                    </ButtonPrimary>
                  )}
                </AutoColumn>

                {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.quotient, BIG_INT_ZERO) && (
                  <div className="flex -m-4 mt-6">
                    <NavLink
                      to={`/add/v2/${currencyId(currency0)}/${currencyId(currency1)}`}
                      className="btn btn-default w-full justify-center !rounded-none bg-lime opacity-70"
                    >
                      <Trans>Add</Trans>
                    </NavLink>
                    <NavLink
                      to={`/remove/v2/${currencyId(currency0)}/${currencyId(currency1)}`}
                      className="btn btn-default w-full justify-center !rounded-none bg-lime"
                    >
                      <Trans>Remove</Trans>
                    </NavLink>
                  </div>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </AutoColumn>
    </div>
  )
}
