import { createMulticall } from '@uniswap/redux-multicall'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { useInterfaceMulticall } from 'hooks/useContract'
// import useBlockNumber from 'lib/hooks/useBlockNumber'
import { combineReducers, createStore } from 'redux'
import Updater from 'state/multicall/updater'

const multicall = createMulticall()
const reducer = combineReducers({ [multicall.reducerPath]: multicall.reducer })
export const store = createStore(reducer)

export default multicall
export function MulticallUpdater() {
  return <Updater />
}

// export function MulticallUpdater() {
//   const latestBlockNumber = useBlockNumber()
//   const { chainId } = useActiveWeb3React()
//   const contract = useInterfaceMulticall()
//   return <multicall.Updater chainId={chainId} latestBlockNumber={latestBlockNumber} contract={contract} />
// }
