// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useIsGenesisNftHolder } from 'hooks/useIsGenesisNftHolder'
import { darken } from 'polished'
import { useMemo } from 'react'
import { Activity } from 'react-feather'
import styled from 'styled-components/macro'

import GenesisSymbolIcon from '../../assets/images/genesis-symbol.png'
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg'
import { NetworkContextName } from '../../constants/misc'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { classNames, shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 1px;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 400;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  white-space: nowrap;
  width: fit-content;
  font-weight: 400;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

// function Sock() {
//   return (
//     <span role="img" aria-label={t`has socks emoji`} style={{ marginTop: -4, marginBottom: -4 }}>
//       🧦
//     </span>
//   )
// }

function Web3StatusInner() {
  const { account, error } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  // const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()

  const isGenesisNftHolder = useIsGenesisNftHolder()

  if (account) {
    return (
      <>
        {hasPendingTransactions ? (
          <button
            id="web3-status-pending"
            onClick={toggleWalletModal}
            className="btn btn-secondary flex items-center rounded-3xl h-[32px] bg-linear-lime !border-transparent"
          >
            <RowBetween>
              <Text className="mr-1">
                <Trans>{pending?.length} Pending</Trans>
              </Text>{' '}
              <Loader stroke="#2A3D4A" />
            </RowBetween>
          </button>
        ) : (
          <div className={classNames(isGenesisNftHolder ? 'mr-2' : '', 'relative')}>
            <button
              id="web3-status-connected"
              onClick={toggleWalletModal}
              className="btn btn-secondary flex items-center px-3 rounded-3xl h-[32px] bg-linear-lime !border-transparent hover:bg-none hover:!bg-medigreen hover:!text-white group"
            >
              <div className="flex w-[28px] h-full">
                <WalletIcon className="mr-2 w-full h-full fill-current text-ink-green group-hover:text-white" />
              </div>
              <Text className="text-base">{ENSName || shortenAddress(account)}</Text>
            </button>
            {isGenesisNftHolder && (
              <div className="absolute top-[-8px] right-[-8px] z-[-1]">
                <img src={GenesisSymbolIcon} alt="icon" className="animate-spin-slow duration-75 w-12 h-12" />
              </div>
            )}
          </div>
        )}
      </>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? <Trans>Wrong Network</Trans> : <Trans>Error</Trans>}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <button
        id="connect-wallet"
        onClick={toggleWalletModal}
        className="btn btn-primary ml-1 px-3 text-[15px] rounded-3xl h-[32px] !border-transparent"
      >
        <Text>
          <Trans>Connect Wallet</Trans>
        </Text>
      </button>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <>
      <Web3StatusInner />
      {(contextNetwork.active || active) && (
        <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
      )}
    </>
  )
}
