import { Trans } from '@lingui/macro'
import { AbstractConnector } from '@web3-react/abstract-connector'
import styled from 'styled-components/macro'
import { isMobile } from 'utils/userAgent'

import AppStoreIcon from '../../assets/images/appstore.svg'
import GooglePlayIcon from '../../assets/images/googleplay.svg'
import { injected } from '../../connectors'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import Loader from '../Loader'
import Option from './Option'

const PendingSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    width: 100%;
  }
`

const StyledLoader = styled(Loader)`
  margin-right: 1rem;
`

const LoadingMessage = styled.div<{ error?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  margin-bottom: 20px;
  color: #2a3d4a;
  border: 1px solid ${({ theme, error }) => (error ? theme.red1 : '#d9ecca')};

  & > * {
    padding: 1rem;
  }
`

const ErrorGroup = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: flex-start;
`

const LoadingWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: center;
`

export default function PendingView({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}: {
  connector?: AbstractConnector
  error?: boolean
  setPendingError: (error: boolean) => void
  tryActivation: (connector: AbstractConnector) => void
}) {
  const isMetamask = window?.ethereum?.isMetaMask

  return (
    <PendingSection>
      <LoadingMessage error={error}>
        <LoadingWrapper>
          {error ? (
            <ErrorGroup>
              <div>
                <Trans>Error connecting</Trans>
              </div>
              <button
                className="btn btn-primary font-normal text-sm px-3 py-2 ml-4"
                onClick={() => {
                  setPendingError(false)
                  connector && tryActivation(connector)
                }}
              >
                <Trans>Try Again</Trans>
              </button>
            </ErrorGroup>
          ) : (
            <>
              <StyledLoader />
              <Trans>Initializing...</Trans>
            </>
          )}
        </LoadingWrapper>
      </LoadingMessage>
      {Object.keys(SUPPORTED_WALLETS).map((key) => {
        const option = SUPPORTED_WALLETS[key]
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== 'MetaMask') {
              return null
            }
            if (!isMetamask && option.name === 'MetaMask') {
              return null
            }
          }
          return (
            <>
              {isMobile ? (
                <>
                  <Option
                    id={`connect-${key}-1`}
                    key={key}
                    color={'#E8831D'}
                    header={<Trans>Install MetaMask for iPhone</Trans>}
                    subheader={null}
                    link={'https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202'}
                    icon={AppStoreIcon}
                  />
                  <div className="mt-2"></div>
                  <Option
                    id={`connect-${key}-2`}
                    key={key}
                    color={'#E8831D'}
                    header={<Trans>Install MetaMask for Android</Trans>}
                    subheader={null}
                    link={'https://play.google.com/store/apps/details?id=io.metamask'}
                    icon={GooglePlayIcon}
                  />
                </>
              ) : (
                <Option
                  id={`connect-${key}`}
                  key={key}
                  clickable={false}
                  color={option.color}
                  header={option.name}
                  subheader={option.description}
                  icon={option.iconURL}
                />
              )}
            </>
          )
        }
        return null
      })}
    </PendingSection>
  )
}
