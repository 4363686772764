import { Currency } from '@uniswap/sdk-core'
import useCurrencyLogoURIs from 'lib/hooks/useCurrencyLogoURIs'
import React from 'react'
import styled from 'styled-components/macro'

import Logo from '../Logo'

enum ShadowColor {
  WHITE = 'white',
  BLACK = 'black',
}

const StyledLogo = styled(Logo)<{ size: string; sc: ShadowColor }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: radial-gradient(white 50%, #ffffff00 calc(75% + 1px), #ffffff00 100%);
  border-radius: 50%;
  box-shadow: 0 5px 10px -5px ${({ sc }) => (sc === ShadowColor.WHITE ? '#AAB1B7' : '#AAB1B7')};
  border: 0px solid rgba(255, 255, 255, 0);
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  ...rest
}: {
  currency?: Currency | null
  size?: string
  style?: React.CSSProperties
}) {
  const logoURIs = useCurrencyLogoURIs(currency)

  return (
    <StyledLogo
      size={size}
      sc={currency?.isNative ? ShadowColor.WHITE : ShadowColor.BLACK}
      srcs={logoURIs}
      alt={currency?.symbol}
      style={style}
      {...rest}
    />
  )
}
