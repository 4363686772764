import Footer from 'components/Footer'
import AnnouncementBanner from 'components/Header/AnnouncementBanner'
import Loader from 'components/Loader'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Suspense, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

// import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { isMobile } from '../utils/userAgent'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import DualFarming from './DualFarming'
import Farming from './Farming'
import Genesis from './Genesis'
import Launchpad from './Launchpad'
import Auction from './Launchpad/Components/Auction'
import Lottery from './Lottery'
import PoolV2 from './Pool/v2'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Simulator from './Simulator'
import Staking from './Staking'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-height: 100vh;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  align-items: center;
  flex: 1;
  z-index: 1;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4px 12px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  background: linear-gradient(116.59deg, #f5fdff -0.69%, #ecfbfe 6.24%, #fafce3 100%, #fbffcf 100%);
  position: fixed;
  top: 0;
  z-index: 2;
`

export default function App() {
  const bodyRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const announcementBannerRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldStick, setShouldStick] = useState(true)
  const [isDomReady, setIsDomReady] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const adjustFooter = useCallback(() => {
    if (!bodyRef || !bodyRef.current || !footerRef || !footerRef.current) return

    if (
      window.visualViewport.height + 10 <
      bodyRef.current.getBoundingClientRect().top + bodyRef.current.clientHeight + footerRef.current.clientHeight
    ) {
      setShouldStick(false)
    } else {
      setShouldStick(true)
    }
  }, [bodyRef, footerRef])

  useLayoutEffect(() => {
    setIsDomReady(true)
  }, [])

  const isAnnouncementBannerShown = useCallback(() => {
    return announcementBannerRef && announcementBannerRef.current && announcementBannerRef.current?.clientHeight > 0
  }, [])

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper className="flex !flex-col pt-1 sm:pt-0">
            {!isMobile && (
              <div className="hidden lg:flex" ref={announcementBannerRef}>
                <AnnouncementBanner />
              </div>
            )}
            <Header />
          </HeaderWrapper>
          <BodyWrapper
            ref={bodyRef}
            className={`relative ${isDomReady && isAnnouncementBannerShown() ? '!pt-[182px]' : '!pt-[110px]'} ${
              location.pathname.indexOf('lottery') !== -1 ? 'bg-[#FBE1D3]' : ''
            }`}
          >
            <Popups isAnnouncementActive={(isDomReady && isAnnouncementBannerShown()) || false} />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/swap" component={Swap} />

                <Route exact strict path="/pool/v2/find" component={PoolFinder} />
                <Route exact strict path="/pool/v2" component={PoolV2} />
                <Route exact strict path="/pool" component={PoolV2} />

                <Route
                  exact
                  strict
                  path="/add/v2/:currencyIdA?/:currencyIdB?"
                  component={RedirectDuplicateTokenIdsV2}
                />

                <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/farming" component={Farming} />
                <Route exact strict path="/dual-farming" component={DualFarming} />
                <Route exact strict path="/staking" component={Staking} />
                <Route exact strict path="/simulator" component={Simulator} />
                <Route exact strict path="/genesis" component={Genesis} />
                <Route exact strict path="/lottery" component={Lottery} />
                <Route exact strict path="/launchpad" component={Launchpad} />
                <Route exact strict path="/launchpad/:address" component={Auction} />

                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Suspense>
            <Footer ref={footerRef} />
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
