import { ExternalProvider } from '@ethersproject/providers'
import { CHAIN_INFO } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'

let window: any

export const setupNetwork = async (externalProvider?: ExternalProvider) => {
  const provider = externalProvider || window.ethereum
  const chainId = SupportedChainId.ESPACE

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      return true
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          const info = CHAIN_INFO[chainId]
          const param = {
            chainId: `0x${chainId.toString(16)}`,
            chainName: info.label,
            nativeCurrency: info.addNetworkInfo.nativeCurrency,
            rpcUrls: [info.addNetworkInfo.rpcUrl],
            blockExplorerUrls: [info.explorer],
            iconUrls: [info.logoUrl],
          }

          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [param],
          })
          return true
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error)
          return false
        }
      }
      return false
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined")
    return false
  }
}
