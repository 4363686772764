import { isESpaceMainnet } from 'constants/chains'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function useIsPreview() {
  const query = new URLSearchParams(useLocation().search)
  const isPreviewParam = query.get('preview') === 'true'

  const [isPreview, setIsPreview] = useState(false)

  useEffect(() => {
    const IS_PREVIEW = process.env.REACT_APP_IS_PREVIEW === 'true'
    const canPreview = isESpaceMainnet() ? isPreviewParam : true

    // Do not set back to false by purpose
    if (IS_PREVIEW && canPreview) setIsPreview(true)
  }, [isPreviewParam])

  return isPreview
}
