// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const NetworkContextName = 'NETWORK'

export const IS_IN_IFRAME = window.parent !== window

export const INITIAL_ALLOWED_SLIPPAGE = 50

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30
export const L2_DEADLINE_FROM_NOW = 60 * 5

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 25000
export const L2_TXN_DISMISS_MS = 5000

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const TWO_PERCENT = new Percent(JSBI.BigInt(200), BIPS_BASE)
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const INIT_CODE_HASH = '0xe9013b07c22e5f47a6c477cffbbef5afdb24c90dedb1e8eacd17963f07186901'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export const _9975 = JSBI.BigInt(9975)
export const _10000 = JSBI.BigInt(10000)

export const LOCK_DURATION_OPTIONS = [
  { text: t`1 week`, value: 604800 }, // 3600 * 24 * 7
  { text: t`2 weeks`, value: 1209600 }, // 3600 * 24 * 7 * 2
  { text: t`1 month`, value: 2419200 }, // 3600 * 24 * 7 * 4, 4 weeks
  { text: t`2 months`, value: 5443200 }, // 3600 * 24 * 7 * 9, 9 weeks
  { text: t`3 months`, value: 7862400 }, // 3600 * 24 * 7 * 13, 13 weeks
  { text: t`6 months`, value: 15724800 }, // 3600 * 24 * 7 * 26, 26 weeks
]

export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const STAKING_OPEN_TIME = '2022-04-15T09:00:00.000+08:00'

export const APR_SHARE_NUMBER = 2

export const FEES_RATE = 0.0017
