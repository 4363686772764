export default function Component({
  className = '',
  itemClassName = '',
  ...props
}: {
  className?: string
  itemClassName?: string
}) {
  return (
    <>
      <div className={`ph-item rounded ${className}`}>
        <div className="ph-col-12 space-y-3">
          <div className="ph-row">
            <div className={`ph-col-2 big bg-linear-gray-light ${itemClassName}`}></div>
            <div className="ph-col-8 empty big"></div>
            <div className={`ph-col-2 big bg-linear-gray-light ${itemClassName}`}></div>
          </div>
          <div className="ph-row">
            <div className={`ph-col-4 bg-linear-gray-light ${itemClassName}`}></div>
            <div className="ph-col-8 empty"></div>
          </div>
          <div className="ph-row">
            <div className={`ph-col-6 bg-linear-gray-light ${itemClassName}`}></div>
            <div className="ph-col-6 empty"></div>
          </div>
          <div className="ph-row">
            <div className={`ph-col-12 bg-linear-gray-light ${itemClassName}`}></div>
          </div>
        </div>
      </div>
    </>
  )
}
