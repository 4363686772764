import { ComponentProps, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components/macro'

import Tooltip from '../Tooltip'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 14px;
  height: 14px;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  font-size: 12px;
  background-color: white;
  border: ${({ color }) => `1px solid ${color || '#2a3d4a'}`};
  color: ${({ color, theme }) => color || theme.text2};
  opacity: 0.6;

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionMark = styled.span`
  font-size: 12px;
`

export default function QuestionHelper({
  text,
  placement = 'right',
  color,
}: {
  text: ReactNode
  size?: number
  placement?: ComponentProps<typeof Tooltip>['placement']
  color?: string
}) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4, display: 'flex', alignItems: 'center' }}>
      <Tooltip text={text} show={show} placement={placement}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close} color={color}>
          <QuestionMark>?</QuestionMark>
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
