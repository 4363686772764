import { Currency, NativeCurrency, Token } from '@uniswap/sdk-core'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import { EVM_SPACE } from './addresses'
import { SupportedChainId } from './chains'

// Do not remove for now
export const UNI: { [chainId: number]: Token } = {
  // [SupportedChainId.ESPACE]: new Token(SupportedChainId.ESPACE, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
}


export const LHKD: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].LHKD,
    9,
    'LHKD',
    'LambdaHKD'
  ),
}

export const SHUI: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].SHUI,
    18,
    'SHUI',
    'Shui Token'
  ),
}

export const WBTC: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetBTC,
    18,
    'WBTC',
    'Wrapped BTC'
  ),
}

export const ABC: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].ABC,
    18,
    'ABC',
    'ABC PoS Pool'
  ),
}

export const NEKO: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].NEKO,
    18,
    'NEKO',
    'Maneki-Neko'
  ),
}

export const CFL: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].CFL,
    18,
    'CFL',
    'CFL Pool Token'
  ),
}

export const UTS: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].UTS,
    18,
    'UTS',
    'Unitus'
  ),
}

export const GOL: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].GOL,
    18,
    'GOL',
    'Goledo'
  ),
}
export const FLOKI: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FLOKI,
    9,
    'FLOKI',
    'FLOKI'
  ),
}

export const WMT: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].WMT,
    6,
    'WMT',
    'World Mobile Token'
  ),
}

export const USX: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].USX,
    18,
    'USX',
    'dForce USD'
  ),
}

export const PHX: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].PHX,
    18,
    'PHX',
    'PHX Governance Token'
  ),
}

export const cCFX: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].cCFX,
    18,
    'cCFX',
    'PoS Compound CFX'
  ),
}

export const HYT: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].HYT,
    18,
    'HYT',
    'HydroxylToken'
  ),
}

export const MIT: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].MIT,
    18,
    'MIT',
    'MIToken'
  ),
}

export const HSF: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].HSF,
    18,
    'HSF',
    'HydraSF'
  ),
}


export const XCFX: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].XCFX,
    18,
    'XCFX',
    'X nucleon CFX'
  ),
}

export const NUT: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].NUT,
    18,
    'NUT',
    'Nucleon Governance Token'
  ),
}

export const CFXs: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].CFXs,
    18,
    'CFXs',
    'CFXs ERC20'
  ),
}

export const sCFX: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].sCFX,
    18,
    'sCFX',
    'Shui CFX'
  ),
}

export const WETH: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetETH,
    18,
    'ETH',
    'Ether'
  ),
}

export const WCFX: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].WCFX,
    18,
    'CFX',
    'Wrapped CFX'
  ),
}

export const USDT: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetUSDT,
    18,
    'USDT',
    'Tether USD'
  ),
}

export const PPI: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].PPI,
    18,
    'PPI',
    'Swappi Token'
  ),
}

export const USDC: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetUSDC,
    18,
    'USDC',
    'USD Coin'
  ),
}

export const BNB: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetBNB,
    18,
    'BNB',
    'BNB'
  ),
}

export const MULTI: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetMULTI,
    18,
    'MULTI',
    'MULTI'
  ),
}

export const TAD: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetTAD,
    18,
    'TAD',
    'TriAngle DAO'
  ),
}

export const AS: { [chainId: number]: Token } = {
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].FaucetAS,
    18,
    'AS',
    'AS'
  ),
}

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  // ...WETH9,
  [SupportedChainId.ESPACE]: new Token(
    SupportedChainId.ESPACE,
    EVM_SPACE[SupportedChainId.ESPACE].WCFX,
    18,
    'CFX',
    'Wrapped CFX'
  ),
}

class CFX extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    return WRAPPED_NATIVE_CURRENCY[this.chainId]
  }

  public constructor(chainId: number) {
    super(chainId, 18, 'CFX', 'Conflux')
  }
}

export class ExtendedEther extends CFX {
  public get wrapped(): Token {
    if (this.chainId in WRAPPED_NATIVE_CURRENCY) return WRAPPED_NATIVE_CURRENCY[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency } = {}
export function nativeOnChain(chainId: number): NativeCurrency {
  return cachedNativeCurrency[chainId] ?? (cachedNativeCurrency[chainId] = ExtendedEther.onChain(chainId))
}

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const tokensOnChains = {
  [SupportedChainId.ESPACE]: defineTokens({
    UTS: UTS[SupportedChainId.ESPACE],
    CFL: CFL[SupportedChainId.ESPACE],
    CFXs: CFXs[SupportedChainId.ESPACE],
    sCFX: sCFX[SupportedChainId.ESPACE],
    CFX: WCFX[SupportedChainId.ESPACE],
    WBTC: WBTC[SupportedChainId.ESPACE],
    ETH: WETH[SupportedChainId.ESPACE],
    USDT: USDT[SupportedChainId.ESPACE],
    PPI: PPI[SupportedChainId.ESPACE],
    USDC: USDC[SupportedChainId.ESPACE],
    BNB: BNB[SupportedChainId.ESPACE],
    MULTI: MULTI[SupportedChainId.ESPACE],
    TAD: TAD[SupportedChainId.ESPACE],
    AS: AS[SupportedChainId.ESPACE],
    GOL: GOL[SupportedChainId.ESPACE],
    FLOKI: FLOKI[SupportedChainId.ESPACE],
    HYT: HYT[SupportedChainId.ESPACE],
    MIT: MIT[SupportedChainId.ESPACE],
    HSF: HSF[SupportedChainId.ESPACE],
    XCFX: XCFX[SupportedChainId.ESPACE],
    NUT: NUT[SupportedChainId.ESPACE],
    ABC: ABC[SupportedChainId.ESPACE],
    NEKO: NEKO[SupportedChainId.ESPACE],
    PHX: PHX[SupportedChainId.ESPACE],
    cCFX: cCFX[SupportedChainId.ESPACE],
    WMT: WMT[SupportedChainId.ESPACE],
    USX: USX[SupportedChainId.ESPACE],
    SHUI: SHUI[SupportedChainId.ESPACE],
    LHKD: LHKD[SupportedChainId.ESPACE],
  }),
} as any

export const tokensOnList = {
  [SupportedChainId.ESPACE]: defineTokens({
    UTS: UTS[SupportedChainId.ESPACE],
    CFL: CFL[SupportedChainId.ESPACE],
    CFXs: CFXs[SupportedChainId.ESPACE],
    sCFX: sCFX[SupportedChainId.ESPACE],
    WBTC: WBTC[SupportedChainId.ESPACE],
    ETH: WETH[SupportedChainId.ESPACE],
    USDT: USDT[SupportedChainId.ESPACE],
    PPI: PPI[SupportedChainId.ESPACE],
    USDC: USDC[SupportedChainId.ESPACE],
    BNB: BNB[SupportedChainId.ESPACE],
    MULTI: MULTI[SupportedChainId.ESPACE],
    TAD: TAD[SupportedChainId.ESPACE],
    AS: AS[SupportedChainId.ESPACE],
    GOL: GOL[SupportedChainId.ESPACE],
    FLOKI: FLOKI[SupportedChainId.ESPACE],
    HYT: HYT[SupportedChainId.ESPACE],
    MIT: MIT[SupportedChainId.ESPACE],
    HSF: HSF[SupportedChainId.ESPACE],
    XCFX: XCFX[SupportedChainId.ESPACE],
    NUT: NUT[SupportedChainId.ESPACE],
    ABC: ABC[SupportedChainId.ESPACE],
    NEKO: NEKO[SupportedChainId.ESPACE],
    PHX: PHX[SupportedChainId.ESPACE],
    cCFX: cCFX[SupportedChainId.ESPACE],
    WMT: WMT[SupportedChainId.ESPACE],
    USX: USX[SupportedChainId.ESPACE],
    SHUI: SHUI[SupportedChainId.ESPACE],
    LHKD: LHKD[SupportedChainId.ESPACE],
  }),
} as any

export const getAllTokens = () => {
  return Object.keys(tokensOnList).reduce((memo: any, key) => {
    const tokens = tokensOnList[key]

    const token = Object.keys(tokens).reduce<{ [address: string]: any }>((memo, key) => {
      const val = tokens[key]
      memo[val.address] = { token: new WrappedTokenInfo(val) }
      return memo
    }, {})

    memo[key] = token

    return memo
  }, {})
}
