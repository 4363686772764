import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { useEffect, useState } from 'react'

import SwappiNFT from '../abi/swappi-nft/SwappiNFT.json'

export const useIsGenesisNftHolder = () => {
  const { account } = useActiveWeb3React()
  const activeWeb3React = useActiveWeb3React()
  const chainId = activeWeb3React.chainId as SupportedChainId

  const cSwappiNFT = useContract(EVM_SPACE[chainId]?.SwappiNFT, SwappiNFT.abi, false)
  const [isGenesisNftHolder, setIsGenesisNftHolder] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!cSwappiNFT || !account) {
        setIsGenesisNftHolder(false)
        return
      }

      const balanceOf = +(await cSwappiNFT.callStatic.balanceOf(account))

      setIsGenesisNftHolder(balanceOf >= 1)
    })()
  }, [account, cSwappiNFT])

  return isGenesisNftHolder
}
