import { Percent } from '@uniswap/sdk-core'
import { NavLink } from 'react-router-dom'

import { classNames } from '../../utils'
import SettingsTab from '../Settings'

export default function SwapHeader({
  allowedSlippage,
  page,
  isShowIcon = true,
}: {
  allowedSlippage: Percent
  page: string
  isShowIcon?: boolean
}) {
  return (
    <>
      <div className="flex pt-5 px-1 justify-between">
        <div className="flex justify-center w-full space-x-5 sm:space-x-6 md:space-x-20 sm:ml-10 ml-5">
          <NavLink
            id={`swap-nav-link`}
            to={'/swap'}
            className={classNames(
              page === 'swap' ? 'border-lime' : 'border-transparent',
              'font-medium tracking-widest uppercase border-b-[3px] pb-2 px-2 dark:text-white text-ink-green text-[16px]'
            )}
          >
            Exchange
          </NavLink>
          <NavLink
            id={`liquidity-nav-link`}
            to={'/pool/v2'}
            className={classNames(
              page === 'liquidity' ? 'border-lime' : 'border-transparent',
              'font-medium tracking-widest uppercase border-b-[3px] pb-2 px-2 dark:text-white text-ink-green text-[16px]'
            )}
          >
            Liquidity
          </NavLink>
        </div>
        {isShowIcon && (
          <div className="flex">
            <SettingsTab placeholderSlippage={allowedSlippage} />
          </div>
        )}
      </div>
    </>
  )
}
