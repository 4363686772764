import { isESpaceMainnet, SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.ESPACE]: isESpaceMainnet() ? `https://evm.confluxrpc.com` : `https://evmtestnet.confluxrpc.com`,
  // [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.OPTIMISM]: `https://optimism-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.OPTIMISTIC_KOVAN]: `https://optimism-kovan.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ARBITRUM_ONE]: `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ARBITRUM_RINKEBY]: `https://arbitrum-rinkeby.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
}

/**
 * This is used to call the add network RPC
 */
interface AddNetworkInfo {
  readonly rpcUrl: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
}

export enum NetworkType {
  L1,
  L2,
}

interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly addNetworkInfo: AddNetworkInfo
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
}

export type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo }

export const urlPrefix = window.location.protocol + '//' + window.location.host

export const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.ESPACE]: {
    networkType: NetworkType.L1,
    docs: 'https://conflux-chain.github.io/conflux-doc/',
    explorer: isESpaceMainnet() ? 'https://evm.confluxscan.io/' : 'https://evmtestnet.confluxscan.io/',
    infoLink: 'https://conflux-chain.github.io/conflux-doc/',
    label: isESpaceMainnet() ? 'Conflux eSpace Mainnet' : 'Conflux eSpace Testnet',
    logoUrl: `${urlPrefix}/images/conflux.svg`,
    addNetworkInfo: {
      nativeCurrency: { name: 'Conflux', symbol: 'CFX', decimals: 18 },
      rpcUrl: INFURA_NETWORK_URLS[SupportedChainId.ESPACE],
    },
  },
}
