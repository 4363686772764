import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import PlaceholderItem from 'components/Spinner/PlaceholderItem'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { FEES_RATE, MAX_INT } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { ExternalLink as LinkIcon, MinusSquare as MinusSquareIcon, PlusSquare as PlusSquareIcon } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { classNames, getAddressOrName, getProviderOrSigner, numberWithCommas } from 'utils'

import SwappiPair from '../../abi/swappi-core/SwappiPair.json'
import MultiRewardPool from '../../abi/swappi-farm/MultiRewardPool.sol/MultiRewardPool.json'
import ActionModal from '../../components/Farming/ActionModal'
import { useContract } from '../../hooks/useContract'
import DoubleCurrencyLogo from '../DoubleLogo'

export default function DualFarmingItem({ item, onAfterAction, isLoading, pairsData }: any) {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const dualFarmControllerWriter = useContract(EVM_SPACE[chainId]?.MultiRewardPool, MultiRewardPool.abi, true)
  const swappiPair = useContract(item.token, SwappiPair.abi, false)

  const addTransaction = useTransactionAdder()

  const [actionType, setActionType] = useState('deposit')
  const [isOpen, setIsOpen] = useState(false)

  const claim = useCallback(async () => {
    try {
      if (!dualFarmControllerWriter) return

      const methodName = 'deposit'
      const methodParams = [item.pid, new BigNumber(0).toString(10)]

      const gasLimit = await dualFarmControllerWriter.estimateGas[methodName](...methodParams)

      const response = await dualFarmControllerWriter[methodName](...methodParams, {
        gasLimit,
        gasPrice: undefined,
      })
      addTransaction(response, { type: TransactionType.CLAIM_PPI })

      await response.wait()
    } catch (error) {
      console.log(error)
    }
  }, [dualFarmControllerWriter, addTransaction, item.pid])

  const enableFarm = useCallback(async () => {
    try {
      if (!swappiPair || !library || !account) return
      const swappiPairWriter = swappiPair.connect(getProviderOrSigner(library, account))

      const methodName = 'approve'
      const methodParams = [EVM_SPACE[chainId].MultiRewardPool, MAX_INT]

      const gasLimit = await swappiPairWriter.estimateGas[methodName](...methodParams)

      const response = await swappiPairWriter[methodName](...methodParams, {
        gasLimit,
        gasPrice: undefined,
      })
      addTransaction(response, { type: TransactionType.FARM_APPROVE })

      await response.wait()
    } catch (error) {
      console.log(error)
    }
  }, [swappiPair, addTransaction, chainId, library, account])

  const lpRewardAPR = useMemo(() => {
    if (!pairsData || !item.pair.lpPoolTotalLiquidity) return

    const pair = pairsData.find((i: any) => i.id.toLowerCase() === item.token.toLowerCase())

    if (pair) {
      const feeShareInYear = new BigNumber(pair.oneDayVolumeUSD).times(FEES_RATE).times(365).toNumber()
      return new BigNumber(feeShareInYear).div(item.pair.lpPoolTotalLiquidity).toNumber()
    }

    return
  }, [pairsData, item.pair.lpPoolTotalLiquidity, item.token])

  const apr = useMemo(() => {
    if (!item.farmBaseAPR) return

    return item.farmBaseAPR + (lpRewardAPR || 0)
  }, [item.farmBaseAPR, lpRewardAPR])

  return (
    <>
      <Disclosure as="li" className="text-sm border-b border-grass text-center last:border-none">
        {({ open }) => (
          <>
            <div
              className={classNames(
                open ? 'border-b border-grass' : '',
                'py-3 px-2 sm:p-4 flex flex-wrap gap-1 sm:gap-2 items-center text-ink-green'
              )}
            >
              <div className="flex flex-col sm:hidden">
                <div className="text-[13px] w-[40px] sm:w-auto sm:text-base text-left sm:whitespace-nowrap font-medium">
                  {item.pair.displayName}
                </div>
                <div className="ml-3 mt-1">
                  <DoubleCurrencyLogo currency0={item.pair.token0} currency1={item.pair.token1} size={20} />
                </div>
              </div>
              <div className="hidden sm:flex ml-2 items-center font-medium w-[130px]">
                <DoubleCurrencyLogo currency0={item.pair.token0} currency1={item.pair.token1} size={20} />
                <div className="flex flex-col ml-2 items-center">
                  <div className="text-base">{item.pair.displayName}</div>
                  {item.hasEnded && (
                    <div className="flex mt-1">
                      <span className="inline-flex text-xs text-orange">
                        <Trans>Finished</Trans>
                      </span>
                      <span className="inline-flex opacity-60">
                        <QuestionHelper
                          text={<Trans>Farming is finished, please unstake your LP as soon as possible.</Trans>}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {[0, 1].map((i: number) => (
                <div key={i} className="flex flex-col w-24 min-w-[55px] flex-1 ml-1">
                  <span className="opacity-60">
                    <Trans>{`Earned (${item.rewardInfo ? item.rewardInfo[`token${i}`].symbol : '-'})`}</Trans>
                  </span>
                  <span className="mt-1">
                    {isLoading ? (
                      <PlaceholderItem className="w-16 mx-auto" />
                    ) : item.userReward && item.userReward[`token${i}`].alignedRewardAmount ? (
                      numberWithCommas(item.userReward[`token${i}`].alignedRewardAmount.toFixed(5).slice(0, -1))
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              ))}
              <div className="flex flex-col sm:w-24 min-w-[105px] flex-1 items-center">
                <span className="opacity-60">
                  <Trans>Farming APR</Trans>
                </span>

                <div className="flex items-center">
                  {isLoading ? (
                    <PlaceholderItem className="w-16 mx-auto" />
                  ) : !item.hasEnded && apr ? (
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex">
                        <span>{parseFloat((apr * 100).toFixed(1))}%</span>
                        <span className="inline-flex ml-1 mt-[-1px]">
                          <QuestionHelper
                            text={
                              <Trans>
                                Farm Base APR: {parseFloat((item.farmBaseAPR * 100).toFixed(2))}%<br />
                                LP Reward APR: {lpRewardAPR ? `${parseFloat((lpRewardAPR * 100).toFixed(2))}%` : '-'}
                              </Trans>
                            }
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <span className="inline-flex">
                        <span className="mt-1">-</span>
                      </span>
                      {item.hasEnded && (
                        <div className="flex sm:hidden">
                          <span className="inline-flex text-xs text-orange">
                            <Trans>Finished</Trans>
                          </span>
                          <span className="inline-flex opacity-60">
                            <QuestionHelper
                              text={<Trans>Farming is finished, please unstake your LP as soon as possible.</Trans>}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden sm:flex flex-col w-24 min-w-[100px] break-all flex-1">
                <span className="inline">
                  <span className="opacity-60">
                    <Trans>Liquidity</Trans>
                  </span>
                  <span className="inline-flex">
                    <QuestionHelper text={<Trans>Total value of the funds in this farm&apos;s liquidity pool</Trans>} />
                  </span>
                </span>
                <span className="mt-1">
                  {isLoading ? (
                    <PlaceholderItem className="w-16 mx-auto" />
                  ) : item.pair.liquidity ? (
                    <>${numberWithCommas(new BigNumber(item.pair.liquidity).toFixed(3).slice(0, -1))}</>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
              {account ? (
                <div className="sm:ml-2 sm:w-24">
                  <Disclosure.Button className="btn !bg-white !text-ink-green !border-none !font-medium px-1 sm:px-4 text-sm sm:mr-1">
                    <span className="hidden sm:inline">{open ? 'Hide' : 'Details'}</span>
                    <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5 font-light`} />
                  </Disclosure.Button>
                </div>
              ) : (
                <div className="flex mr-6"></div>
              )}
            </div>
            <Disclosure.Panel className="p-2 sm:p-4 flex justify-between items-start sm:items-center bg-white lg:from-[#FAFAEA] lg:to-[#FBFFDF] lg:bg-gradient-to-r">
              <div className="hidden lg:flex flex-col space-y-2">
                {chainId && (
                  <NavLink
                    to={`/add/v2/${getAddressOrName(item.pair.token0.address, chainId)}/${getAddressOrName(
                      item.pair.token1.address,
                      chainId
                    )}`}
                    className="flex space-x-1 text-xs text-[#EBA337] underline"
                    target="_blank"
                  >
                    <span>{`Get ${item.pair.displayName} LP`}</span>
                    <LinkIcon size={14} />
                  </NavLink>
                )}
                <NavLink to={'/swap'} className="flex space-x-1 text-xs text-[#EBA337] underline" target="_blank">
                  <span>{`Trade ${item.pair.displayName}`}</span>
                  <LinkIcon size={14} />
                </NavLink>
              </div>
              <div className="flex flex-col lg:block flex-1">
                <div className="rounded-xl border border-[#D9ECCA] p-3 flex justify-between lg:ml-12 h-auto">
                  {[0, 1].map((i: number) => (
                    <div key={i} className="flex flex-col text-left space-y-1 mr-1">
                      <label className="uppercase text-lime text-xs font-medium sm:font-bold">
                        <Trans>{`${item.rewardInfo ? item.rewardInfo[`token${i}`].symbol : '-'} Earned`}</Trans>
                      </label>
                      {isLoading ? (
                        <PlaceholderItem className="w-20" />
                      ) : (
                        <>
                          <span className="text-lg text-[#2A3D4A] font-medium whitespace-nowrap">
                            {item.userReward && item.userReward[`token${i}`].alignedRewardAmount
                              ? numberWithCommas(
                                  item.userReward[`token${i}`].alignedRewardAmount.toFixed(5).slice(0, -1)
                                )
                              : '-'}
                          </span>
                          <span className="text-xs text-[#2A3D4A] opacity-60 whitespace-nowrap">
                            {item.userReward && item.userReward[`token${i}`].rewardUSDValue
                              ? `~ $${numberWithCommas(
                                  parseFloat(item.userReward[`token${i}`].rewardUSDValue.toFixed(3).slice(0, -1))
                                )}`
                              : '-'}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                  <div className="hidden lg:block self-end ml-2">
                    {item.userReward &&
                    item.userReward.token0.alignedRewardAmount + item.userReward.token1.alignedRewardAmount ? (
                      <button
                        id="farming-button-3"
                        className="btn btn-default px-4 py-1"
                        onClick={async () => {
                          await claim()
                          await onAfterAction()
                        }}
                      >
                        <Trans>Claim</Trans>
                      </button>
                    ) : (
                      <span className="inline-flex">
                        <button id="farming-button-4" className="btn btn-disabled px-4 py-1 mr-1">
                          <Trans>Claim</Trans>
                        </button>
                      </span>
                    )}
                  </div>
                </div>
                <div className="lg:hidden flex flex-wrap justify-between mt-2 items-center">
                  <div className="self-end h-7 min-w-[115px] text-left">
                    {item.userReward &&
                    item.userReward.token0.alignedRewardAmount + item.userReward.token1.alignedRewardAmount > 0 ? (
                      <button
                        id="farming-button-5"
                        className="btn btn-default px-4 py-0.5 rounded text-sm h-full"
                        onClick={async () => {
                          await claim()
                          await onAfterAction()
                        }}
                      >
                        <Trans>Claim</Trans>
                      </button>
                    ) : (
                      <button id="farming-button-6" className="btn btn-disabled px-4 py-0.5 rounded text-sm h-full">
                        <Trans>Claim</Trans>
                      </button>
                    )}
                  </div>
                  <NavLink
                    to={'/swap'}
                    className="sm:ml-4 mt-1.5 flex space-x-1 text-xs text-[#EBA337] underline"
                    target="_blank"
                  >
                    <span>
                      <Trans>Trade {item.pair.displayName}</Trans>
                    </span>
                    <LinkIcon size={14} />
                  </NavLink>
                </div>
              </div>
              <div className="flex flex-col lg:block flex-1 ml-2 sm:ml-8 lg:ml-0">
                <div className="rounded-xl border border-[#D9ECCA] p-3 flex justify-between lg:ml-8 h-auto">
                  {item.pair.allowance > 0 ? (
                    new BigNumber(item.userInfo.alignedAmount).isGreaterThan(0) ? (
                      <>
                        <div className="flex flex-col text-left space-y-1 mr-1">
                          <label className="uppercase text-lime text-xs font-medium sm:font-bold">
                            <Trans>{item.pair.displayName} LP Staked</Trans>
                          </label>
                          {isLoading ? (
                            <PlaceholderItem className="w-20" />
                          ) : (
                            <>
                              <span className="text-lg text-[#2A3D4A] font-medium">
                                {item.userInfo.alignedAmount
                                  ? numberWithCommas(
                                      parseFloat(new BigNumber(item.userInfo.alignedAmount).toFixed(5).slice(0, -1))
                                    )
                                  : '-'}
                              </span>
                              <span className="text-xs text-[#2A3D4A] opacity-60">
                                {item.userInfo.amountUSDValue
                                  ? `~ $${numberWithCommas(item.userInfo.amountUSDValue.toFixed(3).slice(0, -1))}`
                                  : '-'}
                              </span>
                            </>
                          )}
                        </div>
                        <div className="hidden lg:flex self-end ml-2">
                          <button
                            id="farming-button-7"
                            className="btn btn-default !rounded-tr-none !rounded-br-none px-4 py-1 opacity-70"
                            onClick={() => {
                              setActionType('withdraw')
                              setIsOpen(true)
                            }}
                          >
                            <MinusSquareIcon />
                          </button>
                          <button
                            id="farming-button-8"
                            className={classNames(
                              item.hasEnded ? 'btn-disabled' : 'btn-default',
                              'btn !rounded-tl-none !rounded-bl-none px-4 py-1'
                            )}
                            onClick={() => {
                              if (!item.hasEnded) {
                                setActionType('deposit')
                                setIsOpen(true)
                              }
                            }}
                          >
                            <PlusSquareIcon />
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-between w-full text-left">
                        <label className="uppercase text-lime text-xs font-medium sm:font-semibold">
                          <Trans>{item.pair.displayName} LP Staked</Trans>
                        </label>
                        <button
                          id="farming-button-9"
                          className="btn btn-default flex justify-center rounded text-sm sm:px-4 sm:rounded-lg sm:text-base"
                          onClick={() => {
                            setActionType('deposit')
                            setIsOpen(true)
                          }}
                        >
                          <Trans>Stake LP</Trans>
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="flex flex-col justify-between w-full text-left">
                      <label className="uppercase text-lime text-xs font-semibold">
                        <Trans>Enable Farm</Trans>
                      </label>
                      <button
                        id="farming-button-10"
                        className="btn btn-default flex justify-center"
                        onClick={async () => {
                          await enableFarm()
                          await onAfterAction()
                        }}
                      >
                        <Trans>Enable</Trans>
                      </button>
                    </div>
                  )}
                </div>
                <div className="lg:hidden flex flex-wrap justify-between mt-2 items-center">
                  {item.pair.allowance > 0 && new BigNumber(item.userInfo.alignedAmount).isGreaterThan(0) && (
                    <div className="self-end h-7 min-w-[115px] text-left">
                      <button
                        id="farming-button-11"
                        className="btn btn-default !rounded-tr-none !rounded-br-none px-4 py-0.5 rounded text-sm opacity-70"
                        onClick={() => {
                          setActionType('withdraw')
                          setIsOpen(true)
                        }}
                      >
                        <MinusSquareIcon />
                      </button>
                      <button
                        id="farming-button-12"
                        className="btn btn-default !rounded-tl-none !rounded-bl-none px-4 py-0.5 rounded text-sm"
                        onClick={() => {
                          setActionType('deposit')
                          setIsOpen(true)
                        }}
                      >
                        <PlusSquareIcon />
                      </button>
                    </div>
                  )}
                  {chainId && (
                    <NavLink
                      to={`/add/v2/${getAddressOrName(item.pair.token0.address, chainId)}/${getAddressOrName(
                        item.pair.token1.address,
                        chainId
                      )}`}
                      className="sm:ml-4 mt-1.5 flex space-x-1 text-xs text-[#EBA337] underline"
                      target="_blank"
                    >
                      <span>
                        <Trans>Get {item.pair.displayName} LP</Trans>
                      </span>
                      <LinkIcon size={14} />
                    </NavLink>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {isOpen && (
        <ActionModal
          item={item}
          actionType={actionType}
          onClose={() => {
            setIsOpen(false)
          }}
          farmingType="dual"
          onAfterAction={onAfterAction}
        />
      )}
    </>
  )
}
