import { useCallback, useState } from 'react'

export default function useTimer() {
  // timespan will be an arr: [hours, minutes, seconds], the initial value is [], and became null when finish
  const [timespan, setTimespan] = useState<number[] | null>([])

  // endTime is a string, eg: '2022-04-15T09:00:00.000+08:00'
  const getTimespan = useCallback((endTime: string) => {
    const endTimeMills = new Date(endTime).valueOf()
    const nowMills = new Date().valueOf()
    const endTimeSeconds = (endTimeMills - nowMills) / 1000

    const hours = parseInt((endTimeSeconds / 3600).toString(), 10)
    const minutes = parseInt(((endTimeSeconds - hours * 3600) / 60).toString(), 10)
    const seconds = parseInt((endTimeSeconds - hours * 3600 - minutes * 60).toString(), 10)

    return hours + minutes + seconds <= 0 ? null : [hours, minutes, seconds]
  }, [])

  const startTimer = useCallback(
    (endTime: string) => {
      const timespan = getTimespan(endTime)
      if (!timespan) {
        setTimespan(null)
        return
      }

      setTimespan(timespan)
      const timerId = setInterval(() => {
        if (!getTimespan(endTime)) {
          setTimespan(null)

          setTimeout(() => {
            clearInterval(timerId)
          })
        } else {
          setTimespan(getTimespan(endTime))
        }
      }, 1000)

      return timerId as any
    },
    [getTimespan]
  )

  return {
    startTimer,
    timespan,
  }
}
