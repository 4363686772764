import * as constants from '@ethersproject/constants'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
// import { useSingleCallResult } from 'lib/hooks/multicall'
import { useEffect, useMemo, useState } from 'react'

import { useTokenContract } from './useContract'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.address, false)

  // const inputs = useMemo(() => [owner, spender], [owner, spender])
  // const allowance = useSingleCallResult(contract, 'allowance', inputs).result
  const [allowance, setAllowance] = useState(constants.Zero)

  // overrides allowance getter of ERC20 tokens
  useEffect(() => {
    const checkAllowance = async () => {
      if (contract && owner && spender) {
        try {
          const res = await contract.allowance(owner, spender)
          if (!res.eq(allowance)) {
            setAllowance(res)
          }
        } catch (error) {
          console.log('@@@ checkAllowance error', { owner, spender }, error)
        }
      }
    }
    checkAllowance()
  })

  return useMemo(
    () => (token && allowance ? CurrencyAmount.fromRawAmount(token, allowance.toString()) : undefined),
    [token, allowance]
  )
}
