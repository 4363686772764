import { Menu, Transition } from '@headlessui/react'
import { Trans } from '@lingui/macro'
import useIsPreview from 'hooks/useIsPreview'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { classNames } from 'utils'

export default function MoreLinkSelector() {
  const [hash, setHash] = useState<string>('')

  const isPreview = useIsPreview()

  const updateHash = useCallback(() => {
    const path = window.location.hash
    if (!path.startsWith('#/')) setHash('')
    setHash(window.location.hash.substring(1))
  }, [])

  useEffect(() => {
    updateHash()
    window.addEventListener('hashchange', updateHash, false)
    return () => {
      window.removeEventListener('hashchange', updateHash, false)
    }
  }, [updateHash])

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={classNames(
            'font-normal dark:text-white p-2 rounded-xl',
            hash === '/staking' || hash === '/lottery' || hash === '/pool/v2'
              ? 'text-[#4daf9e] bg-[#f0fcf8]'
              : 'text-ink-green'
          )}
        >
          <Trans>More</Trans>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute w-max rounded-md shadow-lg bg-white focus:outline-none top-auto left-auto right-0 bottom-[46px]">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  id={`staking-nav-link`}
                  to={'/staking'}
                  className={classNames(
                    hash === '/staking' ? 'text-[#4daf9e]' : 'text-gray-700',
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 dark:text-white text-ink-green font-normal'
                  )}
                >
                  <Trans>Staking</Trans>
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  id={`lottery-nav-link`}
                  to={'/lottery'}
                  className={classNames(
                    hash === '/lottery' ? 'text-[#4daf9e]' : 'text-gray-700',
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 dark:text-white text-ink-green font-normal'
                  )}
                >
                  <Trans>Lottery</Trans>
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  id={`liquidity-nav-link`}
                  to={'/pool/v2'}
                  className={classNames(
                    hash === '/pool/v2' ? 'text-[#4daf9e]' : 'text-gray-700',
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 dark:text-white text-ink-green font-normal'
                  )}
                >
                  <Trans>Liquidity</Trans>
                </NavLink>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
