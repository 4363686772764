import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { useIsGenesisNftHolder } from 'hooks/useIsGenesisNftHolder'
import useTimer from 'hooks/useTimer'
import { useCallback, useEffect, useState } from 'react'

import ERC20 from '../../../abi/swappi-core/ERC20.json'
import VotingEscrow from '../../../abi/swappi-farm/VotingEscrow.sol/VotingEscrow.json'
import SwappiIdoplatform from '../../../abi/swappi-idoplatform/idoplatform.sol/idoplatform.json'
import { EVM_SPACE } from '../../../constants/addresses'
import { SupportedChainId } from '../../../constants/chains'
import { getTimeString, getUnixTimeSecond } from '../../../utils'
import { AuctionStageEnum } from '../utils'
import { useToken } from './useTokens'

let timerId = null as any

const getCurrentStage = (idoState: any) => {
  const privateSaleTime = +idoState.priSaleInfo?.startTime
  const publicSaleTime = +idoState.priSaleInfo?.endTime
  const endTime = +idoState.pubSaleInfo?.endTime

  if (!privateSaleTime || !publicSaleTime || !endTime) return AuctionStageEnum.unknown

  const nowTime = getUnixTimeSecond()
  // if (idoState.alignedAmt <= 0) return AuctionStageEnum.ended
  if (nowTime < privateSaleTime) return AuctionStageEnum.upcoming
  if (nowTime >= privateSaleTime && nowTime < publicSaleTime) {
    if (idoState.alignedPriAmt <= 0) {
      return AuctionStageEnum.publicSale
    } else {
      return AuctionStageEnum.privateSale
    }
  }
  if (nowTime >= publicSaleTime && nowTime < endTime) return AuctionStageEnum.publicSale
  if (nowTime >= endTime) return AuctionStageEnum.ended

  return AuctionStageEnum.unknown
}

const min = (a: BigNumber, b: BigNumber) => {
  if (a.lte(b)) return a
  return b
}

const max = (a: BigNumber, b: BigNumber) => {
  if (a.gte(b)) return a
  return b
}

export default function useIdoInfo(address: string) {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId
  const isGenesisNftHolder = useIsGenesisNftHolder()

  const { data: tokenState } = useToken(address)

  const cSwappiIdoplatform = useContract(EVM_SPACE[chainId]?.SwappiIdoplatform, SwappiIdoplatform.abi, false)
  const cIdoToken = useContract(address, ERC20.abi, false)
  const cVotingEscrow = useContract(EVM_SPACE[chainId]?.VotingEscrow, VotingEscrow.abi, false)
  const { startTimer, timespan: timespanState } = useTimer()

  const [idoState, setIdoState] = useState<any>({})

  const init = useCallback(async () => {
    try {
      if (!chainId || !library || !cSwappiIdoplatform || !cVotingEscrow || !cIdoToken || !tokenState) return

      const idoId =
        tokenState.id || +(await cSwappiIdoplatform.callStatic.getCurrentIDOIdByTokenAddr(tokenState.address))

      const promises = [
        cSwappiIdoplatform.callStatic.tokenInfo(tokenState.address, idoId),
        cIdoToken.callStatic.totalSupply(),
        tokenState.decimals ? Promise.resolve(tokenState.decimals) : cIdoToken.callStatic.decimals(),
        cVotingEscrow.callStatic.decimals(),
        account ? cVotingEscrow.callStatic.balanceOf(account) : Promise.resolve(0),
        account
          ? cSwappiIdoplatform.callStatic.getAmtOfTokenForBuyer(tokenState.address, idoId, account)
          : Promise.resolve(0),
        cSwappiIdoplatform.callStatic.getAmtOfCFXCollected(tokenState.address, idoId),
        account
          ? cSwappiIdoplatform.callStatic.getAmtOfCFXForBuyer(tokenState.address, idoId, account)
          : Promise.resolve(0),
        account ? cSwappiIdoplatform.callStatic.isInWhitelist(tokenState.address, idoId, account) : Promise.resolve(0),
        account
          ? cSwappiIdoplatform.callStatic.maxAmountInPrivateSaleByAddr(tokenState.address, idoId, account)
          : Promise.resolve(0),
      ]

      const [
        tokenInfoResult,
        totalSupply,
        decimals,
        veDecimals,
        veBalance,
        claimableAmt,
        amtOfCFXCollected,
        amtOfCFXForBuyer,
        isInWhitelist,
        maxAmountInPrivateSaleByAddr,
      ] = await Promise.all(promises)

      const alignedTotalSupply = +formatUnits(totalSupply, +decimals)
      const alignedTotalAmount = +formatUnits(tokenInfoResult.totalAmt, +decimals)
      const alignedAmt = +formatUnits(tokenInfoResult.amt, +decimals)
      const alignedPriAmt = +formatUnits(tokenInfoResult.priSaleInfo.amount, +decimals)
      const alignedAmountForLP = +formatUnits(tokenInfoResult.amtForLP, +decimals)
      const alignedPriPrice = +formatUnits(tokenInfoResult.priSaleInfo.price, +decimals)
      const alignedPubPrice = +formatUnits(tokenInfoResult.pubSaleInfo.price, +decimals)
      const alignedVeTokenThreshold = +formatUnits(tokenInfoResult.priSaleInfo.veTokenThreshold, +veDecimals)
      const alignedNFTThreshold = +formatUnits(
        max(
          BigNumber.from(0),
          tokenInfoResult.priSaleInfo.veTokenThreshold.sub(tokenInfoResult.priSaleInfo.NFTThreshold)
        ),
        +veDecimals
      )
      const alignedVeBalance = +formatUnits(veBalance, +veDecimals)
      const alignedClaimableAmt = +formatUnits(claimableAmt, +decimals)
      const alignedAmtOfCFXCollected = +formatUnits(amtOfCFXCollected, +decimals)
      const alignedAmtOfCFXForBuyer = +formatUnits(amtOfCFXForBuyer, +decimals)
      const alignedPriSaleUpperLimitPerAddress = +formatUnits(tokenInfoResult.priSaleInfo.maxAmtPerBuyer, +decimals)

      const priSaleRequiredThreshold = Math.max(
        0,
        alignedVeTokenThreshold - (isGenesisNftHolder ? alignedNFTThreshold : 0)
      )
      const isMeetPriSaleScore = alignedVeBalance >= priSaleRequiredThreshold

      let maxValidAmountInPrivateSale = BigNumber.from(0)
      if (
        (isInWhitelist && !isMeetPriSaleScore) ||
        (isInWhitelist &&
          isMeetPriSaleScore &&
          tokenInfoResult.priSaleInfo.maxAmtPerBuyer.lte(maxAmountInPrivateSaleByAddr))
      ) {
        maxValidAmountInPrivateSale = maxAmountInPrivateSaleByAddr.sub(claimableAmt)
      }

      if (!isInWhitelist && isMeetPriSaleScore) {
        maxValidAmountInPrivateSale = min(
          tokenInfoResult.priSaleInfo.maxAmtPerBuyer.sub(claimableAmt),
          tokenInfoResult.priSaleInfo.amountExcludingWhitelist
        )
      }

      if (
        isInWhitelist &&
        isMeetPriSaleScore &&
        tokenInfoResult.priSaleInfo.maxAmtPerBuyer.gt(maxAmountInPrivateSaleByAddr)
      ) {
        if (claimableAmt.lt(maxAmountInPrivateSaleByAddr)) {
          maxValidAmountInPrivateSale = maxAmountInPrivateSaleByAddr
            .sub(claimableAmt)
            .add(
              min(
                tokenInfoResult.priSaleInfo.amountExcludingWhitelist,
                tokenInfoResult.priSaleInfo.maxAmtPerBuyer.sub(maxAmountInPrivateSaleByAddr)
              )
            )
        } else {
          maxValidAmountInPrivateSale = min(
            tokenInfoResult.priSaleInfo.maxAmtPerBuyer.sub(claimableAmt),
            tokenInfoResult.priSaleInfo.amountExcludingWhitelist
          )
        }
      }

      const alignedMaxValidAmountInPrivateSale = +formatUnits(maxValidAmountInPrivateSale, +decimals)

      const idoStateItem = {
        idoId,
        idoTokenAddress: tokenState.address,
        auctionAddress: cSwappiIdoplatform.address,
        alignedTotalSupply,
        alignedTotalAmount,
        alignedAmt,
        alignedPriAmt,
        forLpRatio: alignedAmountForLP / alignedTotalAmount,
        priSaleInfo: tokenInfoResult.priSaleInfo,
        pubSaleInfo: tokenInfoResult.pubSaleInfo,
        projectName: tokenInfoResult.projectName,
        alignedPriPrice,
        alignedPubPrice,
        alignedVeTokenThreshold,
        alignedNFTThreshold,
        alignedVeBalance,
        alignedClaimableAmt,
        alignedAmtOfCFXCollected,
        alignedAmtOfCFXForBuyer,
        alignedPriSaleUpperLimitPerAddress,
        alignedMaxValidAmountInPrivateSale,
        isInWhitelist,
      }
      const currentStage = getCurrentStage(idoStateItem)
      if (currentStage === AuctionStageEnum.publicSale) {
        idoStateItem.alignedVeTokenThreshold = 0
      }

      setIdoState({ ...idoStateItem, currentStage })

      const privateSaleTime = +idoStateItem.priSaleInfo?.startTime
      const publicSaleTime = +idoStateItem.priSaleInfo?.endTime
      const endTime = +idoStateItem.pubSaleInfo?.endTime

      if (currentStage === AuctionStageEnum.upcoming && privateSaleTime) {
        timerId = startTimer(getTimeString(privateSaleTime * 1000))
      } else if (currentStage === AuctionStageEnum.privateSale && publicSaleTime) {
        timerId = startTimer(getTimeString(publicSaleTime * 1000))
      } else if (currentStage === AuctionStageEnum.publicSale && endTime) {
        timerId = startTimer(getTimeString(endTime * 1000))
      }
    } catch (error) {
      console.log(error)
    }
  }, [
    chainId,
    library,
    cSwappiIdoplatform,
    cVotingEscrow,
    cIdoToken,
    tokenState,
    account,
    startTimer,
    isGenesisNftHolder,
  ])

  useEffect(() => {
    ;(async () => {
      await init()
    })()

    return () => {
      clearInterval(timerId)
    }
  }, [init])

  useEffect(() => {
    ;(() => {
      // auto refresh when timer finished
      if (timespanState === null) {
        setTimeout(init, 1500)
      }
    })()
  }, [timespanState, init])

  return {
    idoState,
    tokenState,
    timespanState,
    account,
    init,
  }
}
