import { defaultAbiCoder } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import PlaceholderContent from 'components/Spinner/PlaceholderContent'
import { SupportedChainId } from 'constants/chains'
import { LOCK_DURATION_OPTIONS } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { classNames, currencyMapping, displayVal, getDisplayName, rangeMapping } from 'utils'

import Multicall from '../../abi/Multicall.json'
import SwappiPair from '../../abi/swappi-core/SwappiPair.json'
import FarmController from '../../abi/swappi-farm/FarmController.sol/FarmController.json'
import PPIToken from '../../abi/swappi-farm/PPIToken.sol/PPIToken.json'
import VotingEscrow from '../../abi/swappi-farm/VotingEscrow.sol/VotingEscrow.json'
import PoolshareIcon from '../../assets/images/poolshare.svg'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import AnnouncementBannerBottom from '../../components/Header/AnnouncementBannerBottom'
import SemiCircleProgressBar from '../../components/SemiCircleProgressBar'
import { EVM_SPACE } from '../../constants/addresses'
import { useContract } from '../../hooks/useContract'
import { isMobile } from '../../utils/userAgent'
import AppBody from '../AppBody'

const obj: any = {}

export default function Simulator() {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const farmController = useContract(EVM_SPACE[chainId]?.FarmController, FarmController.abi, false)
  const multicall = useContract(EVM_SPACE[chainId]?.Multicall, Multicall.abi, false)
  const ppiToken = useContract(EVM_SPACE[chainId]?.PPI, PPIToken.abi, false)
  const votingEscrow = useContract(EVM_SPACE[chainId]?.VotingEscrow, VotingEscrow.abi, false)

  const [poolsState, setPoolsState] = useState([])
  const [pageState, setPageState] = useState({}) as any
  const [isLoading, setIsLoading] = useState(false)
  const { register, setValue, getValues } = useForm()
  const [selectedDurationText, setSelectedDurationText] = useState('')

  const initPools = useCallback(async () => {
    if (!library || !farmController || !ppiToken || !multicall || !votingEscrow) return

    let pools = await farmController.callStatic.getPoolInfo(0)
    const k = +(await farmController.callStatic.k())

    pools = await Promise.all(
      pools.map(async (i: any, idx: number) => {
        const swappiPair = new Contract(i.token, SwappiPair.abi, library)

        const token0CallData = swappiPair.interface.encodeFunctionData('token0')
        const token1CallData = swappiPair.interface.encodeFunctionData('token1')
        const decimalsCallData = swappiPair.interface.encodeFunctionData('decimals')
        const re = await multicall.callStatic.aggregate([
          [i.token, token0CallData],
          [i.token, token1CallData],
          [i.token, decimalsCallData],
        ])

        const token0Addr = defaultAbiCoder.decode(['address'], re.returnData[0])[0]
        const token1Addr = defaultAbiCoder.decode(['address'], re.returnData[1])[0]
        const token0 = currencyMapping({ address: token0Addr }, chainId)
        const token1 = currencyMapping({ address: token1Addr }, chainId)
        const decimals = +defaultAbiCoder.decode(['uint8'], re.returnData[2])[0]

        const pair = {
          token0,
          token1,
          displayName: getDisplayName(token0.symbol, token1.symbol),
          decimals,
        }

        return {
          ...i,
          totalSupply: +i.totalSupply,
          pair,
          k,
        }
      })
    )

    obj.pools = pools
  }, [library, farmController, ppiToken, multicall, votingEscrow, chainId])

  const initPage = useCallback(async () => {
    if (!ppiToken || !multicall) return

    const totalLockedCallData = ppiToken.interface.encodeFunctionData('balanceOf', [EVM_SPACE[chainId].VotingEscrow])
    const ppiDecimalsCallData = ppiToken.interface.encodeFunctionData('decimals')
    const re = await multicall.callStatic.aggregate([
      [EVM_SPACE[chainId].PPI, totalLockedCallData],
      [EVM_SPACE[chainId].PPI, ppiDecimalsCallData],
    ])

    const totalLocked = +defaultAbiCoder.decode(['uint256'], re.returnData[0])[0]
    const ppiDecimals = +defaultAbiCoder.decode(['uint8'], re.returnData[1])[0]
    const alignedTotalLocked = new BigNumber(totalLocked).div(10 ** ppiDecimals).toNumber()

    obj.alignedTotalLocked = alignedTotalLocked
  }, [chainId, ppiToken, multicall])

  const calBoostRatio = useCallback(
    async (pool: any) => {
      if (!votingEscrow || !multicall || !ppiToken) return

      const key = `stake-amount-${pool.token}`
      const stakeAmountVal = getValues()[key]
      const lockAmountVal = getValues()['lockAmount']
      const lockDurationVal = getValues()['lockDuration']

      if (!stakeAmountVal || stakeAmountVal < 0 || !lockAmountVal || lockAmountVal < 0 || !lockDurationVal) return

      const stakeAmount = parseFloat(stakeAmountVal)
      const lockAmount = parseFloat(lockAmountVal)
      const lockDuration = parseInt(lockDurationVal, 10)

      const maxTimeCallData = votingEscrow.interface.encodeFunctionData('maxTime')
      const ppiDecimalsCallData = ppiToken.interface.encodeFunctionData('decimals')
      const totalSupplyCallData = votingEscrow.interface.encodeFunctionData('totalSupply')
      const vePPIDecimalsCallData = votingEscrow.interface.encodeFunctionData('decimals')
      const re = await multicall.callStatic.aggregate([
        [EVM_SPACE[chainId].VotingEscrow, maxTimeCallData],
        [EVM_SPACE[chainId].PPI, ppiDecimalsCallData],
        [EVM_SPACE[chainId].VotingEscrow, totalSupplyCallData],
        [EVM_SPACE[chainId].VotingEscrow, vePPIDecimalsCallData],
      ])

      const maxTime = +defaultAbiCoder.decode(['uint256'], re.returnData[0])[0]
      console.log(maxTime)
      const totalSupply = +defaultAbiCoder.decode(['uint256'], re.returnData[2])[0]
      const vePPIDecimals = +defaultAbiCoder.decode(['uint8'], re.returnData[3])[0]

      const alignedTotalSupply = new BigNumber(totalSupply).div(10 ** vePPIDecimals).toNumber()
      const userVePPI = lockAmount * (lockDuration / maxTime)
      const totalVePPI = new BigNumber(alignedTotalSupply).plus(userVePPI).toNumber()
      const alignedPoolTotalSupply = new BigNumber(pool.totalSupply).div(10 ** pool.pair.decimals).toNumber()

      const kRatio = pool.k / 100
      const newStakeAmount = kRatio * stakeAmount + (1 - kRatio) * (userVePPI / totalVePPI) * alignedPoolTotalSupply
      const newWorkingSupply = Math.min(stakeAmount, newStakeAmount)

      const newBoostRatio = newWorkingSupply / (stakeAmount * kRatio)
      const maxRatio = stakeAmount / (stakeAmount * kRatio)
      const percent = rangeMapping({ oMin: 1, oMax: maxRatio, nMin: 0, nMax: 100, oValue: newBoostRatio })

      return {
        ratio: newBoostRatio,
        percent,
        userVePPI,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainId, votingEscrow, multicall, ppiToken]
  )

  const calPoolShare = useCallback(() => {
    const lockAmountVal = getValues()['lockAmount']

    if (!lockAmountVal || lockAmountVal < 0) return 0

    const lockAmount = parseFloat(lockAmountVal)
    return lockAmount / (obj.alignedTotalLocked + lockAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calLaunchpadScore = useCallback(() => {
    const lockAmount = +getValues()['lockAmount'] || 0
    const lockDuration = +getValues()['lockDuration'] || 0

    if (!lockAmount || lockDuration < 0) return 0
    const maxTime = 126144000 // hardcode maxTime for now

    const userVePPI = lockAmount * (lockDuration / maxTime)

    return userVePPI
  }, [getValues])

  const setStates = useCallback(
    async (pool: any = null) => {
      setPoolsState(obj.pools)

      const poolShare = calPoolShare()
      const launchpadScore = calLaunchpadScore()
      const state = Object.assign({}, pageState, {
        poolShare,
        launchpadScore,
      })

      const pools = pool ? [pool] : obj.pools
      if (pools) {
        await Promise.all(
          pools.map(async (pool: any) => {
            const keyRatio = `boost-ratio-${pool.token}`
            const keyPercent = `boost-ratio-percent-${pool.token}`

            const boostRatio = await calBoostRatio(pool)
            if (boostRatio) {
              state[keyRatio] = boostRatio.ratio
              state[keyPercent] = boostRatio.percent
            }
          })
        )
      }

      setPageState(state)
    },
    [calPoolShare, calLaunchpadScore, pageState, calBoostRatio]
  )

  const init = useCallback(async () => {
    setIsLoading(true)

    await initPools()
    await initPage()
    await setStates()

    setIsLoading(false)
  }, [initPools, initPage, setStates])

  useEffect(() => {
    ;(async () => {
      if (account && chainId && library) {
        obj.account = account

        await init()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, library])

  return (
    <>
      {chainId && (
        <>
          <AppBody maxWidth="900px">
            <div className="main-container">
              <div className="flex justify-between pt-5 px-5 pb-2">
                <div className="flex items-center">
                  <h5 className="text-base tracking-widest text-ink-green mr-2 font-medium whitespace-nowrap uppercase">
                    <Trans>Boost Simulator</Trans>
                  </h5>
                  <p className="border-l-[3px] border-windy h-3">&nbsp;</p>
                  <p className="text-xs text-windy px-1 mt-[2px]">
                    <Trans>Calculate Boost and simulate farming strategy.</Trans>
                  </p>
                </div>
              </div>

              <div
                id="simulator-page"
                className="bg-white p-4 rounded-3xl rounded-tr-none rounded-bl-none space-y-3 relative"
              >
                <div className="p-6 rounded-xl border border-grass space-y-5">
                  <div className="flex justify-between">
                    <div className="flex flex-col space-y-4 w-full">
                      <div className="flex flex-col space-y-2">
                        <div className="flex justify-between">
                          <label className="text-xs text-ink-green opacity-60">
                            <Trans>Staked PPI</Trans>
                          </label>
                          <div className="flex sm:hidden text-xs text-ink-green opacity-60 space-x-2">
                            <div className="space-x-1">
                              <label className="">
                                <Trans>Pool Share:</Trans>
                              </label>
                              <span>
                                {!isNaN(pageState.poolShare)
                                  ? parseFloat((pageState.poolShare * 100).toFixed(5).slice(0, -1))
                                  : ''}
                                %
                              </span>
                            </div>

                            <div className="space-x-1">
                              <span>
                                <Trans>Launchpad Score</Trans>
                              </span>
                              <span>{displayVal(pageState.launchpadScore, 2)}</span>
                            </div>
                          </div>
                        </div>
                        <input
                          autoComplete="no"
                          className="text-ink-green rounded-lg border border-grass bg-linear-yellow-light p-2 text-base w-full sm:w-80 px-4"
                          type="number"
                          placeholder="Enter an amount"
                          {...register('lockAmount', {
                            onChange: (e) => {
                              setValue('lockAmount', e.target.value < 0 ? 0 : e.target.value)
                              setStates()
                            },
                          })}
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="text-xs text-ink-green opacity-60">
                          <Trans>Locked Duration</Trans>
                        </label>
                        <input
                          autoComplete="no"
                          className="text-ink-green rounded-lg border border-grass bg-linear-yellow-light p-2 text-base w-full sm:w-80 px-4"
                          type="text"
                          disabled={true}
                          placeholder="Please select..."
                          value={selectedDurationText}
                        />
                      </div>
                    </div>
                    <div className="hidden sm:block relative px-4">
                      <img className="w-[132px] h-[132px]" src={PoolshareIcon} alt="icon" />
                      <div className="w-[132px] flex flex-col absolute top-[56px] left-1/2 transform -translate-x-1/2 text-center text-white p-1.5">
                        <span className="">
                          <span className="text-[24px] font-normal break-all">
                            {!isNaN(pageState.poolShare)
                              ? parseFloat((pageState.poolShare * 100).toFixed(4).slice(0, -1))
                              : ''}
                          </span>
                          <span className="opacity-60 font-bold text-xl ml-1">%</span>
                        </span>
                        <span className="text-xs font-medium whitespace-nowrap opacity-60 -mt-1">
                          <Trans>Pool Share</Trans>
                        </span>
                      </div>
                      <div className="flex flex-col text-center text-xs text-bluegreen">
                        <span>
                          <Trans>Launchpad Score</Trans>
                        </span>
                        <span>{displayVal(pageState.launchpadScore, 2)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <fieldset className="">
                      {LOCK_DURATION_OPTIONS.map(({ text, value }) => (
                        <label htmlFor={text} key={text}>
                          <input
                            hidden
                            type="radio"
                            value={value}
                            id={text}
                            name="lockDuration"
                            {...(register('lockDuration'),
                            {
                              onChange: (e) => {
                                setValue('lockDuration', e.target.value)
                                setSelectedDurationText(text)
                                setStates()
                              },
                            })}
                          />
                          <span
                            className={classNames(
                              'inline-block btn cursor-pointer text-base font-normal rounded-full border border-gray-400 hover:border-gray-300 mr-2 mb-2 leading-5 tracking-normal',
                              selectedDurationText === text ? 'from-[#FAFAEA] to-[#FBFFDF] bg-gradient-to-r' : ''
                            )}
                          >
                            {text}
                          </span>
                        </label>
                      ))}
                    </fieldset>
                  </div>
                </div>

                {isLoading ? (
                  <PlaceholderContent className="mt-4" />
                ) : (
                  <>
                    {poolsState &&
                      poolsState.map((item: any) => (
                        <Disclosure
                          as="div"
                          key={item.token}
                          className="rounded-xl border border-grass overflow-hidden"
                        >
                          {({ open }) => (
                            <>
                              <div
                                className={classNames(
                                  open ? '' : 'bg-linear-yellow',
                                  'pl-8 pr-4 py-2.5 flex justify-between text-ink-green items-center'
                                )}
                              >
                                <div className="flex items-center">
                                  <DoubleCurrencyLogo
                                    currency0={item.pair.token0}
                                    currency1={item.pair.token1}
                                    size={20}
                                  />
                                  <span className="font-medium text-base ml-1">{item.pair.displayName}</span>
                                </div>
                                <Disclosure.Button className="btn btn-secondary !bg-transparent border-none">
                                  <ChevronDownIcon
                                    className={`${open ? 'transform rotate-180' : ''} w-5 h-5 font-light`}
                                  />
                                </Disclosure.Button>
                              </div>

                              <Disclosure.Panel className="pb-6 px-6 pt-0 flex flex-wrap justify-center sm:justify-between align-bottom items-start">
                                <div className="flex flex-col space-y-2 w-full sm:w-auto">
                                  <div className="flex flex-col">
                                    <label className="text-xs text-[#EBA337] font-medium">
                                      <Trans>You must enter locked amount and duration before calculate</Trans>
                                    </label>
                                  </div>
                                  <div className="flex flex-col space-y-2">
                                    <label className="text-xs text-ink-green opacity-60">
                                      <Trans>Staked {item.pair.displayName} LP</Trans>
                                    </label>
                                    <input
                                      className="text-ink-green rounded-lg border border-grass bg-linear-yellow-light p-2 text-base w-full sm:w-80 px-4"
                                      type="text"
                                      autoComplete="no"
                                      placeholder="Enter an amount"
                                      {...register(`stake-amount-${item.token}`, {
                                        onChange: (e) => {
                                          setValue(
                                            `stake-amount-${item.token}`,
                                            e.target.value < 0 ? 0 : e.target.value
                                          )
                                          setStates(item)
                                        },
                                      })}
                                    />
                                  </div>
                                </div>
                                <div className="relative px-4 mt-8 sm:mt-0 flex">
                                  <div className="w-[140px] h-[105px] -mt-4">
                                    <SemiCircleProgressBar
                                      percent={
                                        pageState[`boost-ratio-percent-${item.token}`]
                                          ? pageState[`boost-ratio-percent-${item.token}`]
                                          : 0
                                      }
                                    />
                                  </div>
                                  <div className="flex flex-col absolute bottom-0 left-1/2 transform -translate-x-1/2 text-center text-ink-green font-medium">
                                    <span className="">
                                      <span className="text-[26px]">
                                        {pageState[`boost-ratio-${item.token}`]
                                          ? parseFloat(pageState[`boost-ratio-${item.token}`].toFixed(2))
                                          : '1'}
                                      </span>
                                      <span className="opacity-20 text-[22px] ml-1">X</span>
                                    </span>
                                    <span className="inline-flex">
                                      <span className="text-sm font-medium whitespace-nowrap">
                                        <Trans>Estimated Boost Factor</Trans>
                                      </span>
                                      <QuestionHelper
                                        text={
                                          <Trans>
                                            Boost factor speeds up your farming rewards via staking PPI. Locking more
                                            PPI or for longer time improves your boost factor.
                                          </Trans>
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                  </>
                )}

                {isMobile && <AnnouncementBannerBottom />}
              </div>
            </div>
          </AppBody>
        </>
      )}
    </>
  )
}
