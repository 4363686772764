import { formatUnits } from '@ethersproject/units'
import { useCallback, useEffect, useState } from 'react'

import ERC20 from '../abi/swappi-core/ERC20.json'
import { MAX_INT } from '../constants/misc'
import { TransactionType } from '../state/transactions/actions'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils'
import { useContract } from './useContract'

export const useAllowance = ({ token, account, contractAddress }: any) => {
  const cToken = useContract(token?.address, ERC20.abi, false)
  const [allowance, setAllowance] = useState(0)
  const [hasOutdated, setHasOutdated] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (!cToken || !hasOutdated) return

      const allowanceResult = await cToken.callStatic.allowance(account, contractAddress)
      const alignedAllowance = +formatUnits(allowanceResult, token?.decimals)

      setAllowance(+alignedAllowance)
      setHasOutdated(false)
    })()
  }, [cToken, account, contractAddress, token?.decimals, hasOutdated])

  return {
    allowance,
    setHasOutdated,
  }
}

export const useApprove = ({ tokenAddress, contractAddress, approveAmount = MAX_INT }: any) => {
  const cTokenWithSigner = useContract(tokenAddress, ERC20.abi)
  const addTransaction = useTransactionAdder()

  const [isProcessing, setIsProcessing] = useState(false)

  const approve = useCallback(async () => {
    try {
      if (!cTokenWithSigner) return

      setIsProcessing(true)

      const methodName = 'approve'
      const methodParams = [contractAddress, approveAmount]

      const gasLimit = calculateGasMargin(await cTokenWithSigner.estimateGas[methodName](...methodParams))
      const gasPrice = undefined

      const response = await cTokenWithSigner[methodName](...methodParams, {
        gasLimit,
        gasPrice,
      })
      addTransaction(response, {
        type: TransactionType.APPROVAL,
        spender: contractAddress,
        tokenAddress,
      })

      await response.wait()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessing(false)
    }
  }, [cTokenWithSigner, addTransaction, approveAmount, contractAddress, tokenAddress])

  return {
    approve,
    isProcessing,
  }
}
