// a list of tokens by chain
import { Currency, Token } from '@uniswap/sdk-core'

import { SupportedChainId } from './chains'
import { GOL, nativeOnChain, NUT, PPI, USDC, USDT, WBTC, WETH, WRAPPED_NATIVE_CURRENCY } from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY).map(([key, value]) => [key, [value]])
)

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.ESPACE]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ESPACE],
    WBTC[SupportedChainId.ESPACE],
    WETH[SupportedChainId.ESPACE],
    USDT[SupportedChainId.ESPACE],
  ],
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.ESPACE]: {},
}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.ESPACE]: {},
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.ESPACE]: [
    nativeOnChain(SupportedChainId.ESPACE),
    WBTC[SupportedChainId.ESPACE],
    WETH[SupportedChainId.ESPACE],
    USDT[SupportedChainId.ESPACE],
    USDC[SupportedChainId.ESPACE],
    PPI[SupportedChainId.ESPACE],
    GOL[SupportedChainId.ESPACE],
    NUT[SupportedChainId.ESPACE],
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.ESPACE]: [WBTC[SupportedChainId.ESPACE], WETH[SupportedChainId.ESPACE]],
}

export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [SupportedChainId.ESPACE]: [[WBTC[SupportedChainId.ESPACE], WETH[SupportedChainId.ESPACE]]],
}
