import { useMemo } from 'react'

export default function SemiCircleProgressBar({ percent, text = '' }: { r?: number; percent: number; text?: string }) {
  const perimeter = Math.PI * 2 * 80
  const ringPercent = useMemo(() => {
    return (percent / 100 / 2) * perimeter + ' ' + perimeter
  }, [percent, perimeter])
  const semiRingPercent = (100 / 100 / 2) * perimeter + ' ' + perimeter
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 180 100"
        style={{
          transform: 'rotate(180deg)',
        }}
      >
        <defs>
          <linearGradient id="svg_1" x1="0%" y1="0%" x2="100%" y2="64.9%">
            <stop offset="0%" stopColor="#EB9C25" />
            <stop offset="100%" stopColor="#F9E643" />
          </linearGradient>
        </defs>
        <circle
          cx="50%"
          cy="20%"
          r="80"
          strokeWidth="16"
          stroke="#AAB1B7"
          strokeOpacity="0.4"
          strokeLinecap="round"
          fill="none"
          strokeDasharray={semiRingPercent}
        ></circle>
        <circle
          cx="50%"
          cy="20%"
          r="80"
          strokeWidth="16"
          stroke="url(#svg_1)"
          fill="none"
          strokeLinecap="round"
          strokeDasharray={ringPercent}
          style={{
            transition: 'stroke-dasharray .3s ease-in-out',
          }}
        ></circle>
        {text && (
          <text
            x="10"
            y="105"
            fontFamily="Verdana"
            fontSize="14"
            fontWeight={600}
            style={{
              textAlign: 'center',
              transformOrigin: 'center',
              transform: 'rotate(180deg)',
            }}
          >
            {text}
          </text>
        )}
      </svg>
    </>
  )
}
