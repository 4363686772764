// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import HeadlessModal from 'components/Modal/HeadlessModal'
import { CheckCircle } from 'react-feather'
import { classNames } from 'utils'

import CrownIcon from '../../assets/images/crown.png'
import TicketIcon from '../../assets/images/ticket.png'
import { ballColorClasses } from './utils'

export default function Index({ onClose, item, userInfoItem, onAction }: any) {
  return (
    <>
      <HeadlessModal title={t`Round ${item.id}`} isOpen={true} onDismiss={onClose}>
        <div className="mt-6 text-ink-green text-opacity-60 font-normal">
          <Trans>Winning Number</Trans>
        </div>

        <div className="mt-8 rounded-xl bg-linear-lime min-h-[40px]">
          <div className="flex items-center space-x-2 sm:space-x-3 mx-2 sm:mx-4 justify-center mt-[-12px]">
            {item.finalNumber
              .toString()
              .split('')
              .map((i: number, idx: number) => (
                <div key={idx} className={`relative w-[38px] h-[38px] rounded-full ${ballColorClasses[idx]}`}>
                  <div className="w-[21px] h-[21px] rounded-full bg-[#F5F4F8] bg-opacity-90 absolute transform left-1/2 -translate-x-1/2 bottom-1/2 translate-y-1/2">
                    <span className="flex items-center justify-center w-full h-full text-medigreen text-[21px] leading-[21px] font-bold lottery-stroke-text3">
                      {i}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="">
          <div className="mt-6 text-ink-green text-opacity-60 font-medium">
            <Trans>Your Tickets</Trans>
          </div>
          <div className="mt-4 text-ink-green text-opacity-60 font-normal flex justify-between border-b border-dashed border-grass pb-2">
            <span className="flex space-x-[10px] items-center">
              <span>
                <img src={TicketIcon} alt="icon" className="w-[24px]" />
              </span>
              <span>
                <Trans>Total Tickets {userInfoItem.userTickets ? userInfoItem.userTickets.length : '-'}</Trans>
              </span>
            </span>
            <span className="flex space-x-[10px] items-center mr-5">
              <span>
                <img src={CrownIcon} alt="icon" className="w-[24px]" />
              </span>
              <span>
                <Trans>
                  Winning Tickets{' '}
                  {userInfoItem.userTickets ? userInfoItem.userTickets.filter((i: any) => i.matches > 0).length : '-'}
                </Trans>
              </span>
            </span>
          </div>
        </div>

        <div className="flex flex-col space-y-1 mt-4">
          {userInfoItem.userTickets && (
            <>
              {userInfoItem.userTickets.map((i: any, idx: number) => (
                <div key={idx}>
                  <div className="border border-grass rounded-xl bg-linear-egg py-1 px-3">
                    <div className="flex items-center space-x-9">
                      <span className="text-xs font-normal text-medigreen">#{i.id}</span>
                      <div className="relative">
                        <div className="flex space-x-6 sm:space-x-[26px] text-ink-green text-[22px] leading-[30px] font-medium">
                          {[...Array(6).keys()].map((j: number) => (
                            <span key={j} className="w-[14px]">
                              {i.number.toString().split('')[j]}
                            </span>
                          ))}
                        </div>
                        {i.matches > 0 && (
                          <div
                            className={classNames(
                              'border-2 border-orange  h-[40px] rounded-full bg-transparent absolute top-1/2 left-0 transform translate-x-[-14px] -translate-y-1/2'
                            )}
                            style={{ width: 40 * i.matches }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                  {i.matches > 0 ? (
                    <div className="px-0.5 text-orange text-xs">
                      <div className="flex space-x-1 items-center">
                        <span>
                          <CheckCircle width={12} />
                        </span>
                        <span>
                          <Trans>
                            Matched {i.matches === 6 ? 'All' : 'First'} {i.matches}
                          </Trans>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2"></div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>

        <button
          className={classNames(
            userInfoItem.userTickets && userInfoItem.userTickets.filter((i: any) => i.matches > 0).length > 0
              ? ''
              : 'btn-disabled',
            'btn btn-primary mt-6 justify-center py-3 rounded-xl'
          )}
          onClick={() => {
            if (userInfoItem.userTickets && userInfoItem.userTickets.filter((i: any) => i.matches > 0).length > 0) {
              onAction()
            }
          }}
        >
          <Trans>Collect Prize</Trans>
        </button>
      </HeadlessModal>
    </>
  )
}
