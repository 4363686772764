import { defaultAbiCoder } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { formatUnits } from '@ethersproject/units'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import PlaceholderItem from 'components/Spinner/PlaceholderItem'
import { SupportedChainId } from 'constants/chains'
import { PPI } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useGraphData from 'hooks/useGraphData'
import { getPairAmount, getPairPrice } from 'hooks/usePairPrice'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { classNames, currencyMapping, debounce, getDisplayName, numberWithCommas, rangeMapping } from 'utils'

import Multicall from '../../abi/Multicall.json'
import SwappiFactory from '../../abi/swappi-core/SwappiFactory.json'
import SwappiPair from '../../abi/swappi-core/SwappiPair.json'
import FarmController from '../../abi/swappi-farm/FarmController.sol/FarmController.json'
import PPIRate from '../../abi/swappi-farm/PPIRate.sol/PPIRate.json'
import PPIToken from '../../abi/swappi-farm/PPIToken.sol/PPIToken.json'
import VotingEscrow from '../../abi/swappi-farm/VotingEscrow.sol/VotingEscrow.json'
import PlaceholderIcon from '../../assets/images/avocado.svg'
import FarmingItem from '../../components/Farming/FarmingItem'
import AnnouncementBannerBottom from '../../components/Header/AnnouncementBannerBottom'
import { EVM_SPACE } from '../../constants/addresses'
import { APR_SHARE_NUMBER } from '../../constants/misc'
import { pools as poolsFromConfig } from '../../constants/pools'
import { useContract } from '../../hooks/useContract'
import { isMobile } from '../../utils/userAgent'
import AppBody from '../AppBody'

const obj = {} as any

enum OrderTypeEnum {
  hot = 'hot',
  apr = 'APR',
  earned = 'earned',
  multiplier = 'multiplier',
  latest = 'latest',
  liquidity = 'liquidity',
}

const getMulticallAggregatesForUpdate = (pools: any, { library, farmController, chainId }: any) => {
  let multicallAggregates = [] as any
  let callDataList = []

  pools.forEach((i: any) => {
    const swappiPair = new Contract(i.token, SwappiPair.abi, library)

    const token0CallData = swappiPair.interface.encodeFunctionData('token0')
    const token1CallData = swappiPair.interface.encodeFunctionData('token1')
    const totalSupplyCallData = swappiPair.interface.encodeFunctionData('totalSupply')
    const decimalsCallData = swappiPair.interface.encodeFunctionData('decimals')
    const claimableCallData = farmController.interface.encodeFunctionData('claimable')

    const callList1 = [
      [i.token, token0CallData],
      [i.token, token1CallData],
      [i.token, totalSupplyCallData],
      [i.token, decimalsCallData],
      [EVM_SPACE[chainId].FarmController, claimableCallData],
    ]

    let callList1Additional = [] as any
    if (obj.account) {
      const balanceOfCallData = swappiPair.interface.encodeFunctionData('balanceOf', [obj.account])
      const allowanceCallData = swappiPair.interface.encodeFunctionData('allowance', [
        obj.account,
        EVM_SPACE[chainId].FarmController,
      ])
      const userInfoCallData = farmController.interface.encodeFunctionData('userInfo', [i.pid, obj.account])

      callList1Additional = [
        [i.token, balanceOfCallData],
        [i.token, allowanceCallData],
        [EVM_SPACE[chainId].FarmController, userInfoCallData],
      ]
    }

    callDataList = callList1.concat(callList1Additional)

    multicallAggregates = multicallAggregates.concat(callDataList)
  })

  return { multicallAggregates, dataCounts: callDataList.length }
}

const getAggregates = (aggregatedResult: any, dataCounts: any) => {
  const re = [] as any

  aggregatedResult.returnData.forEach((item: any, index: number) => {
    if (index % dataCounts === 0) {
      re.push({ returnData: [item] })
    } else {
      const pos = parseInt((index / dataCounts).toString(), 10)
      re[pos].returnData.push(item)
    }
  })

  return re
}

export default function Farming() {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId: SupportedChainId = activeWeb3React.chainId || SupportedChainId.ESPACE

  const farmController = useContract(EVM_SPACE[chainId].FarmController, FarmController.abi, false)
  const ppiToken = useContract(EVM_SPACE[chainId].PPI, PPIToken.abi, false)
  const swappiFactory = useContract(EVM_SPACE[chainId].SwappiFactory, SwappiFactory.abi, false)
  const multicall = useContract(EVM_SPACE[chainId].Multicall, Multicall.abi, false)
  const ppiRate = useContract(EVM_SPACE[chainId].PPIRate, PPIRate.abi, false)
  const votingEscrow = useContract(EVM_SPACE[chainId].VotingEscrow, VotingEscrow.abi, false)

  const [poolsState, setPoolsState] = useState(poolsFromConfig)
  const [pageState, setPageState] = useState({}) as any
  const [isLoading, setIsLoading] = useState(false)

  const { pairsData } = useGraphData(poolsFromConfig.map((i) => i.token.toLowerCase()))

  const { register } = useForm()
  const [filterTypeState, setFilterTypeState] = useState(localStorage.getItem('filterType') || 'all')
  const [orderTypeState, setOrderTypeState] = useState(OrderTypeEnum.hot)
  const [searchQueryState, setSearchQueryState] = useState('')

  const updatePools = useCallback(
    async (pools: any, isPreload: boolean) => {
      if (!library || !farmController || !ppiToken || !swappiFactory || !multicall || !ppiRate || !votingEscrow) return

      let userStakedValue = 0
      let userEarnedValue = 0

      const maxTimeCallData = votingEscrow.interface.encodeFunctionData('maxTime')
      const ppiDecimalsCallData = ppiToken.interface.encodeFunctionData('decimals')
      const kCallData = farmController.interface.encodeFunctionData('k')
      const start = Math.floor(new Date().getTime() / 1000)
      const calculateRewardCallData = ppiRate.interface.encodeFunctionData('calculateReward', [start, start + 1])
      const totalAllocPointCallData = farmController.interface.encodeFunctionData('totalAllocPoint')
      const totalSupplyCallData = votingEscrow.interface.encodeFunctionData('totalSupply')

      const callList = [
        [EVM_SPACE[chainId].VotingEscrow, maxTimeCallData],
        [EVM_SPACE[chainId].PPI, ppiDecimalsCallData],
        [EVM_SPACE[chainId].FarmController, kCallData],
        [EVM_SPACE[chainId].PPIRate, calculateRewardCallData],
        [EVM_SPACE[chainId].FarmController, totalAllocPointCallData],
        [EVM_SPACE[chainId].VotingEscrow, totalSupplyCallData],
      ]

      let callListAdditional = [] as any
      if (obj.account) {
        const balanceOfCallData = votingEscrow.interface.encodeFunctionData('balanceOf', [obj.account])

        callListAdditional = [[EVM_SPACE[chainId].VotingEscrow, balanceOfCallData]]
      }

      const p0 = multicall.callStatic.aggregate(callList.concat(callListAdditional))

      const getPoolInfoCallData = farmController.interface.encodeFunctionData('getPoolInfo', [0])
      const p1 = multicall.callStatic.aggregate([[EVM_SPACE[chainId].FarmController, getPoolInfoCallData]])

      const multicallAggregatesObj = getMulticallAggregatesForUpdate(isPreload ? pools.slice(0, 2) : pools, {
        library,
        chainId,
        farmController,
      })
      const p2 = multicall.callStatic.aggregate(multicallAggregatesObj.multicallAggregates)

      const [re, re1, aggregatedResult] = await Promise.all([p0, p1, p2])

      const ppiDecimals = +defaultAbiCoder.decode(['uint8'], re.returnData[1])[0]
      const k = +defaultAbiCoder.decode(['uint256'], re.returnData[2])[0]
      const rewardInSecond = +defaultAbiCoder.decode(['uint256'], re.returnData[3])[0]
      const rewardInDay = new BigNumber(rewardInSecond).times(3600 * 24).toNumber()
      const rewardInYear = new BigNumber(rewardInSecond).times(3600 * 24 * 365).toNumber()
      const totalAllocPoint = defaultAbiCoder.decode(['uint256'], re.returnData[4])[0]

      const totalVePPI = +defaultAbiCoder.decode(['uint256'], re.returnData[5])[0]
      const userVePPI = obj.account ? +defaultAbiCoder.decode(['uint256'], re.returnData[6])[0] : 0

      const poolsFromContract = defaultAbiCoder.decode(
        ['tuple(address, uint256, uint256, uint256, uint256, uint256)[]'],
        re1.returnData[0]
      )[0]

      pools = pools.map((i: any, index: number) => {
        const pos = poolsFromContract.findIndex((arr: any) => arr[0].toLowerCase() === i.token.toLowerCase())
        const poolFromContract = poolsFromContract[pos]

        return {
          ...i,
          token: poolFromContract[0],
          allocPoint: poolFromContract[1],
          lastRewardTime: poolFromContract[2],
          totalSupply: poolFromContract[3],
          workingSupply: poolFromContract[4],
          accRewardPerShare: poolFromContract[5],
        }
      })

      const originalPools = pools
      if (isPreload) pools = pools.slice(0, 2)

      const swappiFactoryInfo = {
        address: EVM_SPACE[chainId].SwappiFactory,
        abi: SwappiFactory.abi,
      }
      const multicallInfo = {
        address: EVM_SPACE[chainId].Multicall,
        abi: Multicall.abi,
      }

      let totalRewardsTodayUSDValue: number | undefined

      const aggregates = getAggregates(aggregatedResult, multicallAggregatesObj.dataCounts)

      pools = await Promise.all(
        pools.map(async (i: any, index: number) => {
          const re = aggregates[index]

          const token0Addr = defaultAbiCoder.decode(['address'], re.returnData[0])[0]
          const token1Addr = defaultAbiCoder.decode(['address'], re.returnData[1])[0]
          const token0 = currencyMapping({ address: token0Addr }, chainId)
          const token1 = currencyMapping({ address: token1Addr }, chainId)

          const allTotalSupply = defaultAbiCoder.decode(['uint256'], re.returnData[2])[0]
          const decimals = +defaultAbiCoder.decode(['uint8'], re.returnData[3])[0]
          const claimable = defaultAbiCoder.decode(['bool'], re.returnData[4])[0]

          const balance = obj.account && defaultAbiCoder.decode(['uint256'], re.returnData[5])[0]
          const allowance = obj.account && +defaultAbiCoder.decode(['uint256'], re.returnData[6])[0]

          const p0 = getPairAmount({
            tokenA: token0,
            tokenB: token1,
            pairAddr: i.token,
            multicallInfo,
            swappiFactoryInfo,
            library,
          })
          const p1 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: token0,
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p2 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: token1,
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p3 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: PPI[SupportedChainId.ESPACE],
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p4 = farmController.callStatic.deposit(i.pid, new BigNumber(0).toString(10), { from: obj.account })

          const promises = [p0, p1, p2, p3, p4]
          const results = await Promise.all(promises.map((p) => p.catch(() => undefined)))
          const validResults = results.filter((result) => !(result instanceof Error))
          const [{ amountA, amountB }, price0, price1, ppiPrice] = validResults
          const earnedBN = validResults[4] ? +validResults[4] : 0

          if (!totalRewardsTodayUSDValue) {
            const totalRewardsToday = new BigNumber(rewardInDay)
              .times(0.5)
              .div(10 ** ppiDecimals)
              .toString()

            totalRewardsTodayUSDValue = new BigNumber(totalRewardsToday).times(ppiPrice).toNumber()
          }

          const lpPoolTotalLiquidity = new BigNumber(amountA)
            .times(price0)
            .plus(new BigNumber(amountB).times(price1))
            .toNumber()

          const liquidity = new BigNumber(i.totalSupply.toString())
            .div(allTotalSupply.toString())
            .times(lpPoolTotalLiquidity)
            .toString()

          const pair = {
            token0,
            token1,
            alignedBalance: balance ? formatUnits(balance, decimals) : balance,
            allowance,
            decimals,
            displayName: getDisplayName(token0.symbol, token1.symbol),
            liquidity,
            lpPoolTotalLiquidity,
          }

          const weight = new BigNumber(+i.allocPoint).div(100).toNumber()
          const lpPrice = new BigNumber(lpPoolTotalLiquidity.toString())
            .div(new BigNumber(allTotalSupply.toString()).div(10 ** decimals))
            .toString()

          const kRatio = k / 100
          const poolAllocPoint = i.allocPoint.toString()
          const poolPPIReward = new BigNumber(poolAllocPoint)
            .div(totalAllocPoint.toString())
            .times(rewardInYear)
            .toString()

          const poolProfitValue = new BigNumber(poolPPIReward).times(ppiPrice)
          const poolCapitalValue = new BigNumber(i.totalSupply.toString()).times(lpPrice)
          const poolApr = poolProfitValue.div(poolCapitalValue).toNumber()
          const overallBoostRatio = new BigNumber(i.workingSupply.toString())
            .div(i.totalSupply.toString())
            .div(kRatio)
            .toNumber()
          const aprLowerBound = poolApr / overallBoostRatio
          const aprUpperBound = aprLowerBound / kRatio
          const aprRange = aprLowerBound && aprLowerBound ? [aprLowerBound, aprUpperBound] : ''

          let accountedInfo = {}
          if (obj.account) {
            const amount = defaultAbiCoder.decode(['uint256', 'uint256', 'uint256'], re.returnData[7])[0]
            const workingSupply = defaultAbiCoder.decode(['uint256', 'uint256', 'uint256'], re.returnData[7])[1]
            const rewardPerShare = +defaultAbiCoder.decode(['uint256', 'uint256', 'uint256'], re.returnData[7])[2]

            const alignedAmount = formatUnits(amount, decimals)
            const userWorkingLPRatio = new BigNumber(workingSupply.toString())
              .div(i.workingSupply.toString())
              .toString()

            const exactAmountUSDValue = new BigNumber(new BigNumber(amount.toString()).div(10 ** decimals))
              .times(lpPrice)
              .toNumber()

            userStakedValue = new BigNumber(userStakedValue).plus(exactAmountUSDValue).toNumber()

            const userInfo = {
              amount,
              workingSupply,
              rewardPerShare,
              alignedAmount,
              amountUSDValue: exactAmountUSDValue,
            }

            const earned = +earnedBN
            const alignedEarned = new BigNumber(earned).div(10 ** ppiDecimals).toNumber()
            const earnedUSDValue = ppiPrice ? alignedEarned * ppiPrice : ''
            userEarnedValue = new BigNumber(userEarnedValue).plus(earnedUSDValue).toNumber()

            const boostRatio =
              new BigNumber(userInfo.workingSupply.toString()).isGreaterThan(0) && userInfo.amount.gt(0) && k > 0
                ? new BigNumber(userInfo.workingSupply.toString())
                    .div(userInfo.amount.toString())
                    .div(k)
                    .times(100)
                    .toNumber()
                : 0

            const userPPIReward = new BigNumber(userWorkingLPRatio).times(poolPPIReward).toString()
            const alignedUserPPIReward = new BigNumber(userPPIReward).div(10 ** ppiDecimals).toString()
            const profitValue = new BigNumber(ppiPrice).times(alignedUserPPIReward).toString()
            const capitalValue = exactAmountUSDValue
            const farmBaseAPR = capitalValue && new BigNumber(profitValue).div(capitalValue).toNumber()

            const stakeAmount = alignedAmount
            const alignedPoolTotalSupply = new BigNumber(i.totalSupply.toString()).div(10 ** decimals).toNumber()
            const newStakeAmount = new BigNumber(kRatio)
              .times(stakeAmount)
              .plus(
                new BigNumber(1 - kRatio).times(new BigNumber(userVePPI).div(totalVePPI)).times(alignedPoolTotalSupply)
              )
            const newWorkingSupply = BigNumber.minimum(stakeAmount, newStakeAmount)
            const newBoostRatio = new BigNumber(newWorkingSupply)
              .div(new BigNumber(stakeAmount).times(kRatio))
              .toNumber()
            const maxRatio = new BigNumber(stakeAmount).div(new BigNumber(stakeAmount).times(kRatio)).toNumber()

            const percent = rangeMapping({ oMin: 1, oMax: maxRatio, nMin: 0, nMax: 100, oValue: boostRatio })

            accountedInfo = {
              userInfo,
              alignedEarned,
              earnedUSDValue,
              boostRatio: parseFloat(boostRatio.toFixed(6)),
              newBoostRatio: parseFloat(newBoostRatio.toFixed(6)),
              percent,
              farmBaseAPR: farmBaseAPR ? farmBaseAPR / APR_SHARE_NUMBER : '',
            }
          }

          return {
            ...i,
            pair,
            pid: i.pid,
            k,
            userVePPI,
            totalVePPI,
            weight,
            claimable,
            ...accountedInfo,
            aprRange: aprRange ? [aprRange[0] / APR_SHARE_NUMBER, aprRange[1] / APR_SHARE_NUMBER] : '',
            hasLoaded: true,
          }
        })
      )

      obj.pools = isPreload ? pools.concat(originalPools.slice(2)) : pools
      obj.userStakedValue = userStakedValue
      obj.userEarnedValue = userEarnedValue
      obj.totalRewardsTodayUSDValue = totalRewardsTodayUSDValue
    },

    [library, farmController, ppiToken, swappiFactory, multicall, ppiRate, votingEscrow, chainId]
  )

  const setStates = useCallback(() => {
    setPoolsState(obj.pools)
    setPageState({
      userStakedValue: obj.userStakedValue,
      userEarnedValue: obj.userEarnedValue,
      totalRewardsTodayUSDValue: obj.totalRewardsTodayUSDValue,
    })
  }, [])

  const update = useCallback(
    async (isPreload = false) => {
      try {
        obj.account = account

        await updatePools(obj.pools, isPreload)

        setStates()
      } catch (error) {
        console.log(error)
      }
    },
    [updatePools, account, setStates]
  )

  // init with pools config
  useEffect(() => {
    obj.pools = poolsFromConfig
  }, [])

  // update
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      if (chainId && library && obj.pools) {
        // update two pools first to speed up
        update(true)

        await update()
        setIsLoading(false)
      }
    })()
  }, [chainId, library, update])

  const filter = useCallback(
    (i: any) => {
      let isMatchSearch = true
      if (searchQueryState) {
        isMatchSearch = i.pair.displayName.toLowerCase().includes(searchQueryState.toLowerCase())
      }

      if (filterTypeState === 'staked') {
        return isMatchSearch && i.userInfo && new BigNumber(i.userInfo.alignedAmount).isGreaterThan(0)
      } else {
        return isMatchSearch
      }
    },
    [filterTypeState, searchQueryState]
  )

  const order = useCallback(() => {
    return (a: any, b: any) => {
      if (orderTypeState === OrderTypeEnum.earned) {
        if (isNaN(a.alignedEarned) || isNaN(b.alignedEarned)) return 1

        return b.alignedEarned > a.alignedEarned ? 1 : a.alignedEarned > b.alignedEarned ? -1 : 0
      } else if (orderTypeState === OrderTypeEnum.multiplier) {
        return b.weight > a.weight ? 1 : a.weight > b.weight ? -1 : 0
      } else if (orderTypeState === OrderTypeEnum.latest) {
        return +b.pid > +a.pid ? 1 : +a.pid > +b.pid ? -1 : 0
      } else if (orderTypeState === OrderTypeEnum.liquidity) {
        return +b.pair.liquidity > +a.pair.liquidity ? 1 : +a.pair.liquidity > +b.pair.liquidity ? -1 : 0
      } else if (orderTypeState === OrderTypeEnum.apr) {
        if (!a.aprRange || !b.aprRange) return 0

        const aprComparationA = a.farmBaseAPR || a.aprRange[0]
        const aprComparationB = b.farmBaseAPR || b.aprRange[0]
        return aprComparationB > aprComparationA ? 1 : aprComparationA > aprComparationB ? -1 : 0
      } else {
        return 0
      }
    }
  }, [orderTypeState])

  const changeHandler = useCallback((e: any) => {
    setSearchQueryState(e.target.value)
  }, [])

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 777), [changeHandler])

  const satisfiedPools = useMemo(() => {
    return poolsState.length > 0 ? poolsState.filter(filter).concat().sort(order()) : []
  }, [poolsState, filter, order])

  return (
    <>
      <AppBody maxWidth="900px">
        <div className="main-container">
          <div className="flex justify-between pt-5 px-5 pb-2">
            <div className="flex items-center">
              <h5 className="text-base tracking-widest text-ink-green mr-2 font-medium uppercase">
                <Trans>Farming</Trans>
              </h5>
              <p className="border-l-[3px] border-windy h-3">&nbsp;</p>
              <p className="text-xs text-lime px-1 mt-[2px]">
                <Trans>Stake LP tokens to earn</Trans>
              </p>
            </div>
          </div>

          <div id="farming-page" className="bg-white p-3 sm:p-4 rounded-3xl rounded-tr-none rounded-bl-none relative">
            <div className="py-4 px-3 sm:px-5 sm:py-5 border border-[#D9ECCA] flex flex-wrap md:flex-nowrap space-y-3 md:space-y-0 items-baseline justify-start md:justify-between md:items-center md:space-x-1 rounded-xl from-[#ECFBFE] to-[#F8FECA] bg-gradient-to-r">
              <div className="flex flex-col min-w-[170px] md:min-w-[auto]">
                <label className="text-sm text-ink-green opacity-60">
                  <Trans>My Staked Value</Trans>
                </label>
                <span className="text-lg text-ink-green font-medium">
                  {isLoading ? (
                    <PlaceholderItem className="my-1 max-w-[80px]" />
                  ) : pageState.userStakedValue ? (
                    `$${numberWithCommas(parseFloat(pageState.userStakedValue.toFixed(2)))}`
                  ) : (
                    '-'
                  )}
                </span>
              </div>
              <div className="flex flex-col min-w-[170px] md:min-w-[auto]">
                <label className="text-sm text-ink-green opacity-60">
                  <Trans>My Rewards</Trans>
                </label>
                <span className="text-lg text-ink-green font-medium">
                  {isLoading ? (
                    <PlaceholderItem className="my-1 max-w-[80px]" />
                  ) : pageState.userEarnedValue ? (
                    `$${numberWithCommas(parseFloat(pageState.userEarnedValue.toFixed(2)))}`
                  ) : (
                    <span className="inline-flex">
                      <span className="mr-1">-</span>
                    </span>
                  )}
                </span>
              </div>
              <div className="flex flex-col ml-0 min-w-[170px] md:min-w-[auto]">
                <label className="text-sm text-ink-green opacity-60">
                  <Trans>Total Rewards for Today</Trans>
                </label>
                <span className="text-lg text-ink-green font-medium">
                  {isLoading ? (
                    <PlaceholderItem className="my-1 max-w-[80px]" />
                  ) : pageState.totalRewardsTodayUSDValue ? (
                    `$${numberWithCommas(parseFloat(pageState.totalRewardsTodayUSDValue.toFixed(2)))}`
                  ) : (
                    <span className="inline-flex">
                      <span className="mr-1">-</span>
                      <QuestionHelper text={<Trans>Data will be displayed when PPI price is available.</Trans>} />
                    </span>
                  )}
                </span>
              </div>
              <div className="flex items-center w-[170px] sm:w-auto">
                <div className="flex flex-col z-20">
                  <NavLink id={`staking`} to={'/staking'} className="text-sm text-[#EBA337] underline">
                    <Trans>Stake Your PPI</Trans>
                  </NavLink>
                  <p className="text-sm">
                    <Trans>To increase your boost factor</Trans>
                  </p>
                </div>
                <div className="w-10 h-10 mx-2 -ml-2 sm:ml-2 z-10">
                  <img src={PlaceholderIcon} alt="icon" />
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-3">
              <div className="flex">
                <Menu as="div" className="relative inline-block text-left z-10 mr-3">
                  <div>
                    <Menu.Button className="inline-flex justify-between items-center w-32 h-9 rounded-3xl border border-ink-green border-opacity-10 bg-linear-yellow-light px-4 py-2 text-base leading-4 font-medium text-ink-green capitalize">
                      <Trans>{orderTypeState}</Trans>
                      <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4 text-ink-green opacity-60" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Menu.Items className="origin-top-right absolute left-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {Object.values(OrderTypeEnum).map((i) => (
                        <Menu.Item key={i}>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active ? 'bg-grass' : '',
                                i === orderTypeState ? 'text-activate-green font-medium' : 'text-ink-green font-normal',
                                'flex w-full px-4 py-2 text-base cursor-pointer capitalize items-center'
                              )}
                              onClick={() => {
                                if (i !== orderTypeState) {
                                  setOrderTypeState(i)
                                }
                              }}
                            >
                              <Trans>{i}</Trans>
                              {i === OrderTypeEnum.apr && (
                                <span className="inline-flex ml-1">
                                  <QuestionHelper
                                    text={
                                      <Trans>
                                        {`Use the "Farm base APR" or lower bound of "APR Range" as sort criteria.`}
                                      </Trans>
                                    }
                                  />
                                </span>
                              )}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
                {!isMobile && (
                  <div>
                    <input
                      type="text"
                      placeholder={t`Search`}
                      {...register('searchQuery', {
                        onChange: debouncedChangeHandler,
                      })}
                      className="bg-linear-lime-light border border-ink-green border-opacity-10 rounded-3xl w-56 h-9 text-base text-ink-green px-4 py-2"
                    />
                  </div>
                )}
              </div>
              <div className="flex">
                <div className="inline-flex rounded-xl border border-ink-gray bg-[#F6F6F6] h-9 items-center px-1 space-x-1">
                  <button
                    type="button"
                    className={classNames(
                      'border-transparent inline-flex rounded-lg py-0.5 font-medium px-3',
                      filterTypeState === 'staked' ? 'bg-ink-green text-white' : 'text-ink-green'
                    )}
                    onClick={() => {
                      setFilterTypeState('staked')
                      localStorage.setItem('filterType', 'staked')
                    }}
                  >
                    <Trans>Staked</Trans>
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'border-transparent inline-flex rounded-lg py-0.5 font-medium px-3',
                      filterTypeState === 'all' ? 'bg-ink-green text-white' : 'text-ink-green'
                    )}
                    onClick={() => {
                      setFilterTypeState('all')
                      localStorage.setItem('filterType', 'all')
                    }}
                  >
                    <Trans>All</Trans>
                  </button>
                </div>
              </div>
            </div>

            {isMobile && (
              <div className="mt-3">
                <input
                  type="text"
                  placeholder={t`Search`}
                  {...register('searchQuery', {
                    onChange: debouncedChangeHandler,
                  })}
                  className="bg-linear-lime-light border border-ink-green border-opacity-10 rounded-3xl w-full h-9 text-base text-ink-green px-4 py-2"
                />
              </div>
            )}

            {satisfiedPools.length > 0 ? (
              <div className="rounded-xl border border-grass mt-[10px] overflow-hidden">
                <ul>
                  {satisfiedPools.map((item: any) => (
                    <FarmingItem
                      key={item.token}
                      item={item}
                      isLoading={!item.hasLoaded}
                      pairsData={pairsData}
                      onAfterAction={async () => {
                        await update()
                      }}
                    />
                  ))}
                </ul>
              </div>
            ) : (
              <div className="mt-4 mx-2">
                <Trans>No items found.</Trans>
              </div>
            )}

            {isMobile && <AnnouncementBannerBottom />}
          </div>
        </div>
      </AppBody>
    </>
  )
}
