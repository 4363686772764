import { defaultAbiCoder } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { Web3Provider } from '@ethersproject/providers'
import { formatUnits } from '@ethersproject/units'
import Multicall from 'abi/Multicall.json'
import SwappiERC20 from 'abi/swappi-core/SwappiERC20.json'
import SwappiFactory from 'abi/swappi-core/SwappiFactory.json'
import SwappiPair from 'abi/swappi-core/SwappiPair.json'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { PPI } from 'constants/tokens'
import { useCallback, useEffect, useMemo, useState } from 'react'

import useActiveWeb3React from './useActiveWeb3React'
import { useContract } from './useContract'
import useUSDCPrice from './useUSDCPrice'

interface PairAmount {
  amountA?: string
  amountB?: string
}

interface ContractInfo {
  address: string
  abi: any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getPairAmountOld = async (
  addrA: string,
  addrB: string,
  swappiFactoryInfo: ContractInfo,
  multicallInfo: ContractInfo,
  library: Web3Provider
) => {
  const defaultVal = {
    amountA: 0,
    amountB: 0,
  }

  try {
    const swappiFactory = new Contract(swappiFactoryInfo.address, swappiFactoryInfo.abi, library)
    const multicall = new Contract(multicallInfo.address, multicallInfo.abi, library)

    const pairCallData = swappiFactory.interface.encodeFunctionData('getPair', [addrA, addrB])
    const re1 = await multicall.callStatic.aggregate([[swappiFactoryInfo.address, pairCallData]])

    const [pairAddr] = defaultAbiCoder.decode(['address'], re1.returnData[0])
    const swappiPair = new Contract(pairAddr, SwappiPair.abi, library)
    const token0CallData = swappiPair.interface.encodeFunctionData('token0')
    const token1CallData = swappiPair.interface.encodeFunctionData('token1')
    const getReservesCallData = swappiPair.interface.encodeFunctionData('getReserves')
    const re2 = await multicall?.callStatic.aggregate([
      [pairAddr, token0CallData],
      [pairAddr, token1CallData],
      [pairAddr, getReservesCallData],
    ])

    const [token0] = defaultAbiCoder.decode(['address'], re2.returnData[0])
    const token0SwappiERC20 = new Contract(token0, SwappiERC20.abi, library)
    const token0Decimals = +(await token0SwappiERC20?.callStatic.decimals())

    const [token1] = defaultAbiCoder.decode(['address'], re2.returnData[1])
    const token1SwappiERC20 = new Contract(token1, SwappiERC20.abi, library)
    const token1Decimals = +(await token1SwappiERC20?.callStatic.decimals())

    const shouldReverse = addrA.toLowerCase() !== token0.toLowerCase()
    const [_reserve0, _reserve1] = defaultAbiCoder.decode(['uint256', 'uint256', 'uint256'], re2.returnData[2])

    const amountA =
      (shouldReverse ? +formatUnits(_reserve1, token1Decimals) : +formatUnits(_reserve0, token0Decimals)) || 0
    const amountB =
      (shouldReverse ? +formatUnits(_reserve0, token0Decimals) : +formatUnits(_reserve1, token1Decimals)) || 0

    return { amountA, amountB }
  } catch (error) {
    console.log(error)
    return defaultVal
  }
}

export const getPairAmount = async ({ tokenA, tokenB, pairAddr, multicallInfo, swappiFactoryInfo, library }: any) => {
  const defaultVal = {
    amountA: 0,
    amountB: 0,
  }

  try {
    const multicall = new Contract(multicallInfo.address, multicallInfo.abi, library)

    if (!pairAddr) {
      const swappiFactory = new Contract(swappiFactoryInfo.address, swappiFactoryInfo.abi, library)
      const pairCallData = swappiFactory.interface.encodeFunctionData('getPair', [tokenA.address, tokenB.address])
      const re = await multicall.callStatic.aggregate([[swappiFactoryInfo.address, pairCallData]])

      const [addr] = defaultAbiCoder.decode(['address'], re.returnData[0])
      pairAddr = addr
    }

    const swappiPair = new Contract(pairAddr, SwappiPair.abi, library)
    const token0CallData = swappiPair.interface.encodeFunctionData('token0')
    const getReservesCallData = swappiPair.interface.encodeFunctionData('getReserves')
    const re1 = await multicall?.callStatic.aggregate([
      [pairAddr, token0CallData],
      [pairAddr, getReservesCallData],
    ])

    const [token0] = defaultAbiCoder.decode(['address'], re1.returnData[0])
    const shouldReverse = tokenA.address.toLowerCase() !== token0.toLowerCase()
    const [_reserve0, _reserve1] = defaultAbiCoder.decode(['uint256', 'uint256', 'uint256'], re1.returnData[1])

    const amountA =
      (shouldReverse ? +formatUnits(_reserve1, tokenA.decimals) : +formatUnits(_reserve0, tokenA.decimals)) || 0
    const amountB =
      (shouldReverse ? +formatUnits(_reserve0, tokenB.decimals) : +formatUnits(_reserve1, tokenB.decimals)) || 0

    return { amountA, amountB }
  } catch (error) {
    console.log(error)
    return defaultVal
  }
}

export const getPairPrice = async ({
  tokenA,
  tokenB,
  pairAddr,
  multicallInfo,
  swappiFactoryInfo,
  library,
  chainId,
}: any) => {
  try {
    if (
      tokenA.address.toLowerCase() === tokenB.address.toLowerCase() &&
      tokenA.address.toLowerCase() === EVM_SPACE[chainId].FaucetUSDT.toLowerCase()
    ) {
      return 1
    }

    const { amountA, amountB } = await getPairAmount({
      tokenA,
      tokenB,
      pairAddr,
      multicallInfo,
      swappiFactoryInfo,
      library,
    })
    if (!amountA || amountA <= 0 || !amountB || amountB <= 0) return 0

    return amountA / amountB
  } catch (error) {
    console.log(error)
    return 0
  }
}

export function usePairAmount(addrA?: string | undefined, addrB?: string | undefined): PairAmount {
  const { library, chainId } = useActiveWeb3React()
  const swappiFactory = useContract(EVM_SPACE[chainId as SupportedChainId]?.SwappiFactory, SwappiFactory.abi, false)
  const multicall = useContract(EVM_SPACE[chainId as SupportedChainId]?.Multicall, Multicall.abi, false)

  const [pairAmount, setPairAmount] = useState<PairAmount>({})
  const pariCallData = useMemo(() => {
    if (!addrA || !addrB) return null
    return swappiFactory?.interface.encodeFunctionData('getPair', [addrA, addrB])
  }, [swappiFactory, addrA, addrB])

  const execCalc = useCallback(async () => {
    if (!addrA || !addrB || !multicall) {
      setPairAmount({})
      return
    }
    try {
      let res
      if (chainId && chainId in SupportedChainId && typeof pariCallData === 'string') {
        res = await multicall?.callStatic.aggregate([
          [EVM_SPACE[chainId as SupportedChainId].SwappiFactory, pariCallData],
        ])
      }
      if (!res) return
      const { returnData } = res
      // const pairAddr = web3.eth.abi.decodeParameter('address', )encodeABI
      const [pairAddr] = defaultAbiCoder.decode(['address'], returnData[0])

      const swappiPair = new Contract(pairAddr, SwappiPair.abi, library)

      const token0CallData = swappiPair.interface.encodeFunctionData('token0')
      const token1CallData = swappiPair.interface.encodeFunctionData('token1')
      const getReservesCallData = swappiPair.interface.encodeFunctionData('getReserves')
      const re2 = await multicall?.callStatic.aggregate([
        [pairAddr, token0CallData],
        [pairAddr, token1CallData],
        [pairAddr, getReservesCallData],
      ])
      // const token0 = web3.eth.abi.decodeParameter('address', re2.returnData[0])
      const [token0] = defaultAbiCoder.decode(['address'], re2.returnData[0])
      const token0SwappiERC20 = new Contract(token0, SwappiERC20.abi, library)
      const token0Decimals = +(await token0SwappiERC20?.callStatic.decimals())

      // const token1 = web3.eth.abi.decodeParameter('address', re2.returnData[1])
      const [token1] = defaultAbiCoder.decode(['address'], re2.returnData[1])
      const token1SwappiERC20 = new Contract(token1, SwappiERC20.abi, library)
      const token1Decimals = +(await token1SwappiERC20?.callStatic.decimals())

      const shouldReverse = addrA.toLowerCase() !== token0.toLowerCase()
      const [_reserve0, _reserve1] = defaultAbiCoder.decode(['uint256', 'uint256', 'uint256'], re2.returnData[2])

      const amountA = shouldReverse ? formatUnits(_reserve1, token0Decimals) : formatUnits(_reserve0, token0Decimals)
      const amountB = shouldReverse ? formatUnits(_reserve0, token1Decimals) : formatUnits(_reserve1, token1Decimals)

      setPairAmount({
        amountA,
        amountB,
      })
    } catch (error) {
      console.log('@@@ usePairAmount.execCalc Error: ', error)
      setPairAmount({})
    }
  }, [addrA, addrB, chainId, pariCallData, library, multicall])

  useEffect(() => {
    execCalc()
  }, [addrA, addrB, swappiFactory, multicall, pariCallData, execCalc])
  return useMemo(() => pairAmount, [pairAmount])
}

export default function usePairPrice(addrA?: string | undefined, addrB?: string | undefined): number | null {
  const { amountA, amountB } = usePairAmount(addrA, addrB)
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!chainId) return null

    if (typeof amountA !== 'string' || typeof amountB !== 'string') return null
    if (!+amountB) return null

    if (
      addrA?.toLowerCase() === addrB?.toLowerCase() &&
      addrA?.toLowerCase() === EVM_SPACE[chainId as SupportedChainId]?.FaucetUSDT.toLowerCase()
    ) {
      return 1
    }

    return +amountA / +amountB
  }, [amountA, amountB, addrA, addrB, chainId])
}

export function usePPIPriceWithFallback(): number | null | undefined {
  const PPICurrency = PPI[SupportedChainId.ESPACE]
  const price = useUSDCPrice(PPICurrency)
  // TODO: as a temp test and compare
  const _PPIPrice = usePairPrice(
    EVM_SPACE[SupportedChainId.ESPACE]?.FaucetUSDT,
    EVM_SPACE[SupportedChainId.ESPACE]?.PPI
  )
  const _price = price ? +price?.toSignificant(18) : null
  // console.log('@@@ usePPIPrice.result', { price: _price, _PPIPrice })
  return _price ? _price : _PPIPrice ? +_PPIPrice : null
}

export function usePPIPrice(): number | null | undefined {
  const price = useUSDCPrice(PPI[SupportedChainId.ESPACE])

  return price ? +price.toSignificant(18) : null
}

// export const useAllPairAmount = () => {

// }
