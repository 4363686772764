import { defaultAbiCoder } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { formatUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import PlaceholderItem from 'components/Spinner/PlaceholderItem'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useGraphData from 'hooks/useGraphData'
import { getPairAmount, getPairPrice } from 'hooks/usePairPrice'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { currencyMapping, getDisplayName, getUnixTimeSecond, numberWithCommas } from 'utils'

import Multicall from '../../abi/Multicall.json'
import SwappiFactory from '../../abi/swappi-core/SwappiFactory.json'
import SwappiPair from '../../abi/swappi-core/SwappiPair.json'
import MultiRewardPool from '../../abi/swappi-farm/MultiRewardPool.sol/MultiRewardPool.json'
import PPIRate from '../../abi/swappi-farm/PPIRate.sol/PPIRate.json'
import PPIToken from '../../abi/swappi-farm/PPIToken.sol/PPIToken.json'
import VotingEscrow from '../../abi/swappi-farm/VotingEscrow.sol/VotingEscrow.json'
import DualFarmingItem from '../../components/Farming/DualFarmingItem'
import AnnouncementBannerBottom from '../../components/Header/AnnouncementBannerBottom'
import { EVM_SPACE } from '../../constants/addresses'
import { APR_SHARE_NUMBER } from '../../constants/misc'
import { dualPools as poolsFromConfig } from '../../constants/pools'
import { useContract } from '../../hooks/useContract'
import { isMobile } from '../../utils/userAgent'
import AppBody from '../AppBody'

const obj = {} as any

const getMulticallAggregatesForUpdate = (pools: any, { library, dualFarmController, chainId }: any) => {
  let multicallAggregates = [] as any
  let callDataList = []

  pools.forEach((i: any) => {
    const swappiPair = new Contract(i.token, SwappiPair.abi, library)

    const token0CallData = swappiPair.interface.encodeFunctionData('token0')
    const token1CallData = swappiPair.interface.encodeFunctionData('token1')
    const totalSupplyCallData = swappiPair.interface.encodeFunctionData('totalSupply')
    const decimalsCallData = swappiPair.interface.encodeFunctionData('decimals')

    const callList1 = [
      [i.token, token0CallData],
      [i.token, token1CallData],
      [i.token, totalSupplyCallData],
      [i.token, decimalsCallData],
    ]

    let callList1Additional = [] as any
    if (obj.account) {
      const balanceOfCallData = swappiPair.interface.encodeFunctionData('balanceOf', [obj.account])
      const allowanceCallData = swappiPair.interface.encodeFunctionData('allowance', [
        obj.account,
        EVM_SPACE[chainId].MultiRewardPool,
      ])
      const userInfoCallData = dualFarmController.interface.encodeFunctionData('userInfo', [i.pid, obj.account])

      callList1Additional = [
        [i.token, balanceOfCallData],
        [i.token, allowanceCallData],
        [EVM_SPACE[chainId].MultiRewardPool, userInfoCallData],
      ]
    }

    callDataList = callList1.concat(callList1Additional)

    multicallAggregates = multicallAggregates.concat(callDataList)
  })

  return { multicallAggregates, dataCounts: callDataList.length }
}

const getAggregates = (aggregatedResult: any, dataCounts: any) => {
  const re = [] as any

  aggregatedResult.returnData.forEach((item: any, index: number) => {
    if (index % dataCounts === 0) {
      re.push({ returnData: [item] })
    } else {
      const pos = parseInt((index / dataCounts).toString(), 10)
      re[pos].returnData.push(item)
    }
  })

  return re
}

export default function DualFarming() {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId: SupportedChainId = activeWeb3React.chainId || SupportedChainId.ESPACE

  const dualFarmController = useContract(EVM_SPACE[chainId].MultiRewardPool, MultiRewardPool.abi, false)
  const ppiToken = useContract(EVM_SPACE[chainId].PPI, PPIToken.abi, false)
  const swappiFactory = useContract(EVM_SPACE[chainId].SwappiFactory, SwappiFactory.abi, false)
  const multicall = useContract(EVM_SPACE[chainId].Multicall, Multicall.abi, false)
  const ppiRate = useContract(EVM_SPACE[chainId].PPIRate, PPIRate.abi, false)
  const votingEscrow = useContract(EVM_SPACE[chainId].VotingEscrow, VotingEscrow.abi, false)

  const [poolsState, setPoolsState] = useState(poolsFromConfig)
  const [pageState, setPageState] = useState({}) as any
  const [isLoading, setIsLoading] = useState(false)

  const { pairsData } = useGraphData(poolsFromConfig.map((i) => i.token.toLowerCase()))

  const updatePools = useCallback(
    async (pools: any) => {
      if (!library || !dualFarmController || !ppiToken || !swappiFactory || !multicall || !ppiRate || !votingEscrow)
        return

      let userStakedValue = 0
      let userEarnedValue = 0
      let totalStakedValue = 0

      const getPoolInfoCallData = dualFarmController.interface.encodeFunctionData('getPoolInfo', [0])
      const p1 = multicall.callStatic.aggregate([[EVM_SPACE[chainId].MultiRewardPool, getPoolInfoCallData]])

      const multicallAggregatesObj = getMulticallAggregatesForUpdate(pools, {
        library,
        chainId,
        dualFarmController,
      })
      const p2 = multicall.callStatic.aggregate(multicallAggregatesObj.multicallAggregates)

      const [re1, aggregatedResult] = await Promise.all([p1, p2])

      const poolsFromContract = defaultAbiCoder.decode(
        ['tuple(address, tuple(address, uint256, uint256)[], uint256, uint256, uint256, address)[]'],
        re1.returnData[0]
      )[0]

      pools = pools.map((i: any, index: number) => {
        const pos = poolsFromContract.findIndex((arr: any) => arr[0].toLowerCase() === i.token.toLowerCase())
        const poolFromContract = poolsFromContract[pos]

        const rewards = [
          { token: poolFromContract[1][0][0], rate: poolFromContract[1][0][1] },
          { token: poolFromContract[1][1][0], rate: poolFromContract[1][1][1] },
        ]

        const endSecond = +poolFromContract[4]
        const nowSecond = getUnixTimeSecond()
        const hasEnded = endSecond ? nowSecond > endSecond : false

        return {
          ...i,
          token: poolFromContract[0],
          rewards,
          totalSupply: poolFromContract[2],
          lastRewardTime: poolFromContract[3],
          hasEnded,
          sponsor: poolFromContract[5],
        }
      })

      const swappiFactoryInfo = {
        address: EVM_SPACE[chainId].SwappiFactory,
        abi: SwappiFactory.abi,
      }
      const multicallInfo = {
        address: EVM_SPACE[chainId].Multicall,
        abi: Multicall.abi,
      }

      const aggregates = getAggregates(aggregatedResult, multicallAggregatesObj.dataCounts)

      pools = await Promise.all(
        pools.map(async (i: any, index: number) => {
          const re = aggregates[index]

          const token0Addr = defaultAbiCoder.decode(['address'], re.returnData[0])[0]
          const token1Addr = defaultAbiCoder.decode(['address'], re.returnData[1])[0]
          const token0 = currencyMapping({ address: token0Addr }, chainId)
          const token1 = currencyMapping({ address: token1Addr }, chainId)

          const allTotalSupply = defaultAbiCoder.decode(['uint256'], re.returnData[2])[0]
          const decimals = +defaultAbiCoder.decode(['uint8'], re.returnData[3])[0]

          const balance = obj.account && defaultAbiCoder.decode(['uint256'], re.returnData[4])[0]
          const allowance = obj.account && +defaultAbiCoder.decode(['uint256'], re.returnData[5])[0]

          const rewardToken0 = currencyMapping({ address: i.rewards[0].token }, chainId)
          const rewardToken1 = currencyMapping({ address: i.rewards[1].token }, chainId)

          const p0 = getPairAmount({
            tokenA: token0,
            tokenB: token1,
            pairAddr: i.token,
            multicallInfo,
            swappiFactoryInfo,
            library,
          })
          const p1 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: token0,
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p2 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: token1,
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p3 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: rewardToken0,
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p4 = getPairPrice({
            tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
            tokenB: rewardToken1,
            swappiFactoryInfo,
            multicallInfo,
            library,
            chainId,
          })
          const p5 = dualFarmController.callStatic.deposit(i.pid, new BigNumber(0).toString(10), { from: obj.account })

          const promises = [p0, p1, p2, p3, p4, p5]
          const results = await Promise.all(promises.map((p) => p.catch(() => undefined)))
          const validResults = results.filter((result) => !(result instanceof Error))
          const [{ amountA, amountB }, price0, price1, rewardPrice0, rewardPrice1] = validResults
          const [rewards, rewardAmounts] = validResults[5]
            ? validResults[5]
            : [
                ['0x', '0x'],
                [0, 0],
              ]

          const lpPoolTotalLiquidity = new BigNumber(amountA)
            .times(price0)
            .plus(new BigNumber(amountB).times(price1))
            .toNumber()

          const liquidity = new BigNumber(i.totalSupply.toString())
            .div(allTotalSupply.toString())
            .times(lpPoolTotalLiquidity)
            .toString()

          const pair = {
            token0,
            token1,
            alignedBalance: balance ? formatUnits(balance, decimals) : balance,
            allowance,
            decimals,
            displayName: getDisplayName(token0.symbol, token1.symbol),
            liquidity,
            lpPoolTotalLiquidity,
          }

          const lpPrice = new BigNumber(lpPoolTotalLiquidity.toString())
            .div(new BigNumber(allTotalSupply.toString()).div(10 ** decimals))
            .toString()

          const yearSeconds = 3600 * 24 * 365
          const alignedRewardInYear0 = new BigNumber(i.rewards[0].rate.toString())
            .div(10 ** rewardToken0.decimals)
            .times(yearSeconds)
            .toNumber()
          const alignedRewardInYear1 = new BigNumber(i.rewards[1].rate.toString())
            .div(10 ** rewardToken1.decimals)
            .times(yearSeconds)
            .toNumber()

          const rewardInfo = {
            token0: {
              ...rewardToken0,
              ...i.rewards[0],
              rewardInYear: alignedRewardInYear0,
              rewardInYearUSD: new BigNumber(alignedRewardInYear0).times(rewardPrice0).toNumber(),
            },
            token1: {
              ...rewardToken1,
              ...i.rewards[1],
              rewardInYear: alignedRewardInYear1,
              rewardInYearUSD: new BigNumber(alignedRewardInYear1).times(rewardPrice1).toNumber(),
            },
          }

          const poolTotalRewards = rewardInfo.token0.rewardInYearUSD + rewardInfo.token1.rewardInYearUSD
          const farmBaseAPR =
            liquidity && parseFloat(liquidity) > 0 ? new BigNumber(poolTotalRewards).div(liquidity).toNumber() : ''

          let accountedInfo = {}
          if (obj.account) {
            const amount = defaultAbiCoder.decode(['uint256'], re.returnData[6])[0]

            const alignedAmount = formatUnits(amount, decimals)

            const exactAmountUSDValue = new BigNumber(new BigNumber(amount.toString()).div(10 ** decimals))
              .times(lpPrice)
              .toNumber()

            userStakedValue = new BigNumber(userStakedValue).plus(exactAmountUSDValue).toNumber()

            const userInfo = {
              amount,
              alignedAmount,
              amountUSDValue: exactAmountUSDValue,
            }

            const indexer = rewards[0].toLowerCase() === rewardInfo.token0.address.toLowerCase() ? 0 : 1

            const userReward = {
              token0: Object.assign({}, rewardToken0),
              token1: Object.assign({}, rewardToken1),
            }

            userReward.token0.rewardAmount = +rewardAmounts[indexer]
            userReward.token0.alignedRewardAmount = new BigNumber(userReward.token0.rewardAmount)
              .div(10 ** userReward.token0.decimals)
              .toNumber()
            userReward.token0.rewardUSDValue = userReward.token0.alignedRewardAmount * rewardPrice0

            userReward.token1.rewardAmount = +rewardAmounts[indexer === 0 ? 1 : 0]
            userReward.token1.alignedRewardAmount = new BigNumber(userReward.token1.rewardAmount)
              .div(10 ** userReward.token1.decimals)
              .toNumber()
            userReward.token1.rewardUSDValue = userReward.token1.alignedRewardAmount * rewardPrice1

            userEarnedValue = new BigNumber(userEarnedValue)
              .plus(userReward.token0.rewardUSDValue)
              .plus(userReward.token1.rewardUSDValue)
              .toNumber()

            accountedInfo = {
              userInfo,
              userReward,
            }
          }

          const totalAmountUSDValue = new BigNumber(new BigNumber(i.totalSupply.toString()).div(10 ** decimals))
            .times(lpPrice)
            .toNumber()

          totalStakedValue = new BigNumber(totalStakedValue).plus(totalAmountUSDValue).toNumber()

          return {
            ...i,
            pair,
            pid: i.pid,
            rewardInfo,
            farmBaseAPR: farmBaseAPR ? farmBaseAPR / APR_SHARE_NUMBER : '',
            ...accountedInfo,
            hasLoaded: true,
          }
        })
      )

      obj.pools = pools
      obj.userStakedValue = userStakedValue
      obj.userEarnedValue = userEarnedValue
      obj.totalStakedValue = totalStakedValue
    },

    [library, dualFarmController, ppiToken, swappiFactory, multicall, ppiRate, votingEscrow, chainId]
  )

  const setStates = useCallback(() => {
    setPoolsState(obj.pools)
    setPageState({
      userStakedValue: obj.userStakedValue,
      userEarnedValue: obj.userEarnedValue,
      totalStakedValue: obj.totalStakedValue,
    })
  }, [])

  const update = useCallback(async () => {
    try {
      obj.account = account

      await updatePools(obj.pools)

      setStates()
    } catch (error) {
      console.log(error)
    }
  }, [updatePools, account, setStates])

  // init with pools config
  useEffect(() => {
    obj.pools = poolsFromConfig
  }, [])

  // update
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      if (chainId && library && obj.pools) {
        await update()
        setIsLoading(false)
      }
    })()
  }, [chainId, library, update])

  return (
    <>
      <AppBody maxWidth="900px">
        <div className="main-container">
          <div className="flex justify-between pt-5 px-5 pb-2">
            <div className="flex items-center">
              <h5 className="text-base tracking-widest text-ink-green mr-2 font-medium uppercase">
                <Trans>Dual Farming</Trans>
              </h5>
              <p className="border-l-[3px] border-windy h-3">&nbsp;</p>
              <p className="text-xs text-lime px-1 mt-[2px]">
                <Trans>Stake LP tokens to earn</Trans>
              </p>
            </div>
          </div>

          <div id="farming-page" className="bg-white p-3 sm:p-4 rounded-3xl rounded-tr-none rounded-bl-none relative">
            <div className="py-4 px-3 sm:px-5 sm:py-5 border border-[#D9ECCA] flex flex-wrap md:flex-nowrap space-y-3 md:space-y-0 items-baseline justify-start md:justify-between md:items-center md:space-x-1 rounded-xl from-[#ECFBFE] to-[#F8FECA] bg-gradient-to-r">
              <div className="flex flex-col min-w-[170px] md:min-w-[auto]">
                <label className="text-sm text-ink-green opacity-60">
                  <Trans>My Staked Value</Trans>
                </label>
                <span className="text-lg text-ink-green font-medium">
                  {isLoading ? (
                    <PlaceholderItem className="my-1 max-w-[80px]" />
                  ) : pageState.userStakedValue ? (
                    `$${numberWithCommas(parseFloat(pageState.userStakedValue.toFixed(2)))}`
                  ) : (
                    '-'
                  )}
                </span>
              </div>
              <div className="flex flex-col min-w-[170px] md:min-w-[auto]">
                <label className="text-sm text-ink-green opacity-60">
                  <Trans>My Rewards</Trans>
                </label>
                <span className="text-lg text-ink-green font-medium">
                  {isLoading ? (
                    <PlaceholderItem className="my-1 max-w-[80px]" />
                  ) : pageState.userEarnedValue ? (
                    `$${numberWithCommas(parseFloat(pageState.userEarnedValue.toFixed(2)))}`
                  ) : (
                    <span className="inline-flex">
                      <span className="mr-1">-</span>
                    </span>
                  )}
                </span>
              </div>
              <div className="flex flex-col ml-0 min-w-[170px] md:min-w-[auto]">
                <label className="text-sm text-ink-green opacity-60">
                  <Trans>Total Staked Value</Trans>
                </label>
                <span className="text-lg text-ink-green font-medium">
                  {isLoading ? (
                    <PlaceholderItem className="my-1 max-w-[80px]" />
                  ) : pageState.totalStakedValue ? (
                    `$${numberWithCommas(parseFloat(pageState.totalStakedValue.toFixed(2)))}`
                  ) : (
                    <span className="inline-flex">
                      <span className="mr-1">-</span>
                    </span>
                  )}
                </span>
              </div>
              <div className="flex flex-col ml-0 min-w-[170px] md:min-w-[auto]"></div>
            </div>

            <div className="rounded-xl border border-grass mt-4 overflow-hidden">
              <ul>
                {poolsState.length > 0 &&
                  poolsState.map((item: any) => {
                    return (
                      <DualFarmingItem
                        key={item.token}
                        item={item}
                        isLoading={!item.hasLoaded}
                        pairsData={pairsData}
                        onAfterAction={async () => {
                          await update()
                        }}
                      />
                    )
                  })}
              </ul>
            </div>

            {isMobile && <AnnouncementBannerBottom />}
          </div>
        </div>
      </AppBody>
    </>
  )
}
