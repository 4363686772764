import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import HeadlessModal from 'components/Modal/HeadlessModal'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { MAX_INT } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useRef, useState } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { TransactionInfo, TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, classNames, getAddressOrName, getProviderOrSigner } from 'utils'

import SwappiPair from '../../abi/swappi-core/SwappiPair.json'
import FarmController from '../../abi/swappi-farm/FarmController.sol/FarmController.json'
import MultiRewardPool from '../../abi/swappi-farm/MultiRewardPool.sol/MultiRewardPool.json'
import Spinner from '../../components/Spinner'
import { useContract } from '../../hooks/useContract'
import DoubleCurrencyLogo from '../DoubleLogo'

export default function ActionModal({ item, actionType, onClose, onAfterAction, farmingType = 'single' }: any) {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId: SupportedChainId = activeWeb3React.chainId || SupportedChainId.ESPACE

  const farmingContract = farmingType === 'single' ? FarmController : MultiRewardPool
  const farmingAddress =
    farmingType === 'single' ? EVM_SPACE[chainId].FarmController : EVM_SPACE[chainId].MultiRewardPool
  const farmingControllerWriter = useContract(farmingAddress, farmingContract.abi, true)

  const swappiPair = useContract(item.token, SwappiPair.abi, false)

  const addTransaction = useTransactionAdder()

  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(true)

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: 'onChange' })

  const [isProcessing, setIsProcessing] = useState(false)
  const [shouldApproveState, setShouldApproveState] = useState(false)

  const inputElement = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register('actionAmount', {
    min: 0,
    max: actionType === 'deposit' ? item.pair.alignedBalance : item.userInfo.alignedAmount,
    onChange: async (e) => {
      setValue('actionAmount', e.target.value)
      setShouldApproveState(shouldApprove())

      if (!e.target.value || parseFloat(e.target.value) <= 0) {
        setShouldDisableSubmit(true)
      } else {
        setShouldDisableSubmit(false)
      }
    },
  })

  const getAllowance = useCallback(async () => {
    if (!swappiPair || !chainId || !account) return

    return +(await swappiPair.callStatic.allowance(account, farmingAddress))
  }, [swappiPair, chainId, account, farmingAddress])

  const shouldApprove = useCallback((allowance = item.pair.allowance) => {
    const actionAmount = parseFloat(getValues()['actionAmount']) || 0
    const underlyingActionAmount = new BigNumber(actionAmount).times(10 ** item.pair.decimals)

    return new BigNumber(allowance).isLessThan(underlyingActionAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const approve = useCallback(async () => {
    try {
      if (!swappiPair || !chainId || !library || !account) return

      const swappiPairWriter = swappiPair.connect(getProviderOrSigner(library, account))

      setIsProcessing(true)

      const methodName = 'approve'
      const methodParams = [farmingAddress, MAX_INT]

      const gasLimit = calculateGasMargin(await swappiPairWriter.estimateGas[methodName](...methodParams))

      const response = await swappiPairWriter[methodName](...methodParams, {
        gasLimit,
        gasPrice: undefined,
      })
      addTransaction(response, { type: TransactionType.FARM_APPROVE })

      await response.wait()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessing(false)
    }
  }, [swappiPair, addTransaction, chainId, farmingAddress, library, account])

  const action = useCallback(async () => {
    try {
      if (!farmingControllerWriter) return

      const actionAmountInput = getValues()['actionAmount']
      const actionAmount = new BigNumber(actionAmountInput || '0')
      if (actionAmount.isZero()) return

      setIsProcessing(true)

      const underlyingActionAmount = parseUnits(actionAmount.toFixed(item.pair.decimals), item.pair.decimals)

      let methodName = ''
      const methodParams = [item.pid, underlyingActionAmount.toString()]
      let txType

      if (actionType === 'deposit' && actionAmount.isLessThanOrEqualTo(item.pair.alignedBalance)) {
        methodName = 'deposit'
        txType = TransactionType.FARM_DEPOSIT
      } else if (actionType === 'withdraw' && actionAmount.isLessThanOrEqualTo(item.userInfo.alignedAmount)) {
        methodName = 'withdraw'
        txType = TransactionType.FARM_WITHDRAW
      }

      if (!txType) return

      const gasLimit = calculateGasMargin(await farmingControllerWriter.estimateGas[methodName](...methodParams))

      const response = await farmingControllerWriter[methodName](...methodParams, {
        gasLimit,
        gasPrice: undefined,
      })
      addTransaction(response, { type: txType } as TransactionInfo)

      await response.wait()
      onClose()
      onAfterAction()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmingControllerWriter, addTransaction, item.pid, actionType])

  return (
    <HeadlessModal
      title={actionType === 'deposit' ? 'Stake LP tokens' : 'Unstake LP tokens'}
      isOpen={true}
      onDismiss={onClose}
      initialFocusRef={inputElement}
    >
      <div className="mt-5">
        <div className="rounded-xl border border-[#D9ECCA] p-3 flex justify-between from-[#FAFAEA] to-[#FBFFDF] bg-gradient-to-r">
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center">
              <label className="text-xs opacity-60">{actionType === 'deposit' ? 'Stake' : 'Unstake'}</label>
              <div className="flex items-center">
                <span className="text-xs">
                  <Trans>
                    Balance:{' '}
                    {actionType === 'deposit' ? ` ${item.pair.alignedBalance}` : ` ${item.userInfo.alignedAmount}`}
                  </Trans>
                </span>
                <button
                  id="farming-modal-button-1"
                  className="btn btn-default text-xs ml-2 py-0.5 rounded bg-bluegreen"
                  onClick={() => {
                    const maxValue = actionType === 'deposit' ? item.pair.alignedBalance : item.userInfo.alignedAmount

                    setValue('actionAmount', maxValue)
                    clearErrors('actionAmount')
                    setShouldApproveState(shouldApprove())
                    setShouldDisableSubmit(false)
                  }}
                >
                  <Trans>Max</Trans>
                </button>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <input
                type="number"
                autoComplete="no"
                className="w-full text-lg text-[#2A3D4A] font-medium bg-transparent disabled:opacity-75 hover:outline-none focus:outline-none"
                placeholder="0"
                ref={(e) => {
                  ref(e)
                  inputElement.current = e
                }}
                {...rest}
              />
              <div className="ml-4 flex flex-1 space-x-1 items-center">
                <DoubleCurrencyLogo currency0={item.pair.token0} currency1={item.pair.token1} size={18} />
                <span className="whitespace-nowrap">
                  <Trans>{item.pair.displayName} LP</Trans>
                </span>
              </div>
            </div>
          </div>
        </div>
        <NavLink
          to={`/add/v2/${getAddressOrName(item.pair.token0.address, chainId)}/${getAddressOrName(
            item.pair.token1.address,
            chainId
          )}`}
          className="inline-flex space-x-1 text-xs text-[#EBA337] underline mt-3 mb-1"
          target="_blank"
        >
          <span>
            <Trans>Get {item.pair.displayName} LP</Trans>
          </span>
          <LinkIcon size={14} />
        </NavLink>
      </div>

      <div className="mt-4 flex justify-between w-full space-x-2">
        {errors.actionAmount ? (
          <button id="farming-modal-button-2" disabled className="btn btn-disabled w-full justify-center py-2.5">
            {errors.actionAmount.type === 'max' && <Trans>Amount exceed max</Trans>}
            {errors.actionAmount.type === 'min' && <Trans>Amount should greater than zero</Trans>}
          </button>
        ) : (
          <>
            <button
              id="farming-modal-button-3"
              className="btn btn-secondary w-full flex justify-center border-2 border-[#2A3D4A]"
              onClick={onClose}
            >
              <Trans>Cancel</Trans>
            </button>
            {shouldApproveState ? (
              <button
                id="farming-modal-button-4"
                className={classNames(
                  isProcessing ? 'cursor-not-allowed' : '',
                  'btn btn-primary w-full flex justify-center'
                )}
                disabled={isProcessing}
                onClick={async () => {
                  if (!isProcessing) {
                    await approve()
                    setShouldApproveState(shouldApprove(await getAllowance()))
                  }
                }}
              >
                {isProcessing && <Spinner className="-ml-1 mr-3 text-white" />}
                <Trans>Approve</Trans>
              </button>
            ) : (
              <button
                id="farming-modal-button-5"
                className={classNames(
                  isProcessing || shouldDisableSubmit ? 'btn-disabled' : '',
                  'btn btn-primary w-full flex justify-center py-2.5'
                )}
                disabled={isProcessing || shouldDisableSubmit}
                onClick={async () => {
                  if (!isProcessing) {
                    await action()
                  }
                }}
              >
                {isProcessing && <Spinner className="-ml-1 mr-3 text-white" />}
                <Trans>Confirm</Trans>
              </button>
            )}
          </>
        )}
      </div>
    </HeadlessModal>
  )
}
