import { formatUnits } from '@ethersproject/units'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTokenContract } from 'hooks/useContract'
import { useEffect, useState } from 'react'
import { isAddress } from 'utils'

const useScore = (isWithSetter = false) => {
  const { account } = useActiveWeb3React()
  const activeWeb3React = useActiveWeb3React()
  const chainId = activeWeb3React.chainId as SupportedChainId
  const VotingEscrowContract = useTokenContract(EVM_SPACE[chainId]?.VotingEscrow)
  const [score, setScore] = useState<number | undefined>(undefined)
  const [hasOutdated, setHasOutdated] = useState(true)

  useEffect(() => {
    if (!VotingEscrowContract || !account || !isAddress(account) || !hasOutdated) return
    Promise.all([VotingEscrowContract.decimals(), VotingEscrowContract.balanceOf(account)]).then(
      ([decimals, balance]) => {
        setScore(Number(formatUnits(balance, +decimals) ?? '0'))
        setHasOutdated(false)
      }
    )
  }, [account, VotingEscrowContract, hasOutdated])

  return (isWithSetter ? { score, setHasOutdated } : score) as any
}

export default useScore
