import { Trans } from '@lingui/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useIsGenesisNftHolder } from 'hooks/useIsGenesisNftHolder'
import { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import AvocadoIcon from '../../../../assets/images/avocado.svg'
import { SupportedChainId } from '../../../../constants/chains'
import { classNames, displayVal, shortenAddress } from '../../../../utils'
import { ExplorerDataType, getExplorerLink } from '../../../../utils/getExplorerLink'
import AppBody from '../../../AppBody'
import useIdoInfo from '../../hooks/useIdoInfo'
import { AuctionStageEnum } from '../../utils'
import { IdoToken, Timeline } from '..'
import ClaimModal from './ClaimModal'
import MessageModal from './MessageModal'
import ParticipateModal from './ParticipateModal'

export default function Index({
  match: {
    params: { address },
  },
}: RouteComponentProps<{ address: string }>) {
  const activeWeb3React = useActiveWeb3React()
  const chainId = activeWeb3React.chainId as SupportedChainId

  const [isParticipateOpen, setIsParticipateOpen] = useState(false)
  const [isClaimOpen, setIsClaimOpen] = useState(false)
  const [isMessageOpen, setIsMessageOpen] = useState(false)
  const [tabState, setTabState] = useState(0)

  const isGenesisNftHolder = useIsGenesisNftHolder()

  const idoInfo = useIdoInfo(address)
  const { tokenState, idoState, init } = idoInfo
  const requiredThreshold = Math.max(
    0,
    idoState.alignedVeTokenThreshold - (isGenesisNftHolder ? idoState.alignedNFTThreshold : 0)
  )
  const ableToParticipate = idoState && (idoState.isInWhitelist || idoState.alignedVeBalance >= requiredThreshold)

  return tokenState ? (
    <>
      <AppBody maxWidth="900px">
        <div className="main-container">
          <IdoToken
            {...idoInfo}
            source="Detail"
            onClickParticipate={() => {
              ableToParticipate ? setIsParticipateOpen(true) : setIsMessageOpen(true)
            }}
            onClickClaim={() => {
              setIsClaimOpen(true)
            }}
          >
            <div className="border border-grass bg-linear-lime rounded-xl px-6 py-3 mt-4">
              <div className="flex justify-between flex-wrap">
                <div className="flex flex-col">
                  <span className="text-ink-green text-sm text-opacity-60">
                    <Trans>Current Token Price</Trans>
                  </span>
                  <span className="text-ink-green font-medium">
                    {idoState.currentStage === AuctionStageEnum.privateSale
                      ? idoState.alignedPriPrice
                      : idoState.currentStage === AuctionStageEnum.publicSale
                      ? idoState.alignedPubPrice
                      : '-'}{' '}
                    CFX
                  </span>
                </div>
                <div className="flex flex-col text-right sm:text-left">
                  <span className="text-ink-green text-sm text-opacity-60">
                    <Trans>Amount Raised</Trans>
                  </span>
                  <span className="text-ink-green font-medium">
                    {displayVal(idoState.alignedAmtOfCFXCollected, 4)} CFX
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-ink-green text-sm text-opacity-60">
                    <Trans>Commitment</Trans>
                  </span>
                  <span className="text-ink-green font-medium">
                    {displayVal(idoState.alignedAmtOfCFXForBuyer, 4)} CFX
                  </span>
                </div>
                <div className="flex flex-col text-right sm:text-left">
                  <span className="text-ink-green text-sm text-opacity-60">
                    <Trans>Claimable Rewards</Trans>
                  </span>
                  <span className="text-ink-green font-medium">
                    {displayVal(idoState.alignedClaimableAmt, 4)} {tokenState.symbol}
                  </span>
                </div>
              </div>
            </div>

            <div className="border border-grass rounded-xl py-3 px-6 mt-4">
              <div>
                <ul className="inline-flex space-x-6 border-b border-grass">
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      setTabState(0)
                    }}
                  >
                    <span
                      className={classNames(
                        tabState === 0 ? 'border-b-2 border-limo leading-[33px]' : 'leading-[32px]',
                        'font-medium pb-1.5'
                      )}
                    >
                      <Trans>Token Sale Detail</Trans>
                    </span>
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      setTabState(1)
                    }}
                  >
                    <span
                      className={classNames(
                        tabState === 1 ? 'border-b-2 border-limo leading-[33px]' : 'leading-[32px]',
                        'font-medium pb-1.5'
                      )}
                    >
                      <Trans>About Project</Trans>
                    </span>
                  </li>
                </ul>
              </div>

              <div className="mt-8 mb-6">
                {tabState === 0 && (
                  <div className="flex justify-between space-y-14 sm:space-y-0 sm:space-x-14 text-[15px] flex-wrap">
                    <div className="flex flex-col flex-1 space-y-5 min-w-[200px]">
                      <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Token Contract Address</Trans>
                        </span>
                        <span className="text-ink-green">
                          {chainId && idoState.idoTokenAddress ? (
                            <a
                              target="_blank"
                              href={getExplorerLink(chainId, idoState.idoTokenAddress, ExplorerDataType.TOKEN)}
                              rel="noreferrer"
                              className="text-ink-green hover:underline"
                            >
                              {shortenAddress(idoState.idoTokenAddress)}
                            </a>
                          ) : (
                            '-'
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Token Sale Contract Address</Trans>
                        </span>
                        <span className="text-ink-green">
                          {chainId && idoState.auctionAddress ? (
                            <a
                              target="_blank"
                              href={getExplorerLink(chainId, idoState.auctionAddress, ExplorerDataType.ADDRESS)}
                              rel="noreferrer"
                              className="text-ink-green hover:underline"
                            >
                              {shortenAddress(idoState.auctionAddress)}
                            </a>
                          ) : (
                            '-'
                          )}
                        </span>
                      </div>
                      {/* <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Total Token Supply</Trans>
                        </span>
                        <span className="text-ink-green">
                          {displayVal(idoState.alignedTotalSupply, 4)} {tokenState.symbol}
                        </span>
                      </div> */}
                      <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Tokens For Sale</Trans>
                        </span>
                        <span className="text-ink-green">
                          {displayVal(idoState.alignedTotalAmount, 4)} {tokenState.symbol}
                        </span>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Liquidity</Trans>
                        </span>
                        <span className="text-ink-green">
                          {idoState.forLpRatio > 0
                            ? `${displayVal(idoState.forLpRatio * 100, 2)}% of CFX raised and equivalent ${
                                tokenState?.symbol
                              }`
                            : 'No liquidity locked'}
                        </span>
                      </div>
                      {/* <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Pre-Sale Max Per Address</Trans>
                        </span>
                        <span className="text-ink-green">
                          {displayVal(idoState?.alignedPriSaleUpperLimitPerAddress, 4)} {tokenState.symbol}
                        </span>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <span className="text-ink-green text-opacity-60">
                          <Trans>Genesis NFT Holder Offer</Trans>
                        </span>
                        <span className="text-ink-green">
                          Reduce required Launchpad Score by {displayVal(idoState?.alignedNFTThreshold, 4)}
                        </span>
                      </div> */}
                    </div>
                    <div className="flex flex-1 relative">
                      <div className="absolute top-[3px]">
                        <Timeline lineHeights={[76, 78, 78]} current={idoState.currentStage - 1} />
                      </div>
                      <div className="flex flex-col space-y-12 ml-9">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            {idoState.currentStage === AuctionStageEnum.upcoming && (
                              <div className="w-4 h-4 mr-1 inline-flex">
                                <img src={AvocadoIcon} alt="icon" />
                              </div>
                            )}
                            <span
                              className={classNames(
                                idoState.currentStage === AuctionStageEnum.upcoming
                                  ? 'gradient-text-golden-green'
                                  : 'text-ink-green text-opacity-60'
                              )}
                            >
                              <Trans>Token Sale is Upcoming</Trans>
                            </span>
                          </div>
                          <span className="text-ink-green">&nbsp;</span>
                        </div>
                        <div className="flex flex-col space-y-1">
                          <div className="flex items-center">
                            {idoState.currentStage === AuctionStageEnum.privateSale && (
                              <div className="w-4 h-4 mr-1 inline-flex">
                                <img src={AvocadoIcon} alt="icon" />
                              </div>
                            )}
                            <span
                              className={classNames(
                                idoState.currentStage === AuctionStageEnum.privateSale
                                  ? 'gradient-text-golden-green'
                                  : 'text-ink-green text-opacity-60'
                              )}
                            >
                              <Trans>Pre-Sale Start Time</Trans>
                            </span>
                          </div>
                          <span
                            className={classNames(
                              idoState.currentStage === AuctionStageEnum.privateSale
                                ? 'text-bluegreen'
                                : 'text-ink-green'
                            )}
                          >
                            {idoState.priSaleInfo && +idoState.priSaleInfo.startTime
                              ? new Date(+idoState.priSaleInfo.startTime * 1000).toLocaleString()
                              : '-'}
                          </span>
                        </div>
                        <div className="flex flex-col space-y-1">
                          <div className="flex items-center">
                            {idoState.currentStage === AuctionStageEnum.publicSale && (
                              <div className="w-4 h-4 mr-1 inline-flex">
                                <img src={AvocadoIcon} alt="icon" />
                              </div>
                            )}
                            <span
                              className={classNames(
                                idoState.currentStage === AuctionStageEnum.publicSale
                                  ? 'gradient-text-golden-green'
                                  : 'text-ink-green text-opacity-60'
                              )}
                            >
                              <Trans>Latest Start Time for Public Sale</Trans>
                            </span>
                          </div>
                          <span
                            className={classNames(
                              idoState.currentStage === AuctionStageEnum.publicSale
                                ? 'text-bluegreen'
                                : 'text-ink-green'
                            )}
                          >
                            {idoState.priSaleInfo && +idoState.priSaleInfo.endTime
                              ? new Date(+idoState.priSaleInfo.endTime * 1000).toLocaleString()
                              : '-'}
                          </span>
                        </div>
                        <div className="flex flex-col space-y-1">
                          <div className="flex items-center">
                            {idoState.currentStage === AuctionStageEnum.ended && (
                              <div className="w-4 h-4 mr-1 inline-flex">
                                <img src={AvocadoIcon} alt="icon" />
                              </div>
                            )}
                            <span
                              className={classNames(
                                idoState.currentStage === AuctionStageEnum.ended
                                  ? 'gradient-text-golden-green'
                                  : 'text-ink-green text-opacity-60'
                              )}
                            >
                              <Trans>Token Claim Time</Trans>
                            </span>
                          </div>
                          <span
                            className={classNames(
                              idoState.currentStage === AuctionStageEnum.ended ? 'text-bluegreen' : 'text-ink-green'
                            )}
                          >
                            {idoState.pubSaleInfo && +idoState.pubSaleInfo.endTime
                              ? new Date(+idoState.pubSaleInfo.endTime * 1000).toLocaleString()
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tabState === 1 && <div className="text-[15px] text-ink-green">{tokenState.description || '-'}</div>}
              </div>
            </div>
          </IdoToken>
        </div>
      </AppBody>

      {isParticipateOpen && idoState.idoTokenAddress && tokenState && (
        <ParticipateModal
          item={idoState}
          info={tokenState}
          isGenesisNftHolder={isGenesisNftHolder}
          onClose={() => {
            setIsParticipateOpen(false)
          }}
          onAfterAction={init}
        />
      )}

      {isClaimOpen && idoState.idoTokenAddress && tokenState && (
        <ClaimModal
          item={idoState}
          info={tokenState}
          onClose={() => {
            setIsClaimOpen(false)
          }}
          onAfterAction={init}
        />
      )}

      {isMessageOpen && idoState.idoTokenAddress && (
        <MessageModal
          requiredThreshold={requiredThreshold}
          onClose={() => {
            setIsMessageOpen(false)
          }}
        />
      )}
    </>
  ) : (
    <div className="min-h-screen"></div>
  )
}
