import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { ReactNode, useContext } from 'react'
import { CheckCircle } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'

import WaitingForConfirmationIcon from '../../assets/images/avo_confirm.gif'
import FailIcon from '../../assets/images/fail.png'
import MetaMaskLogo from '../../assets/images/metamask-fox.svg'
import SubmittedIcon from '../../assets/images/submitted.png'
import { ExternalLink } from '../../theme'
import { CloseIcon } from '../../theme'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { AutoColumn, ColumnCenter } from '../Column'
import Modal from '../Modal'
import { RowBetween, RowFixed } from '../Row'

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
`
const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '32px 0;')};
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline,
}: {
  onDismiss: () => void
  pendingText: ReactNode
  inline?: boolean // not in modal
}) {
  return (
    <div className="flex flex-col w-full">
      <Wrapper>
        <AutoColumn gap="md">
          {!inline && (
            <RowBetween>
              <div className="font-bold text-ink-green text-base">
                <Trans>Confirm Swap</Trans>
              </div>
              <CloseIcon onClick={onDismiss} />
            </RowBetween>
          )}
          <div className="my-[20px] bg-linear-egg mx-auto">
            <img src={WaitingForConfirmationIcon} alt="icon" className="w-[224px] h-[224px]" />
          </div>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={18} textAlign="center">
              <Trans>Waiting For Confirmation</Trans>
            </Text>
          </AutoColumn>
        </AutoColumn>
      </Wrapper>
      <div className="bg-linear-lime w-full py-5 mt-2">
        <Text fontWeight={500} fontSize={14} textAlign="center">
          {pendingText}
        </Text>
        <Text fontWeight={400} fontSize={14} color="#565A69" textAlign="center" marginTop="12px">
          <Trans>Confirm this transaction in your wallet</Trans>
        </Text>
      </div>
    </div>
  )
}
function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: number
  currencyToAdd?: Currency | undefined
  inline?: boolean // not in modal
}) {
  const theme = useContext(ThemeContext)

  const { library } = useActiveWeb3React()

  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  return (
    <div className="flex flex-col w-full">
      <Wrapper>
        <Section inline={inline}>
          {!inline && (
            <RowBetween>
              <div className="font-bold text-ink-green text-base">
                <Trans>Confirm Swap</Trans>
              </div>
              <CloseIcon onClick={onDismiss} />
            </RowBetween>
          )}
          <ConfirmedIcon inline={inline}>
            <img src={SubmittedIcon} alt="icon" width={224} height={224} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={18} textAlign="center">
              <Trans>Transaction Submitted</Trans>
            </Text>
            {chainId && hash && (
              <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
                <Text fontWeight={400} fontSize={12} color={theme.primary1}>
                  <Trans>View on Conflux Scan</Trans>
                </Text>
              </ExternalLink>
            )}
            <button
              className="btn btn-primary w-full flex py-2.5 justify-center"
              onClick={onDismiss}
              style={{ margin: '20px 0 0 0' }}
            >
              <Text>{inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}</Text>
            </button>
          </AutoColumn>
        </Section>
      </Wrapper>
      {currencyToAdd && library?.provider?.isMetaMask && (
        <div className="bg-linear-lime w-full py-3 mt-2 text-center">
          <button className="btn btn-secondary !border-none !bg-transparent tracking-normal text-sm" onClick={addToken}>
            {!success ? (
              <RowFixed>
                <Trans>
                  Add {currencyToAdd.symbol} to Metamask <StyledLogo src={MetaMaskLogo} />
                </Trans>
              </RowFixed>
            ) : (
              <RowFixed>
                <Trans>Added {currencyToAdd.symbol} </Trans>
                <CheckCircle size={'14px'} stroke={theme.green1} style={{ marginLeft: '6px' }} />
              </RowFixed>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
}: {
  title: ReactNode
  onDismiss: () => void
  topContent: () => ReactNode
  bottomContent?: () => ReactNode | undefined
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={16}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      {bottomContent && <BottomSection gap="12px">{bottomContent()}</BottomSection>}
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: ReactNode; onDismiss: () => void }) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Error</Trans>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <img src={FailIcon} alt="icon" width={224} height={224} />
          <Text
            fontWeight={500}
            fontSize={16}
            style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}
            className="text-ink-green"
          >
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <button className="btn btn-primary flex justify-center py-3" onClick={onDismiss}>
          <Trans>Dismiss</Trans>
        </button>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => ReactNode
  attemptingTxn: boolean
  pendingText: ReactNode
  currencyToAdd?: Currency | undefined
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()
  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}
