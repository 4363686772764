// import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import useBlockNumber from 'lib/hooks/useBlockNumber'
import * as hooks from 'state/multicall/hooks'
import { SkipFirst } from 'types/tuple'

export type { CallStateResult } from '@uniswap/redux-multicall' // re-export for convenience
export { NEVER_RELOAD } from '@uniswap/redux-multicall' // re-export for convenience

// Create wrappers for hooks so consumers don't need to get latest block themselves

type NonSkipFirstTwoParams<T extends (...args: any) => any> = SkipFirst<Parameters<T>, 0>

export function useMultipleContractSingleData(
  ...args: NonSkipFirstTwoParams<typeof hooks.useMultipleContractSingleData>
) {
  return hooks.useMultipleContractSingleData(...args)
}

export function useSingleCallResult(...args: NonSkipFirstTwoParams<typeof hooks.useSingleCallResult>) {
  return hooks.useSingleCallResult(...args)
}

export function useSingleContractMultipleData(
  ...args: NonSkipFirstTwoParams<typeof hooks.useSingleContractMultipleData>
) {
  return hooks.useSingleContractMultipleData(...args)
}

export function useSingleContractWithCallData(...args: any[]) {
  return []
}

// function useCallContext() {
//   const { chainId } = useActiveWeb3React()
//   const latestBlock = useBlockNumber()
//   return { chainId, latestBlock }
// }
