import { Trans } from '@lingui/macro'

export default function AnnouncementBanner() {
  return (
    <>
      <div className="flex w-full bg-medigreen h-[72px] justify-center">
        <div className="flex items-center">
          <div className="flex rounded-3xl bg-[#DACFCE] bg-opacity-20 py-2.5 px-7 ml-2 space-x-1">
            <span className="text-orange">
              <Trans>Bridge Your Assets</Trans>
            </span>
            <span className="text-white pl-2">
              <Trans>from Conflux Core here:</Trans>
            </span>
            <span className="">
              <a
                className="text-bluegreen underline"
                target="_blank"
                href="https://confluxhub.io/cross-space"
                rel="noreferrer"
              >
                confluxhub.io
              </a>
            </span>
            <span className="text-white pl-2">
              <Trans>from Ethereum and Others here:</Trans>
            </span>
            <div className="space-x-2">
              <span className="">
                <a
                  className="text-bluegreen underline"
                  target="_blank"
                  href="https://cbridge.celer.network/"
                  rel="noreferrer"
                >
                  cbridge.celer.network
                </a>
              </span>
              <span className="">
                <a className="text-bluegreen underline" target="_blank" href="https://meson.fi" rel="noreferrer">
                  meson.fi
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
