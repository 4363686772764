import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { InterfaceTrade, TradeState } from 'state/routing/types'

// import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'
import useAutoRouterSupported from './useAutoRouterSupported'
import { useBestV2Trade } from './useBestV2Trade'
import useDebounce from './useDebounce'
// import useIsWindowVisible from './useIsWindowVisible'

/**
 * Returns the best v2+v3 trade for a desired swap.
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useBestTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
} {
  const autoRouterSupported = useAutoRouterSupported()

  const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
    useMemo(() => [amountSpecified, otherCurrency], [amountSpecified, otherCurrency]),
    200
  )

  const isLoading = amountSpecified !== undefined && debouncedAmount === undefined

  // consider trade debouncing when inputs/outputs do not match
  const debouncing = false
  const useFallback = !autoRouterSupported || !debouncing

  const bestV2Trade = useBestV2Trade(
    tradeType,
    useFallback ? debouncedAmount : undefined,
    useFallback ? debouncedOtherCurrency : undefined
  )

  const trade = useMemo(() => {
    if (!bestV2Trade) {
      return {
        state: TradeState.INVALID,
        trade: undefined,
      }
    }
    return {
      state: TradeState.VALID,
      trade: new InterfaceTrade({
        v2Routes: [
          {
            routev2: bestV2Trade?.route,
            inputAmount: bestV2Trade?.inputAmount,
            outputAmount: bestV2Trade?.outputAmount,
          },
        ],
        v3Routes: [],
        tradeType,
      }),
    }
  }, [bestV2Trade, tradeType])

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...trade,
      ...(debouncing ? { state: TradeState.SYNCING } : {}),
      ...(isLoading ? { state: TradeState.LOADING } : {}),
    }),
    [debouncing, isLoading, trade]
  )
}
