import { Trans } from '@lingui/macro'
import QuestionHelper from 'components/QuestionHelper'
import { MouseoverTooltip } from 'components/Tooltip'
import { useIsGenesisNftHolder } from 'hooks/useIsGenesisNftHolder'
import { Link } from 'react-router-dom'
import { classNames } from 'utils'

import PriorityFlagIcon from '../../../../assets/images/priority-flag.png'
import { ReactComponent as TelegramIcon } from '../../../../assets/images/telegram.svg'
import { ReactComponent as TwitterIcon } from '../../../../assets/images/twitter.svg'
import { ReactComponent as WebIcon } from '../../../../assets/images/web.svg'
import { displayVal } from '../../../../utils'
import { AuctionStageEnum } from '../../utils'
import ProgressBar from '../ProgressBar'

const getDayAndHours = (hours: number) => {
  const d = parseInt((hours / 24).toString(), 10)
  const h = hours % 24
  return [d, h]
}

interface Props {
  tokenState: any
  idoState: any
  timespanState: any
  source: 'List' | 'Detail'
  account?: string | null | undefined
  onClickParticipate?: () => void
  onClickClaim?: () => void
}

const IdoToken = ({
  children,
  tokenState,
  idoState,
  timespanState,
  source,
  onClickClaim,
  onClickParticipate,
  account,
}: React.PropsWithChildren<Props>) => {
  const isGenesisNftHolder = useIsGenesisNftHolder()

  const requiredThreshold = Math.max(
    0,
    idoState.alignedVeTokenThreshold - (isGenesisNftHolder ? idoState.alignedNFTThreshold : 0)
  )

  return (
    <>
      <div className="flex justify-between pt-5 px-5 pb-2">
        <div className="flex items-center">
          <h5 className="text-base tracking-wider text-ink-green mr-2 font-medium flex items-center">
            <img src={tokenState?.icon ?? ''} alt="icon" className="w-[28px] h-[28px] rounded-full" />
            {source === 'List' ? (
              <Link to={`launchpad/${tokenState?.address}`} className="text-ink-green hover:underline">
                <span className="ml-[6px]">{idoState?.projectName ?? '-'}</span>
              </Link>
            ) : (
              <span className="ml-[6px]">{idoState?.projectName ?? '-'}</span>
            )}
          </h5>
          <p className="border-l-[3px] border-windy h-3">&nbsp;</p>
          <p className="text-xs text-lime px-1 mt-[2px]">{tokenState?.symbol ?? '-'}</p>
        </div>
      </div>

      <div
        id={source === 'Detail' ? 'detail-page' : undefined}
        className="bg-white p-3 sm:p-4 rounded-3xl rounded-tr-none rounded-bl-none relative"
      >
        <div className="border border-grass rounded-xl py-4 px-4 sm:px-6 flex justify-between space-y-8 sm:space-y-0 sm:space-x-14 flex-wrap">
          <div className="flex flex-col text-ink-green space-y-5 flex-1 min-w-[200px]">
            <div className="flex flex-col space-y-1">
              <div className="flex space-x-3 items-center">
                <span className="">
                  {idoState.alignedPriPrice === idoState.alignedPubPrice ? (
                    <>
                      {tokenState.symbol ?? '-'} Price: {displayVal(idoState.alignedPriPrice, 2)} CFX
                    </>
                  ) : (
                    <>
                      {tokenState.symbol ?? '-'} Price:{' '}
                      <MouseoverTooltip text={`Pre-Sale Price ${displayVal(idoState.alignedPriPrice, 2)} CFX`}>
                        <span>{displayVal(idoState.alignedPriPrice, 2)} CFX</span>
                      </MouseoverTooltip>{' '}
                      /{' '}
                      <MouseoverTooltip text={`Public Sale Price ${displayVal(idoState.alignedPubPrice, 2)} CFX`}>
                        <span>{displayVal(idoState.alignedPubPrice, 2)} CFX</span>
                      </MouseoverTooltip>
                    </>
                  )}
                </span>
                <div className="flex space-x-2 items-center">
                  <a href={tokenState.webUrl} target="_blank" className="w-[15px] h-[15px]" rel="noreferrer">
                    <WebIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                  </a>
                  <a href={tokenState.telegramUrl} target="_blank" className="w-[15px] h-[15px]" rel="noreferrer">
                    <TelegramIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                  </a>
                  <a href={tokenState.twitterUrl} target="_blank" className="w-[15px] h-[15px]" rel="noreferrer">
                    <TwitterIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                  </a>
                </div>
              </div>
              <span>
                Total offered: {displayVal(idoState?.alignedTotalAmount ?? 0, 4)} {tokenState?.symbol ?? '-'}
              </span>
            </div>
            <div className="flex flex-col space-y-0.5">
              <span className="">
                Progress (
                {idoState?.alignedTotalAmount
                  ? displayVal(
                      ((idoState.alignedTotalAmount - idoState.alignedAmt) / idoState.alignedTotalAmount) * 100,
                      2
                    )
                  : '-'}
                %)
              </span>
              <ProgressBar
                percentage={
                  idoState?.alignedTotalAmount
                    ? (idoState.alignedTotalAmount - idoState.alignedAmt) / idoState.alignedTotalAmount
                    : 0
                }
              />
              <span className="text-ink-green text-opacity-60 text-sm">
                {displayVal((idoState?.alignedTotalAmount ?? 0) - (idoState?.alignedAmt ?? 0), 4)}{' '}
                {tokenState?.symbol ?? '-'}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-1 space-y-4">
            <div className="flex space-x-1 items-center">
              {!idoState?.currentStage ||
              idoState?.currentStage === AuctionStageEnum.unknown ||
              idoState?.currentStage === AuctionStageEnum.upcoming ||
              idoState?.currentStage === AuctionStageEnum.privateSale ? (
                <>
                  <img src={PriorityFlagIcon} alt="icon" className="w-5 h-5" />
                  <div className="flex items-baseline space-x-1.5">
                    <span className="text-ink-green font-medium text-base sm:text-lg">Pre-Sale</span>
                    {idoState?.isInWhitelist ? (
                      <span className="text-bluegreen">Whitelist Users</span>
                    ) : (
                      <span
                        className={
                          !idoState.alignedVeTokenThreshold || idoState?.alignedVeBalance >= requiredThreshold
                            ? 'text-bluegreen'
                            : 'text-fail'
                        }
                      >
                        {/* {displayVal(requiredThreshold ?? 0, 2)} Score required */}
                        {isGenesisNftHolder && (
                          <span className="inline-flex translate-y-[-1px]">
                            <QuestionHelper
                              color={
                                !idoState.alignedVeTokenThreshold || idoState?.alignedVeBalance >= requiredThreshold
                                  ? ' rgb(77, 175, 158)'
                                  : 'rgb(217, 110, 129)'
                              }
                              text={`Genesis NFT Holder offers, originally {idoState.alignedVeTokenThreshold} score
                                  required.`}
                            />
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-baseline space-x-1.5">
                    <span className="text-ink-green font-medium text-base sm:text-lg">
                      Round: {idoState?.currentStage === AuctionStageEnum.publicSale ? 'Public Sale' : ''}
                      {idoState?.currentStage === AuctionStageEnum.ended ? 'Token Sale Ends' : ''}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center">
              <span className="text-ink-green mr-6">
                {!idoState?.currentStage ||
                idoState?.currentStage === AuctionStageEnum.unknown ||
                idoState?.currentStage === AuctionStageEnum.upcoming ? (
                  <Trans>Opening at</Trans>
                ) : idoState?.currentStage === AuctionStageEnum.publicSale && idoState.alignedAmt <= 0 ? (
                  <Trans>Claim token after</Trans>
                ) : (
                  <Trans>Time Remaining</Trans>
                )}
              </span>
              <span className="text-ink-green text-base sm:text-[22px] font-medium">
                {!idoState?.currentStage ||
                idoState.currentStage === AuctionStageEnum.ended ||
                idoState.currentStage === AuctionStageEnum.unknown ? (
                  '-'
                ) : (
                  <>
                    <span className="">{timespanState ? getDayAndHours(timespanState[0])[0] : '-'} d </span>
                    <span className="">{timespanState ? getDayAndHours(timespanState[0])[1] : '-'} : </span>
                    <span className="">{timespanState ? timespanState[1] : '-'} : </span>
                    <span className="">{timespanState ? timespanState[2] : '-'}</span>
                  </>
                )}
              </span>
            </div>
            <div className="flex items-center space-x-4">
              {!idoState?.currentStage || idoState?.currentStage === AuctionStageEnum.unknown ? (
                <span className="btn btn-secondary !font-normal tracking-wide !text-opacity-60 border !border-opacity-60 text-[15px] h-8 px-[14px]">
                  <Trans>On Sale Soon</Trans>
                </span>
              ) : (
                <>
                  {source === 'Detail' && (
                    <>
                      {idoState?.currentStage === AuctionStageEnum.ended && idoState.alignedClaimableAmt > 0 && (
                        <button
                          className="btn btn-default !font-normal tracking-wide text-[15px] h-8 bg-linear-orange-green px-[14px]"
                          onClick={onClickClaim}
                        >
                          <Trans>Claim</Trans>
                        </button>
                      )}
                      {(idoState?.currentStage === AuctionStageEnum.privateSale ||
                        (idoState?.currentStage === AuctionStageEnum.publicSale && idoState.alignedAmt > 0)) && (
                        <button
                          className={classNames(
                            'btn btn-default !font-normal tracking-wide text-[15px] h-8 px-[14px]',
                            !account ? 'btn-disabled opacity-30' : ''
                          )}
                          disabled={!account}
                          onClick={onClickParticipate}
                        >
                          <Trans>Participate</Trans>
                        </button>
                      )}
                    </>
                  )}
                  {source === 'List' && (
                    <Link to={`launchpad/${tokenState?.address}`}>
                      <button
                        className={classNames(
                          'btn btn-default !font-normal tracking-wide text-[15px] h-8 px-[14px]',
                          idoState?.currentStage === AuctionStageEnum.ended && idoState.alignedClaimableAmt > 0
                            ? 'bg-linear-orange-green'
                            : ''
                        )}
                      >
                        {idoState?.currentStage === AuctionStageEnum.ended && idoState.alignedClaimableAmt > 0 && (
                          <Trans>Claim</Trans>
                        )}
                        {idoState?.currentStage === AuctionStageEnum.ended && idoState.alignedClaimableAmt <= 0 && (
                          <Trans>View</Trans>
                        )}
                        {idoState?.currentStage === AuctionStageEnum.publicSale && idoState.alignedAmt <= 0 && (
                          <Trans>View</Trans>
                        )}
                        {(idoState?.currentStage === AuctionStageEnum.privateSale ||
                          (idoState?.currentStage === AuctionStageEnum.publicSale && idoState.alignedAmt > 0)) && (
                          <Trans>Participate</Trans>
                        )}
                        {idoState?.currentStage === AuctionStageEnum.upcoming && <Trans>View</Trans>}
                      </button>
                    </Link>
                  )}

                  <span
                    className={classNames(
                      'btn btn-secondary !font-normal tracking-wide border text-[15px] h-8 px-[14px]',
                      idoState?.currentStage === AuctionStageEnum.ended ? '!border-fail !text-fail' : '',
                      idoState?.currentStage === AuctionStageEnum.upcoming ? '!border-orange !text-orange' : '',
                      idoState?.currentStage === AuctionStageEnum.privateSale ||
                        idoState?.currentStage === AuctionStageEnum.publicSale
                        ? '!border-bluegreen !text-bluegreen'
                        : ''
                    )}
                  >
                    {idoState?.currentStage === AuctionStageEnum.upcoming ? 'Upcoming' : ''}
                    {idoState?.currentStage === AuctionStageEnum.ended ? 'Finished' : ''}
                    {idoState?.currentStage === AuctionStageEnum.privateSale ||
                    (idoState?.currentStage === AuctionStageEnum.publicSale && idoState.alignedAmt > 0)
                      ? 'Live'
                      : ''}
                    {idoState?.currentStage === AuctionStageEnum.publicSale && idoState.alignedAmt <= 0
                      ? 'Claim will open'
                      : ''}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    </>
  )
}

export default IdoToken
