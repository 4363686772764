import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'

// Use the replica environment variable for now, waiting for deployment of production
const url = 'https://graphql.swappi.io/subgraphs/name/swappi-dex/swappi-r2'
const blockUrl = 'https://graphql.swappi.io/subgraphs/name/blocklytics/ethereum-blocks'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: url,
  }),
  cache: new InMemoryCache(),
})

// create another client to avoid the cache mixed up issue
export const anotherClient = new ApolloClient({
  link: new HttpLink({
    uri: url,
  }),
  cache: new InMemoryCache(),
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: blockUrl,
  }),
  cache: new InMemoryCache(),
})
