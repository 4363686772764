// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContext, useRef, useState } from 'react'
import { X } from 'react-feather'
// import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { AUTO_ROUTER_SUPPORTED_CHAINS } from 'state/routing/clientSideSmartOrderRouter/constants'
import styled, { ThemeContext } from 'styled-components/macro'

import historyIcon from '../../assets/images/history.png'
import settingsIcon from '../../assets/images/settings.png'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { useClientSideRouter, useExpertModeManager } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};

  user-select: none;
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`

export default function SettingsTab({ placeholderSlippage }: { placeholderSlippage: Percent }) {
  const { chainId } = useActiveWeb3React()

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [clientSideRouter, setClientSideRouter] = useClientSideRouter()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)

  const toggleWalletModal = useWalletModalToggle()

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <div className="py-7">
            <div className="px-6 pb-4 flex justify-between">
              <Text fontWeight={600} fontSize={14}>
                <Trans>Expert Mode</Trans>
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </div>
            <div className="px-6 mt-2">
              <div className="text-sm text-ink-green bg-linear-yellow p-4 rounded-xl">
                <Trans>
                  {`Expert mode turns off the 'Confirm' transaction prompt, and allows high slippage trades that often
                  result in bad rates and lost funds.`}
                </Trans>
              </div>
              <div className="text-sm text-ink-green my-5">Only use this mode if you know what you’re doing.</div>
              <ButtonError
                error={true}
                className="btn btn-primary !rounded-lg !px-3 !py-2"
                onClick={() => {
                  const confirmWord = t`confirm`
                  if (window.prompt(t`Please type the word "${confirmWord}" to enable expert mode.`) === confirmWord) {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text id="confirm-expert-mode">
                  <Trans>Turn On Expert Mode</Trans>
                </Text>
              </ButtonError>
              <button
                className="btn btn-secondary !bg-[#F6F6F6] !border-ink-gray w-full flex justify-center mt-3 px-3 py-2"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>
      <div className="flex mx-2 sm:mx-4 sm:space-x-3 space-x-2 pb-3">
        <button className="w-5 h-5" onClick={toggleWalletModal}>
          <img src={historyIcon} alt="icon" />
        </button>
        <button className="w-5 h-5" onClick={toggle} id="open-settings-dialog-button">
          <img src={settingsIcon} alt="icon" />
        </button>
      </div>
      {open && (
        <MenuFlyout className="bg-white rounded-tl-3xl">
          <AutoColumn gap="16px" style={{ padding: '1rem' }}>
            <Text fontWeight={600} fontSize={14} className="flex w-[21px] h-[21px]">
              <img className="mr-2" src={settingsIcon} alt="icon" />
              <Trans>Settings</Trans>
            </Text>
            <TransactionSettings placeholderSlippage={placeholderSlippage} />
            {chainId && AUTO_ROUTER_SUPPORTED_CHAINS.includes(chainId) && (
              <RowBetween>
                <RowFixed>
                  <ThemedText.Black fontWeight={400} fontSize={14} color={theme.text2}>
                    <Trans>Auto Router API</Trans>
                  </ThemedText.Black>
                  <QuestionHelper text={<Trans>Use the Swappi API to get faster quotes.</Trans>} />
                </RowFixed>
                <Toggle
                  id="toggle-optimized-router-button"
                  isActive={!clientSideRouter}
                  toggle={() => {
                    // ReactGA.event({
                    //   category: 'Routing',
                    //   action: clientSideRouter ? 'enable routing API' : 'disable routing API',
                    // })
                    setClientSideRouter(!clientSideRouter)
                  }}
                />
              </RowBetween>
            )}
            <RowBetween>
              <RowFixed>
                <ThemedText.Black fontWeight={400} fontSize={14} color={theme.text2}>
                  <Trans>Expert Mode</Trans>
                </ThemedText.Black>
                <QuestionHelper
                  text={
                    <Trans>Allow high price impact trades and skip the confirm screen. Use at your own risk.</Trans>
                  }
                />
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode()
                        setShowConfirmation(false)
                      }
                    : () => {
                        toggle()
                        setShowConfirmation(true)
                      }
                }
              />
            </RowBetween>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
