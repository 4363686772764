import { Trans } from '@lingui/macro'
import { CHAIN_INFO } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { useContext } from 'react'
import { AlertCircle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function FailedNetworkSwitchPopup({ chainId }: { chainId: SupportedChainId }) {
  const chainInfo = CHAIN_INFO[chainId]
  const theme = useContext(ThemeContext)

  return (
    <RowNoFlex className="relative">
      <div className="bg-pinko flex w-16 items-center justify-center absolute inset-y-0">
        <div className="">
          <AlertCircle color={theme.red1} size={24} />
        </div>
      </div>
      <AutoColumn gap="8px" className="p-4 relative left-16 mr-20">
        <ThemedText.Body fontWeight={400} fontSize={12}>
          <Trans>
            Failed to switch networks. In order to use Swappi on {chainInfo.label}, you must change the network in your
            wallet.
          </Trans>
        </ThemedText.Body>
      </AutoColumn>
    </RowNoFlex>
  )
}
