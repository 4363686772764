import { formatUnits, parseUnits } from '@ethersproject/units'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import Spinner from 'components/Spinner'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import useTimer from 'hooks/useTimer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Info } from 'react-feather'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, cdnPrefix, classNames, getProviderOrSigner, getUnixTimeSecond } from 'utils'

import PPIToken from '../../abi/swappi-farm/PPIToken.sol/PPIToken.json'
import SwappiNFT from '../../abi/swappi-nft/SwappiNFT.json'
import AvocadoIcon from '../../assets/images/avocado.svg'
import GenesisIcon from '../../assets/images/genesis.png'
import GenesisElement1Icon from '../../assets/images/genesis-element1.png'
import GenesisElement2Icon from '../../assets/images/genesis-element2.png'
import GenesisElement3Icon from '../../assets/images/genesis-element3.png'
import SwappiPassIcon from '../../assets/images/swappi-pass.png'
import { PPI } from '../../constants/tokens'
import AppBody from '../AppBody'

const MINT_OPEN_TIME = '2022-05-24T22:00:00.000+08:00'
const MINT_CLOSE_TIME = '2022-05-24T22:20:00.000+08:00'
let timerId = null as any

export default function Index() {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const cSwappiNFT = useContract(EVM_SPACE[chainId]?.SwappiNFT, SwappiNFT.abi, false)
  const cPPIToken = useContract(EVM_SPACE[chainId]?.PPI, PPIToken.abi, false)

  const [hasEnoughAllowanceState, setHasEnoughAllowanceState] = useState(false)
  const [isMintEnabledState, setIsMintEnabledState] = useState(false)
  const [isProcessingState, setIsProcessingState] = useState(false)
  const [hasMintedState, setHasMintedState] = useState(false)
  const [hasPlaceholderLoadedState, setHasPlaceholderLoadedState] = useState(false)
  const [errMsgState, setErrMsgState] = useState('')
  const [mintPriceState, setMintPriceState] = useState<undefined | number>()
  const [totalAmountState, setTotalAmountState] = useState<undefined | number>()
  const [mintedAmountState, setMintedAmountState] = useState(0)

  const addTransaction = useTransactionAdder()
  const { startTimer, timespan: timespanState } = useTimer()

  const init = useCallback(async () => {
    try {
      if (chainId && cSwappiNFT && cPPIToken) {
        const p0 = Promise.resolve(false)
        const p1 = cSwappiNFT.callStatic._NFTPrice()
        const p2 = cSwappiNFT.callStatic._totalSupply()
        const p3 = cSwappiNFT.callStatic._tokenCounter()
        let promises = [p0, p1, p2, p3]

        if (account) {
          const p0 = cSwappiNFT.callStatic.hasMinted(account)
          const p1 = cPPIToken.callStatic.allowance(account, EVM_SPACE[chainId].SwappiNFT)

          promises = promises.concat([p0, p1])
        }

        const [isMintEnabled, mintPrice, totalAmount, mintedAmount, hasMinted, allowance] = await Promise.all(promises)

        let alignedMintPrice
        if (mintPrice && totalAmount) {
          alignedMintPrice = +formatUnits(mintPrice, PPI[chainId].decimals)
          setMintPriceState(alignedMintPrice)
          setTotalAmountState(+totalAmount)

          if (+mintedAmount > 0) {
            setMintedAmountState(+mintedAmount)
          }
        }

        if (isMintEnabled) {
          timerId = startTimer(MINT_CLOSE_TIME)
          setIsMintEnabledState(isMintEnabled)
        } else {
          timerId = startTimer(MINT_OPEN_TIME)
        }

        if (hasMinted) {
          setHasMintedState(hasMinted)
        } else if (isMintEnabled && allowance) {
          const alignedAllowance = +formatUnits(allowance, PPI[chainId].decimals)
          if (alignedMintPrice && alignedAllowance >= alignedMintPrice) {
            setHasEnoughAllowanceState(true)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [chainId, cSwappiNFT, cPPIToken, account, startTimer])

  useEffect(() => {
    ;(async () => {
      await init()
    })()

    return () => {
      clearInterval(timerId)
    }
  }, [init])

  const approve = useCallback(async () => {
    try {
      if (!chainId || !library || !cPPIToken || !mintPriceState || !account) return

      setIsProcessingState(true)

      const cPPITokenWriter = cPPIToken.connect(getProviderOrSigner(library, account))

      const methodName = 'approve'
      const methodParams = [EVM_SPACE[chainId].SwappiNFT, parseUnits(mintPriceState.toString(), PPI[chainId].decimals)]

      const gasLimit = calculateGasMargin(await cPPITokenWriter.estimateGas[methodName](...methodParams))
      const gasPrice = undefined

      const response = await cPPITokenWriter[methodName](...methodParams, {
        gasLimit,
        gasPrice,
      })
      addTransaction(response, { type: TransactionType.GENESIS_APPROVE })

      await response.wait()
    } catch (error) {
      setErrMsgState(t`You do not have enough CFX in your account to pay for transaction fees on network.`)
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [chainId, library, account, cPPIToken, addTransaction, mintPriceState])

  const mint = useCallback(async () => {
    try {
      if (!cSwappiNFT || !account || !library) return

      setIsProcessingState(true)

      const cSwappiNFTWriter = cSwappiNFT.connect(getProviderOrSigner(library, account))

      const methodName = 'mint'
      const gasLimit = calculateGasMargin(await cSwappiNFTWriter.estimateGas[methodName]())
      const gasPrice = undefined

      const response = await cSwappiNFTWriter[methodName]({
        gasLimit,
        gasPrice,
      })
      addTransaction(response, { type: TransactionType.MINT_GENESIS_NFT })

      await response.wait()
    } catch (error) {
      setErrMsgState(t`Mint failed, check your PPI balance`)
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [cSwappiNFT, addTransaction, account, library])

  const isMintClosed = useMemo(() => {
    const closeSecond = getUnixTimeSecond(MINT_CLOSE_TIME)
    const nowSecond = getUnixTimeSecond()
    const hasClosed = nowSecond > closeSecond

    return hasClosed && timespanState === null && !isMintEnabledState
  }, [timespanState, isMintEnabledState])

  return (
    <>
      <AppBody maxWidth="900px">
        <div className="main-container shadow-none mt-8 sm:mt-12">
          <div id="genesis-page" className="p-4 sm:py-8 sm:px-12">
            <div className="flex flex-col sm:flex-row sm:justify-between">
              <div className="text-medigreen">
                <div className="flex flex-col">
                  <div className="flex flex-col relative">
                    <div className="absolute bg-linear-ellipse-bright inset-0 z-[-1] sm:w-[630px] sm:h-[630px] translate-y-[-104px] sm:translate-y-[-208px] translate-x-[-20px] sm:translate-x-[-65px]"></div>
                    <div className="relative mb-5">
                      <div className="absolute top-[-70px] left-[-65px]">
                        <img src={GenesisElement1Icon} alt="icon" className="w-[108px]" />
                      </div>
                      <img src={GenesisIcon} alt="icon" className="w-[400px]" />
                    </div>
                    <h5 className="text-lg sm:text-xl font-medium mb-1">
                      <Trans>Join the Swappi Club with a Genesis Pass NFT</Trans>
                    </h5>
                    <div className="flex space-x-2 items-center">
                      <img src={AvocadoIcon} alt="icon" className="h-4" />
                      <span>
                        <Trans>
                          {mintedAmountState ? 'Minted/' : ''}Total amount:{' '}
                          {mintedAmountState ? `${mintedAmountState}/` : ''}
                          {totalAmountState || '-'} Passes
                        </Trans>
                      </span>
                    </div>
                    <div className="flex space-x-2 items-center">
                      <img src={AvocadoIcon} alt="icon" className="h-4" />
                      <span>
                        <Trans>Mint price: {mintPriceState || '-'} PPI/Pass</Trans>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex pr-[18px] mt-12 sm:mt-0 sm:pr-0">
                <div className="relative border-2 border-grass bg-linear-card-background backdrop-blur-xl rounded-[60px] rounded-tr-none rounded-bl-none top-[-18px] left-[18px]">
                  <div className="p-6 sm:p-8 pb-7 border-2 border-grass bg-[#F9FFFE] rounded-[60px] rounded-tr-none rounded-bl-none h-full w-full relative top-[18px] left-[-18px]">
                    <div className="relative rounded-3xl">
                      <div className="relative min-w-[240px] min-h-[240px] sm:w-[280px] sm:h-[280px]">
                        <img
                          src={SwappiPassIcon}
                          alt="icon"
                          onLoad={() => {
                            setHasPlaceholderLoadedState(true)
                          }}
                        />
                        <div
                          className={classNames(
                            hasPlaceholderLoadedState ? 'inline-flex' : 'hidden',
                            'absolute inset-0 rounded-3xl overflow-hidden'
                          )}
                        >
                          <video controls muted autoPlay loop poster={SwappiPassIcon}>
                            <source src={`${cdnPrefix}/swappi-nft.mp4`} type="video/mp4" />
                          </video>
                          <div className="absolute top-[26px] right-0 bg-orange text-white rounded-2xl rounded-r-none w-[134px] font-medium text-center py-1">
                            <Trans>Limited {totalAmountState || '-'}</Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col mt-3">
                      <div className="flex justify-between items-baseline border-b border-grass pb-1">
                        <span className="text-ink-green text-xl font-medium">
                          <Trans>Mint Price</Trans>
                        </span>
                        <span className="text-2xl font-medium flex space-x-1 justify-end">
                          <span className="text-ink-green mr-2">{mintPriceState || '-'}</span>
                          <span className="text-limo">PPI</span>
                        </span>
                      </div>
                      <div className="flex justify-between items-baseline mt-2">
                        {isMintClosed ? (
                          <span className="text-orange text-xl font-medium">
                            <Trans>Closed</Trans>
                          </span>
                        ) : (
                          <>
                            <span className="text-bluegreen text-xl font-medium">
                              <Trans>{isMintEnabledState ? 'Closing at' : 'Opening at'}</Trans>
                            </span>
                            <span className="text-xl font-medium flex space-x-2">
                              <span className="text-ink-green flex justify-end min-w-[35px]">
                                {timespanState ? timespanState[0] : '-'}h
                              </span>
                              <span className="text-ink-green flex justify-end min-w-[35px]">
                                {timespanState ? timespanState[1] : '-'}m
                              </span>
                              <span className="text-orange flex justify-end min-w-[35px]">
                                {timespanState ? timespanState[2] : '-'}s
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="absolute top-[-42px] right-[-55px]">
                    <img src={GenesisElement2Icon} alt="icon" className="w-[80px]" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-8 sm:-mt-24 justify-center sm:justify-start">
              <div className="w-[248px]">
                {hasMintedState ? (
                  <button className="btn btn-disabled !font-normal w-full rounded-full justify-center py-3 text-xl tracking-wider">
                    <Trans>Minted</Trans>
                  </button>
                ) : (
                  <>
                    {account && isMintEnabledState ? (
                      <>
                        {hasEnoughAllowanceState ? (
                          <>
                            <button
                              className={classNames(
                                isProcessingState ? 'cursor-not-allowed' : '',
                                'btn btn-primary w-full rounded-full justify-center py-3 font-normal text-xl tracking-wider'
                              )}
                              disabled={isProcessingState}
                              onClick={async () => {
                                if (!isProcessingState) {
                                  await mint()
                                  await init()
                                }
                              }}
                            >
                              {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
                              <Trans>Mint</Trans>
                            </button>
                          </>
                        ) : (
                          <button
                            className={classNames(
                              isProcessingState ? 'cursor-not-allowed' : '',
                              'btn btn-primary w-full rounded-full justify-center py-3 font-normal text-xl tracking-wider'
                            )}
                            disabled={isProcessingState}
                            onClick={async () => {
                              if (!isProcessingState) {
                                await approve()
                                await init()
                              }
                            }}
                          >
                            {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
                            <Trans>Approve</Trans>
                          </button>
                        )}
                      </>
                    ) : (
                      <button className="btn btn-disabled !font-normal w-full rounded-full justify-center py-3 text-xl tracking-wider">
                        <Trans>Mint</Trans>
                      </button>
                    )}
                  </>
                )}
              </div>
              {errMsgState && (
                <div className="flex space-x-2 items-center text-orange mt-2 sm:w-1/2">
                  <Info width={16} />
                  <span className="text-sm">{errMsgState}</span>
                </div>
              )}
            </div>

            <div className="flex justify-between mt-16 sm:mt-24 items-end">
              <div className="flex flex-col text-ink-green space-y-5">
                <h5 className="text-xl font-bold">
                  <Trans>Description</Trans>
                </h5>

                <div className="flex flex-col text-sm opacity-80">
                  <span className="font-medium">
                    <Trans>Benefits:</Trans>
                  </span>
                  <span>
                    <Trans>Surprising SWAG airdrops.</Trans>
                  </span>
                  <span>
                    <Trans>Special boost in the Swappi IDO launchpad.</Trans>
                  </span>
                  <span>
                    <Trans>Priority whitelist, Airdrops from other Conflux eSpace projects, free NFT mint.</Trans>
                  </span>
                  <span>
                    <Trans>Fast track, priority whitelist, and other unique perks in the Swappi NFT Marketplace.</Trans>
                  </span>
                </div>
              </div>

              <div className="mr-[-68px]">
                <img src={GenesisElement3Icon} alt="icon" className="w-[140px]" />
              </div>
            </div>
          </div>
        </div>
      </AppBody>
    </>
  )
}
