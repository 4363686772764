import { Currency } from '@uniswap/sdk-core'
import { ESPACE_MAINNET_CHAIN_ID, ESPACE_TESTNET_CHAIN_ID, SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { isAddress, isTokenAddressOnChain, urlPrefix } from 'utils'

import CFXLogo from '../../assets/images/cfx.svg'

function importAll(r: any) {
  const images = {} as any
  r.keys().forEach((item: any) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

const logoImagesMainnet = importAll(require.context('../../assets/images/1030', false, /\.(png|jpe?g|svg)$/))
const logoImagesTestnet = importAll(require.context('../../assets/images/71', false, /\.(png|jpe?g|svg)$/))

function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.ESPACE): string {
  return CFXLogo
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.ESPACE): string | void {
  const networksWithUrls = [SupportedChainId.ESPACE]

  if (networksWithUrls.includes(chainId)) {
    if (isTokenAddressOnChain(address, chainId)) {
      const parsedAddress = isAddress(address)
      return `${urlPrefix}/images/${chainId}/${parsedAddress}`
    }
  }
}

export default function useCurrencyLogoURIs(currency?: Currency | null, isForWallet = false): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken) {
        if (!isForWallet) {
          if (currency.chainId === ESPACE_MAINNET_CHAIN_ID) {
            const imageAddr = logoImagesMainnet[`${currency.address}.png`]
            if (imageAddr) logoURIs.push(imageAddr.default)
          } else if (currency.chainId === ESPACE_TESTNET_CHAIN_ID) {
            const imageAddr = logoImagesTestnet[`${currency.address}.png`]
            if (imageAddr) logoURIs.push(imageAddr.default)
          }
        } else {
          const logoURI = getTokenLogoURI(currency.address, currency.chainId)
          if (logoURI) {
            logoURIs.push(`${logoURI}.png`)
            logoURIs.push(`${logoURI}.svg`)
          }
        }
      }
    }
    return logoURIs
  }, [currency, locations, isForWallet])
}
