import { isESpaceMainnet } from 'constants/chains'
import { useMemo } from 'react'
import useSWR from 'swr'

import type { Token } from '../utils'
import { staticTokens } from '../utils'

const tokensEndpoint = `https://raw.githubusercontent.com/swappidex/swappi-core/${
  isESpaceMainnet() ? 'main' : 'dev'
}/config/tokens/launchpad/index.json`
const iconBaseUrl = `${tokensEndpoint.split('tokens')[0]}tokens/icons/`

const fetcher = () => {
  // process.env.NODE_ENV === 'development' or false
  if (false) {
    return staticTokens
  } else {
    return fetch(tokensEndpoint).then((res) => res.json())
  }
}

export const normalizeTokens = (data: any) => {
  const tokens: Token[] = data
    ? data.map((i: Token) => ({
        ...i,
        icon: i.icon || `${iconBaseUrl}${i.symbol.toUpperCase()}.png`,
      }))
    : []

  return tokens
}

export const useTokens = () => {
  const { data, error } = useSWR(tokensEndpoint, fetcher, {
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
    revalidateIfStale: true,
    dedupingInterval: 60000,
  })

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useToken = (address: string) => {
  const { data, isError } = useTokens()

  return useMemo(
    () => ({
      data: data ? normalizeTokens(data).find((i: any) => i.address.toLowerCase() === address.toLowerCase()) : null,
      isLoading: !isError && !data,
      isError,
    }),
    [data, isError, address]
  )
}
