import { Trans } from '@lingui/macro'
import Polling from 'components/Header/Polling'
import { usePPIPrice } from 'hooks/usePairPrice'
import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import AuditIcon from '../../assets/images/certik.png'
import { ReactComponent as GithubIcon } from '../../assets/images/github.svg'
import { ReactComponent as MediumIcon } from '../../assets/images/medium.svg'
import ppiIcon from '../../assets/images/ppi.png'
import { ReactComponent as RedditIcon } from '../../assets/images/reddit.svg'
import { ReactComponent as TelegramIcon } from '../../assets/images/telegram.svg'
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter.svg'
import { isMobile } from '../../utils/userAgent'

const WrapppedFooter = forwardRef(function Footer(props, ref: any) {
  const PPIPrice = usePPIPrice()

  return (
    <div ref={ref} className="w-full mt-[120px] sm:mt-20 mb-4 z-10">
      <div className="flex w-full justify-between px-2 sm:px-4 lg:px-20 py-3.5 sm:mb-0 mb-16">
        <div className="flex w-full flex-col text-[13px] space-y-4">
          <div className="flex justify-between sm:items-center items-end">
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-9">
              <a
                href="https://docs.swappi.io"
                target="_blank"
                className="text-medigreen hover:text-bluegreen"
                rel="noreferrer"
              >
                <Trans>Documentation</Trans>
              </a>
              <a
                href="https://forms.gle/xjpxQhrqPFnG2ybSA"
                target="_blank"
                className="text-medigreen hover:text-bluegreen"
                rel="noreferrer"
              >
                <Trans>Token Listing</Trans>
              </a>
              <a
                href="https://forms.gle/xjpxQhrqPFnG2ybSA"
                target="_blank"
                className="text-medigreen hover:text-bluegreen"
                rel="noreferrer"
              >
                <Trans>Apply for Farm</Trans>
              </a>
              <a
                href="https://immunefi.com/bounty/swappi"
                target="_blank"
                className="text-medigreen hover:text-bluegreen"
                rel="noreferrer"
              >
                <Trans>Bug Bounty</Trans>
              </a>
              {!isMobile && (
                <>
                  <a
                    href="https://info.swappi.io"
                    target="_blank"
                    className="text-medigreen hover:text-bluegreen"
                    rel="noreferrer"
                  >
                    <Trans>Analytics</Trans>
                  </a>
                  <NavLink id={`genesis-nav-link`} to={'/genesis'} className="text-medigreen hover:text-bluegreen">
                    <Trans>Genesis NFT</Trans>
                  </NavLink>
                </>
              )}
            </div>
            <div className="flex flex-col items-end space-y-0.5 -mb-8 sm:mb-0 z-10">
              {isMobile && (
                <>
                  <div className="flex flex-col space-y-1.5 items-end mb-2">
                    <NavLink id={`genesis-nav-link`} to={'/genesis'} className="text-medigreen hover:text-bluegreen">
                      <Trans>Genesis NFT</Trans>
                    </NavLink>
                    <div className="flex items-center">
                      <a
                        href="https://info.swappi.io"
                        target="_blank"
                        className="text-medigreen hover:text-bluegreen"
                        rel="noreferrer"
                      >
                        <Trans>Analytics</Trans>
                      </a>
                    </div>
                    <span>&nbsp;</span>
                    <div className="flex items-center min-w-[85px]">
                      <img src={ppiIcon} alt="icon" className="w-[26px] h-[26px] rounded-full" />
                      <span className="ml-2 font-medium text-ink-green text-[15px] whitespace-nowrap">
                        {PPIPrice ? `$ ${parseFloat(PPIPrice.toFixed(3))}` : '-'}
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="flex space-x-3 sm:space-x-6">
                <a href="https://github.com/swappidex" target="_blank" className="w-[18px] h-[18px]" rel="noreferrer">
                  <GithubIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                </a>
                <a href="https://t.me/Swappi_DEX" target="_blank" className="w-[18px] h-[18px]" rel="noreferrer">
                  <TelegramIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                </a>
                <a href="https://twitter.com/SwappiDEX" target="_blank" className="w-[18px] h-[18px]" rel="noreferrer">
                  <TwitterIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                </a>
                <a
                  href="https://www.reddit.com/r/Swappi/"
                  target="_blank"
                  className="w-[18px] h-[18px]"
                  rel="noreferrer"
                >
                  <RedditIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                </a>
                <a href="https://medium.com/@swappidex" target="_blank" className="w-[18px] h-[18px]" rel="noreferrer">
                  <MediumIcon className="w-full h-full fill-current text-medigreen hover:text-bluegreen" />
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-medigreen space-x-2">
              <span>
                <Trans>Audit by</Trans>
              </span>
              <span className="w-20">
                <a href="https://www.certik.com/projects/swappi-finance" target="_blank" rel="noreferrer">
                  <img src={AuditIcon} alt="icon" className="w-full h-full" />
                </a>
              </span>
            </div>
            <div className="relative">
              <Polling />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default WrapppedFooter
