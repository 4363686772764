import { formatUnits } from '@ethersproject/units'
import { Disclosure } from '@headlessui/react'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import PlaceholderContent from 'components/Spinner/PlaceholderContent'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { MAX_INT } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { getPairPrice } from 'hooks/usePairPrice'
import useTimer from 'hooks/useTimer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChevronDown, ChevronsDown } from 'react-feather'
import { classNames, currencyMapping, displayVal, getTimeString } from 'utils'

import Multicall from '../../abi/Multicall.json'
import SwappiFactory from '../../abi/swappi-core/SwappiFactory.json'
import SwappiLottery from '../../abi/swappi-lottery/SwappiLottery.sol/SwappiLottery.json'
import ChevronLeftIcon from '../../assets/images/chevron-left.png'
import ChevronRightIcon from '../../assets/images/chevron-right.png'
import ChevronRightEndIcon from '../../assets/images/chevron-right-end.png'
import CrownIcon from '../../assets/images/crown.png'
import Die1Icon from '../../assets/images/die-1.png'
import Die2Icon from '../../assets/images/die-2.png'
import Die3Icon from '../../assets/images/die-3.png'
import Die4Icon from '../../assets/images/die-4.png'
import Die5Icon from '../../assets/images/die-5.png'
import Die6Icon from '../../assets/images/die-6.png'
import GreenTicketIcon from '../../assets/images/green-ticket.png'
import GuidanceIcon from '../../assets/images/guidance.png'
import TicketIcon from '../../assets/images/ticket.png'
import { PPI } from '../../constants/tokens'
import CheckModal from './CheckModal'
import ClaimModal from './ClaimModal'
import DrawModal from './DrawModal'
import GetModal from './GetModal'
import { ballColorClasses, getUserTickets, transformTicketNumber } from './utils'

let timerId = null as any

const getRewardBrackets = (
  rewardsBreakdown: any,
  ppiPrice: number,
  ppiAmount: number,
  countWinnersPerBracket = null,
  ppiPerBracket = null,
  chainId = null as any
) => {
  const rewardBrackets = [] as any

  let total = 0
  rewardsBreakdown.forEach((i: any) => {
    total += +i
  })

  rewardsBreakdown.forEach((i: any, idx: number) => {
    const ratio = +i / total

    const amount = ppiAmount * ratio
    const obj = {
      name: idx === rewardsBreakdown.length - 1 ? t`Match All ${idx + 1}` : t`Match First ${idx + 1}`,
      amount,
      usdValue: amount * ppiPrice,
    } as any
    if (countWinnersPerBracket && ppiPerBracket && chainId) {
      obj.count = +countWinnersPerBracket[idx]
      obj.each = +formatUnits(ppiPerBracket[idx], PPI[chainId].decimals)
    }

    rewardBrackets.push(obj)
  })

  return rewardBrackets
}

const getTotalRollOver = (rewardBrackets: any) => {
  return rewardBrackets.reduce((memo: number, current: any) => {
    return memo + (current.count === 0 ? current.usdValue : 0)
  }, 0)
}

export default function Index() {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const [isGetModalOpen, setIsGetModalOpen] = useState(false)
  const [isDrawModalOpen, setIsDrawModalOpen] = useState(false)
  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false)
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false)
  const [lotteryState, setLotteryState] = useState<any>({})
  const [lotteryUserInfoState, setLotteryUserInfoState] = useState<any>({})
  const [finishedLotteryState, setFinishedLotteryState] = useState<any>({})
  const [finishedLotteryUserInfoState, setFinishedLotteryUserInfoState] = useState<any>({})
  const [isLoading, setIsLoading] = useState(true)

  const cSwappiLottery = useContract(EVM_SPACE[chainId]?.SwappiLottery, SwappiLottery.abi, false)

  const { startTimer, timespan: timespanState } = useTimer()

  const init = useCallback(async () => {
    try {
      if (!chainId || !library || !cSwappiLottery) return

      const currentLotteryId = +(await cSwappiLottery.callStatic.viewCurrentLotteryId())

      const promises = [
        cSwappiLottery.callStatic.viewLottery(currentLotteryId),
        getPairPrice({
          tokenA: currencyMapping({ address: EVM_SPACE[chainId].FaucetUSDT }, chainId),
          tokenB: PPI[SupportedChainId.ESPACE],
          swappiFactoryInfo: {
            address: EVM_SPACE[chainId].SwappiFactory,
            abi: SwappiFactory.abi,
          },
          multicallInfo: {
            address: EVM_SPACE[chainId].Multicall,
            abi: Multicall.abi,
          },
          library,
          chainId,
        }),
        cSwappiLottery.callStatic.maxNumberTicketsPerBuyOrClaim(),
        account
          ? cSwappiLottery.callStatic.viewUserInfoForLotteryId(account, currentLotteryId, 0, MAX_INT)
          : Promise.resolve(null),
      ]
      const [lotteryResult, ppiPrice, maxNumberTicketsPerBuyOrClaimResult, userInfoResult] = await Promise.all(promises)

      const amountCollectedInPPI = +formatUnits(lotteryResult.amountCollectedInPPI, PPI[chainId].decimals)
      const prizePot = amountCollectedInPPI * ppiPrice
      const endTime = getTimeString(+lotteryResult.endTime * 1000)
      const treasuryFeeRatio = +lotteryResult.treasuryFee / 100 / 100
      const burnAmount = treasuryFeeRatio * amountCollectedInPPI
      const burn = {
        amount: burnAmount,
        usdValue: burnAmount * ppiPrice,
      }

      const rewardBrackets = getRewardBrackets(
        lotteryResult.rewardsBreakdown,
        ppiPrice,
        amountCollectedInPPI - burnAmount
      )
      timerId = startTimer(endTime)

      const priceTicketInPPI = +formatUnits(lotteryResult.priceTicketInPPI, PPI[chainId].decimals)
      const discountDivisor = +lotteryResult.discountDivisor

      setLotteryState({
        currentLotteryId,
        status: +lotteryResult.status,
        prizePot,
        burn,
        rewardBrackets,
        priceTicketInPPI,
        discountDivisor,
        maxNumberTicketsPerBuyOrClaim: +maxNumberTicketsPerBuyOrClaimResult,
        ppiPrice,
      })

      if (userInfoResult) {
        const userTickets = getUserTickets(userInfoResult)

        setLotteryUserInfoState({
          userTickets,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [chainId, account, library, cSwappiLottery, startTimer])

  const initFinished = useCallback(
    async ({ id, ppiPrice }) => {
      try {
        if (!chainId || !library || !cSwappiLottery) return

        setIsLoading(true)

        const promises = [
          cSwappiLottery.callStatic.viewLottery(id),
          account ? cSwappiLottery.callStatic.viewUserInfoForLotteryId(account, id, 0, MAX_INT) : Promise.resolve(null),
        ]

        const [finishedLotteryResult, finishedLotteryUserInfoResult] = await Promise.all(promises)
        const amountCollectedInPPI = +formatUnits(finishedLotteryResult.amountCollectedInPPI, PPI[chainId].decimals)
        const prizePot = amountCollectedInPPI * ppiPrice
        const totalTickets = +finishedLotteryResult.firstTicketIdNextLottery - +finishedLotteryResult.firstTicketId
        const treasuryFeeRatio = +finishedLotteryResult.treasuryFee / 100 / 100
        const burnAmount = treasuryFeeRatio * amountCollectedInPPI
        const burn = {
          amount: burnAmount,
          usdValue: burnAmount * ppiPrice,
        }

        const rewardBrackets = getRewardBrackets(
          finishedLotteryResult.rewardsBreakdown,
          ppiPrice,
          amountCollectedInPPI - burnAmount,
          finishedLotteryResult.countWinnersPerBracket,
          finishedLotteryResult.ppiPerBracket,
          chainId
        )

        const totalRollOver = getTotalRollOver(rewardBrackets)

        const finalNumber = transformTicketNumber(+finishedLotteryResult.finalNumber)

        setFinishedLotteryState({
          id,
          endTime: +finishedLotteryResult.endTime,
          finalNumber,
          prizePot,
          amountCollectedInPPI,
          totalTickets,
          totalRollOver,
          rewardBrackets,
          burn,
          ppiPrice,
        })

        if (finishedLotteryUserInfoResult) {
          const userTickets = getUserTickets(finishedLotteryUserInfoResult, finalNumber)
          setFinishedLotteryUserInfoState({
            userTickets,
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    },
    [chainId, account, library, cSwappiLottery]
  )

  useEffect(() => {
    ;(async () => {
      await init()
    })()

    return () => {
      clearInterval(timerId)
    }
  }, [init])

  useEffect(() => {
    ;(async () => {
      const { currentLotteryId, status, ppiPrice } = lotteryState
      if (!currentLotteryId || status <= 0) return

      const id = status === 1 ? lotteryState.currentLotteryId - 1 : lotteryState.currentLotteryId
      await initFinished({ id, ppiPrice })
    })()
  }, [initFinished, lotteryState])

  const isWonPrize = useMemo(() => {
    if (!finishedLotteryUserInfoState || !finishedLotteryUserInfoState.userTickets) return false

    return finishedLotteryUserInfoState.userTickets.filter((i: any) => i.matches > 0).length > 0
  }, [finishedLotteryUserInfoState])

  const latestFinishedId = useMemo(() => {
    return lotteryState.status === 1 ? lotteryState.currentLotteryId - 1 : lotteryState.currentLotteryId
  }, [lotteryState])

  return (
    <>
      <div className="w-screen">
        <div id="lottery-page" className="relative top-[-45px] overflow-hidden">
          <div className="bg-[#FCDF79] w-full">
            <div className="max-w-[900px] mx-auto px-6 sm:px-4 py-16">
              <div className="">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <div className="flex">
                        <div className="hidden sm:block">
                          <div className="relative top-[10px]">
                            <div className="absolute top-[-14px] left-[-150px] animate-floating2">
                              <img
                                src={Die1Icon}
                                alt="icon"
                                className="w-[56px] h-[56px] animate-spin-fast animation-delay-long"
                              />
                              <img
                                src={Die2Icon}
                                alt="icon"
                                className="w-[56px] h-[56px] relative left-[78px] top-[8px] animate-spin-fast"
                              />
                            </div>
                            <img src={GreenTicketIcon} alt="icon" className="w-[343px] animate-wiggle" />
                            <div className="absolute bottom-[-6px] right-[-576px] animate-floating1">
                              <img
                                src={Die6Icon}
                                alt="icon"
                                className="w-[56px] h-[56px] relative top-[-36px] right-[-60px] z-[2]"
                              />
                              <img
                                src={Die3Icon}
                                alt="icon"
                                className="w-[56px] h-[56px] relative top-[-50px] right-[-76px] z-[1]"
                              />
                              <img
                                src={Die4Icon}
                                alt="icon"
                                className="w-[56px] h-[56px] relative top-[-66px] right-[-48px]"
                              />
                              <img
                                src={Die5Icon}
                                alt="icon"
                                className="w-[56px] h-[56px] animate-spin-fast animation-delay-short"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col ml-0 sm:ml-[70px] relative">
                          <div className="text-orange text-[34px] font-medium tracking-[4px]">
                            <span>
                              <Trans>Prize Pot</Trans>
                            </span>
                          </div>
                          <div className="text-medigreen text-[46px] sm:text-[56px] font-bold lottery-stroke-text tracking-[5px] sm:tracking-[8px] space-x-2 sm:space-x-4 flex items-center">
                            <span className="lottery-stroke-text2 text-[50px] sm:text-[60px]">$</span>
                            <span className="lottery-stroke-text">{displayVal(lotteryState.prizePot, 2)}</span>
                          </div>
                          <div className="flex text-xl font-medium space-x-3">
                            <span className="text-orange">
                              <Trans>Next Draw</Trans>
                            </span>
                            <span className="text-medigreen space-x-3">
                              <span>{timespanState ? timespanState[0] : '-'}h</span>
                              <span>{timespanState ? timespanState[1] : '-'}m</span>
                              <span>{timespanState ? timespanState[2] : '-'}s</span>
                            </span>
                          </div>
                          <div className="mt-2">
                            <Disclosure.Button className="text-xl text-bluegreen font-normal flex space-x-1 items-center">
                              <span>
                                <Trans>More</Trans>
                              </span>
                              <ChevronsDown className={`${open ? 'transform rotate-180' : ''} w-5 h-5`} />
                            </Disclosure.Button>
                          </div>
                          <div
                            className={classNames(
                              open
                                ? 'ease-in translate-x-0 sm:translate-x-[-75px] translate-y-[615px] sm:translate-y-[415px]'
                                : 'ease-out translate-x-0 translate-y-0',
                              'transform origin-top-left transition-transform duration-200 mt-6'
                            )}
                          >
                            <button
                              className={classNames(
                                account && lotteryState.status === 1 ? '' : 'btn-disabled opacity-30',
                                'bg-grass py-3 px-8 border-[5px] border-ink-green rounded-2xl dice-shadow text-2xl text-ink-green font-medium focus-visible:outline-none'
                              )}
                              onClick={() => {
                                if (account && lotteryState.status === 1) setIsGetModalOpen(true)
                              }}
                            >
                              {isNaN(lotteryState.status) && <Trans>Loading...</Trans>}
                              {lotteryState.status === 1 && <Trans>Get Tickets</Trans>}
                              {lotteryState.status === 0 && <Trans>On Sale Soon</Trans>}
                              {lotteryState.status === 2 && <Trans>Closed</Trans>}
                              {lotteryState.status === 3 && <Trans>Claimable</Trans>}
                            </button>
                          </div>
                        </div>
                      </div>

                      <Disclosure.Panel className="mt-[-60px] sm:mt-[-10px] mb-[110px] bg-white bg-opacity-40 box py-6 px-9">
                        <div className="flex justify-center">
                          <span className="text-orange text-sm font-normal tracking-wide">
                            <Trans>
                              Match the winning number in the same order to share prizes. Current prizes up for grabs:
                            </Trans>
                          </span>
                        </div>
                        <div className="flex mt-4 break-words flex-wrap gap-y-6 gap-x-4 sm:gap-x-12">
                          {lotteryState.rewardBrackets ? (
                            lotteryState.rewardBrackets.map((i: any, idx: number) => (
                              <div
                                key={idx}
                                className="flex p-3 pr-[18px] flex-col bg-[#FFF6D8] rounded-[10px] dice-shadow w-[110px] sm:w-[120px]"
                              >
                                <span className="text-ink-green text-opacity-60 text-xs sm:text-sm mb-1.5 tracking-[0.02em]">
                                  {i.name}
                                </span>
                                <span className="text-bluegreen text-base font-medium">
                                  {displayVal(i.amount, 0)} PPI
                                </span>
                                <span className="text-ink-green text-[13px] font-medium">
                                  ~${displayVal(i.usdValue, 2)}
                                </span>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                          <div className="flex p-3 pr-[18px] flex-col bg-[#FFF6D8] rounded-[10px] dice-shadow w-[120px]">
                            <span className="text-fail text-sm mb-1.5 tracking-wide">Burn</span>
                            {/* <span className="text-bluegreen text-base font-medium">
                              {displayVal(lotteryState.burn?.amount, 0)} PPI
                            </span> */}
                            {/* <span className="text-ink-green text-[13px] lead font-medium">
                              ~${displayVal(lotteryState.burn?.usdValue, 2)}
                            </span> */}
                            <span className="text-ink-green text-[13px] lead font-medium">Coming Soon</span>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {((lotteryUserInfoState.userTickets && lotteryUserInfoState.userTickets.length > 0) || isWonPrize) && (
            <div className="bg-[#FFEA9F] w-full">
              <div className="max-w-[900px] mx-auto px-4 py-11">
                {lotteryUserInfoState.userTickets && lotteryUserInfoState.userTickets.length > 0 && (
                  <div className="flex justify-between sm:justify-center space-x-3 items-center">
                    <div className="flex items-center space-x-4">
                      <img src={TicketIcon} alt="icon" className="w-14" />
                      <span className="text-bluegreen font-medium text-base sm:text-xl tracking-widest">
                        {lotteryUserInfoState.userTickets.length === 1 ? (
                          <Trans>You have 1 ticket this round</Trans>
                        ) : (
                          <Trans>You have {lotteryUserInfoState.userTickets.length} tickets this round</Trans>
                        )}
                      </span>
                    </div>
                    <button
                      className="text-orange underline font-medium text-base sm:text-xl tracking-widest"
                      onClick={() => {
                        setIsCheckModalOpen(true)
                      }}
                    >
                      <Trans>Check it out</Trans>
                    </button>
                  </div>
                )}
                {isWonPrize && (
                  <div className="flex justify-between sm:justify-center space-x-3 items-center">
                    <div className="flex items-center space-x-4">
                      <img src={CrownIcon} alt="icon" className="w-14" />
                      <span className="text-bluegreen font-medium text-base sm:text-xl tracking-widest">
                        <Trans>You won!</Trans>
                      </span>
                    </div>
                    <button
                      className="text-orange underline font-medium text-base sm:text-xl tracking-widest"
                      onClick={() => {
                        setIsDrawModalOpen(true)
                      }}
                    >
                      <Trans>Check it out</Trans>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {lotteryState.currentLotteryId > 0 && (
            <div className="bg-[#F8BD9D] w-full">
              <div className="max-w-[900px] mx-auto px-4 py-16">
                <div className="text-center">
                  <span className="text-medigreen tracking-[4px] text-[32px] font-medium">
                    <Trans>Prize Pool History</Trans>
                  </span>
                </div>
                <div className="mt-8 box bg-opacity-40 bg-white">
                  <div className="uppercase justify-center space-x-20 pt-6 hidden">
                    <span className="relative pb-2">
                      <span className="text-base tracking-widest font-medium text-light-black">
                        <Trans>All History</Trans>
                      </span>
                      <div className="h-[3px] w-full bg-orange rounded-sm absolute bottom-0"></div>
                    </span>
                  </div>
                  <div className="box bg-white bg-opacity-60 p-5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          {isLoading ? (
                            <>
                              <PlaceholderContent className="p-3" itemClassName="bg-linear-lottery-warm" />
                            </>
                          ) : (
                            <>
                              <div className="flex flex-wrap justify-between sm:pl-6 items-center pb-2">
                                <div className="flex flex-col">
                                  {false ? (
                                    <></>
                                  ) : (
                                    <>
                                      <div className="flex text-base font-medium space-x-2">
                                        <span className="text-light-black tracking-[1.5px]">
                                          <Trans>Round</Trans>
                                        </span>
                                        <span className="text-orange tracking-widest">{finishedLotteryState.id}</span>
                                      </div>
                                      <span className="text-ink-green text-[13px] lead font-normal text-opacity-60 flex space-x-1">
                                        <span>
                                          {finishedLotteryState.endTime
                                            ? t`Drawn ${new Date(finishedLotteryState.endTime * 1000).toLocaleString()}`
                                            : '-'}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div className="flex space-x-2 items-center mt-3 mb-2 sm:my-0 justify-between w-full sm:w-auto">
                                  {finishedLotteryUserInfoState.userTickets &&
                                    finishedLotteryUserInfoState.userTickets.length > 0 && (
                                      <button
                                        className="mr-4"
                                        onClick={() => {
                                          setIsDrawModalOpen(true)
                                        }}
                                      >
                                        <span className="text-orange underline font-medium text-sm tracking-widest uppercase">
                                          <Trans>Checkout</Trans>
                                        </span>
                                      </button>
                                    )}

                                  <div className="flex space-x-2 items-center">
                                    <button
                                      className={classNames(
                                        finishedLotteryState.id - 1 >= 1 ? '' : 'btn-disabled opacity-30',
                                        'rounded-full w-6 h-6 bg-white bg-opacity-60 items-center justify-center flex'
                                      )}
                                      onClick={async () => {
                                        if (finishedLotteryState.id - 1 >= 1) {
                                          await initFinished({
                                            id: finishedLotteryState.id - 1,
                                            ppiPrice: finishedLotteryState.ppiPrice,
                                          })
                                        }
                                      }}
                                    >
                                      <img src={ChevronLeftIcon} alt="icon" width={'95%'} />
                                    </button>
                                    <button
                                      className={classNames(
                                        finishedLotteryState.id + 1 <= latestFinishedId
                                          ? ''
                                          : 'btn-disabled opacity-30',
                                        'rounded-full w-6 h-6 bg-white bg-opacity-60 items-center justify-center flex'
                                      )}
                                      onClick={async () => {
                                        if (finishedLotteryState.id + 1 <= latestFinishedId) {
                                          await initFinished({
                                            id: finishedLotteryState.id + 1,
                                            ppiPrice: finishedLotteryState.ppiPrice,
                                          })
                                        }
                                      }}
                                    >
                                      <img src={ChevronRightIcon} alt="icon" width={'95%'} />
                                    </button>
                                    <button
                                      className={classNames(
                                        finishedLotteryState.id !== latestFinishedId ? '' : 'btn-disabled opacity-30',
                                        'rounded-full w-6 h-6 bg-white bg-opacity-60 items-center justify-center flex'
                                      )}
                                      onClick={async () => {
                                        if (finishedLotteryState.id !== latestFinishedId) {
                                          await initFinished({
                                            id: latestFinishedId,
                                            ppiPrice: finishedLotteryState.ppiPrice,
                                          })
                                        }
                                      }}
                                    >
                                      <img src={ChevronRightEndIcon} alt="icon" width={'95%'} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {!!finishedLotteryState.finalNumber && (
                                <>
                                  <div className="bg-linear-lottery-warm rounded-xl sm:p-6 p-4">
                                    <div className="flex justify-between items-center flex-col sm:flex-row space-y-4 sm:space-y-0">
                                      <div className="flex sm:flex-col space-y-1 space-x-2 sm:space-x-0 items-baseline sm:items-start">
                                        <span className="text-base font-medium text-light-black tracking-wider">
                                          <Trans>Winning Number</Trans>
                                        </span>
                                        {finishedLotteryState.id === latestFinishedId && (
                                          <span className="w-14 bg-[#F8BE9D] rounded-sm text-center text-white text-[13px] leading-5">
                                            <Trans>Latest</Trans>
                                          </span>
                                        )}
                                      </div>
                                      <div className="flex items-center flex-col sm:flex-row space-y-4 sm:space-y-0">
                                        <div className="flex items-center space-x-2 sm:space-x-[22px] sm:mx-7">
                                          {finishedLotteryState.finalNumber
                                            .toString()
                                            .split('')
                                            .map((i: number, idx: number) => (
                                              <div
                                                key={idx}
                                                className={`w-[38px] h-[38px] sm:w-[58px] sm:h-[58px] rounded-full ${ballColorClasses[idx]}`}
                                              >
                                                <div className="w-[24px] h-[24px] sm:w-[32px] sm:h-[32px] rounded-full bg-[#F5F4F8] bg-opacity-90 transform translate-x-[5px] translate-y-[5px] sm:translate-x-[9px] sm:translate-y-[9px]">
                                                  <span className="flex items-center justify-center w-full h-full text-medigreen text-2xl sm:text-[28px] sm:leading-[28px] font-bold lottery-stroke-text3 transform rotate-[16deg]">
                                                    {i}
                                                  </span>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                        <Disclosure.Button className="text-sm flex font-medium text-ink-green">
                                          <span className="mr-1">
                                            <Trans>Details</Trans>
                                          </span>
                                          <ChevronDown className={`${open ? 'transform rotate-180' : ''}`} width={20} />
                                        </Disclosure.Button>
                                      </div>
                                    </div>
                                  </div>
                                  <Disclosure.Panel className="mt-4 pt-2 bg-white bg-opacity-60 rounded-xl border border-[#F8BE9D]">
                                    <ul className="">
                                      <li className="border-b border-[#F8BE9D] last:border-none py-4">
                                        <div className="px-6 sm:grid sm:grid-cols-5 sm:gap-1 sm:items-center flex flex-col items-start space-y-2 sm:space-y-0">
                                          <span className="text-ink-green text-base font-medium">
                                            <Trans>Prize Pot</Trans>
                                          </span>
                                          <span className="space-x-2 col-span-2">
                                            <span className="text-orange text-2xl leading-4 font-medium tracking-[2.5px]">
                                              $ {displayVal(finishedLotteryState.prizePot, 2)}
                                            </span>
                                            <span className="text-orange text-sm leading-4">
                                              {displayVal(finishedLotteryState.amountCollectedInPPI, 4)} PPI
                                            </span>
                                          </span>
                                          <div className="flex flex-col sm:text-right col-start-4 col-span-2">
                                            <span className="text-ink-green text-opacity-60 text-sm font-normal">
                                              <Trans>
                                                Total tickets this round: {finishedLotteryState.totalTickets}
                                              </Trans>
                                            </span>
                                            <span className="text-ink-green text-opacity-60 text-sm font-normal">
                                              <Trans>
                                                Total roll over: ${displayVal(finishedLotteryState.totalRollOver, 2)}
                                              </Trans>
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                      {finishedLotteryState.rewardBrackets.map((i: any, idx: number) => (
                                        <li key={idx} className="border-b border-[#F8BE9D] last:border-none py-3.5">
                                          <div className="px-6 sm:grid sm:grid-cols-5 sm:gap-1 flex flex-col items-start space-y-2 sm:space-y-0">
                                            <span className="text-ink-green text-base font-medium">{i.name}</span>
                                            <span className="text-bluegreen text-base font-medium">
                                              {displayVal(i.amount, 4)} PPI
                                            </span>
                                            <span className="text-ink-green text-sm">
                                              ~${displayVal(i.usdValue, 2)}
                                            </span>
                                            <span className="text-ink-green text-sm">
                                              <Trans>{displayVal(i.each, 4)} PPI each</Trans>
                                            </span>
                                            <span className="text-ink-green text-sm text-right">
                                              <Trans>{i.count} Winning Tickets</Trans>
                                            </span>
                                          </div>
                                        </li>
                                      ))}
                                      <li className="border-b border-[#F8BE9D] last:border-none py-3.5">
                                        <div className="px-6 sm:grid sm:grid-cols-5 sm:gap-1 flex flex-col items-start space-y-2 sm:space-y-0">
                                          <span className="text-fail text-base font-medium">
                                            <Trans>Burn</Trans>
                                          </span>
                                          <span className="text-bluegreen text-base font-medium">
                                            {displayVal(finishedLotteryState.burn?.amount, 4)} PPI
                                          </span>
                                          <span className="text-ink-green text-sm">
                                            ~${displayVal(finishedLotteryState.burn?.usdValue, 2)}
                                          </span>
                                          <span className="text-ink-green text-sm"></span>
                                          <span className="text-ink-green text-sm text-right"></span>
                                        </div>
                                      </li>
                                    </ul>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-[#FBE1D3] w-full">
            <div className="max-w-[900px] mx-auto px-4 pt-16">
              <div className="text-center">
                <span className="text-[32px] font-medium tracking-[4px] text-diary">
                  <Trans>How to Play</Trans>
                </span>
              </div>

              <div className="box bg-white bg-opacity-40 mt-8 p-8">
                <div className="flex flex-col text-brownie text-sm space-y-4">
                  <span className="">
                    <Trans>
                      Win a portion of the prize pool by matching the winning numbers in the exact order. Simple!
                    </Trans>
                  </span>
                  <span className="flex items-center space-x-3">
                    <span className="rounded-full bg-diary min-w-[12px] h-3"></span>
                    <span>
                      <Trans>Prices are set when the round starts. 1 ticket = $2 in PPI</Trans>
                    </span>
                  </span>
                  <span className="flex items-center space-x-3">
                    <span className="rounded-full bg-diary min-w-[12px] h-3"></span>
                    <span>
                      <Trans>There is one draw every day.</Trans>
                    </span>
                  </span>
                  <span className="flex items-center space-x-3">
                    <span className="rounded-full bg-diary min-w-[12px] h-3"></span>
                    <span>
                      <Trans>Return to this page to check results when the round is over.</Trans>
                    </span>
                  </span>
                </div>
              </div>

              <div className="pt-16 pb-4">
                <span className="text-[32px] font-medium tracking-[4px] text-diary">
                  <Trans>How to Win</Trans>
                </span>
                <div className="flex justify-between mt-6 sm:space-x-5 flex-col sm:flex-row">
                  <div className="flex-col text-brownie text-sm w-full sm:w-[476px] space-y-[24px]">
                    <span>{t`Your ticket's digits must match in the exact order to win.`}</span>
                    <div className="flex flex-col space-y-[18px]">
                      <span>{t`Here's an example of a lottery draw with two tickets.`}</span>
                      <span>
                        {t`Ticket A: The first 3 digits match but the 4th digit is wrong. This ticket only wins a "Match first 3" prize.`}
                      </span>
                      <span>
                        {t`Ticket B: The first digit is incorrect. No prize is awarded even if the last five digits match.`}
                      </span>
                      <span>
                        {t`Prize brackets don't stack. If you match the first 3 digits, you only win prizes from the match 3 bracket.`}
                      </span>
                    </div>
                  </div>
                  <div className="bg-white bg-opacity-40 rounded-3xl w-full sm:w-[360px] mt-4 sm:mt-0">
                    <img src={GuidanceIcon} alt="icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isGetModalOpen && (
        <GetModal
          item={lotteryState}
          onClose={() => {
            setIsGetModalOpen(false)
          }}
          onAfterAction={init}
        />
      )}

      {isDrawModalOpen && (
        <DrawModal
          item={finishedLotteryState}
          userInfoItem={finishedLotteryUserInfoState}
          onClose={() => {
            setIsDrawModalOpen(false)
          }}
          onAction={() => {
            setIsDrawModalOpen(false)
            setIsClaimModalOpen(true)
          }}
        />
      )}

      {isClaimModalOpen && (
        <ClaimModal
          item={finishedLotteryState}
          userInfoItem={finishedLotteryUserInfoState}
          onClose={() => {
            setIsClaimModalOpen(false)
          }}
          onAfterAction={init}
        />
      )}

      {isCheckModalOpen && (
        <CheckModal
          item={lotteryState}
          userInfoItem={lotteryUserInfoState}
          onClose={() => {
            setIsCheckModalOpen(false)
          }}
          onAction={() => {
            setIsCheckModalOpen(false)
            setIsGetModalOpen(true)
          }}
        />
      )}
    </>
  )
}
