const ProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <>
      <div className="bg-[#BDBDBD] bg-opacity-20 rounded-lg relative h-4">
        <div
          className="absolute rounded-lg bg-linear-or h-4"
          style={{ width: `${(percentage * 100).toFixed(0)}%` }}
        ></div>
      </div>
    </>
  )
}

export default ProgressBar
