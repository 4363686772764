import { Trans } from '@lingui/macro'
// eslint-disable-next-line no-restricted-imports
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import HoverInlineText from 'components/HoverInlineText'

import useTheme from '../../hooks/useTheme'
import { ThemedText } from '../../theme'

export function FiatValue({
  fiatValue,
  priceImpact,
}: {
  fiatValue: CurrencyAmount<Currency> | null | undefined
  priceImpact?: Percent
}) {
  const theme = useTheme()

  return (
    <ThemedText.Body fontSize={14} color={fiatValue ? theme.text3 : theme.text4}>
      {fiatValue ? (
        <Trans>
          $
          <HoverInlineText
            text={fiatValue?.toSignificant(6, { groupSeparator: ',' })}
            textColor={fiatValue ? theme.text3 : theme.text4}
          />
        </Trans>
      ) : (
        ''
      )}
    </ThemedText.Body>
  )
}
