// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import HeadlessModal from 'components/Modal/HeadlessModal'
import { NavLink } from 'react-router-dom'
import { displayVal } from 'utils'

export default function Index({ onClose, requiredThreshold }: any) {
  return (
    <>
      <HeadlessModal title={t`Participate`} isOpen={true} onDismiss={onClose}>
        <span className="mt-10 text-fail font-medium">{t`Sorry, you haven't meet ${displayVal(
          requiredThreshold,
          2
        )} score required`}</span>

        <span className="mt-4 text-ink-green">
          <Trans>Please stake PPI to get enough score.</Trans>
        </span>

        <NavLink to={'/staking'} className="mt-20 btn btn-primary justify-center py-3 rounded-xl">
          <Trans>Stake</Trans>
        </NavLink>
      </HeadlessModal>
    </>
  )
}
