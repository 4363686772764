import { Trans } from '@lingui/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { AlertCircle, CheckCircle } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import { useTransaction } from '../../state/transactions/hooks'
import { ExternalLink } from '../../theme'
import { classNames } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { TransactionSummary } from '../AccountDetails/TransactionSummary'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function TransactionPopup({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React()

  const tx = useTransaction(hash)

  if (!tx) return null
  const success = Boolean(tx.receipt && tx.receipt.status === 1)

  return (
    <RowNoFlex className="relative">
      <div
        className={classNames(
          success ? 'bg-windy' : 'bg-pinko',
          'flex w-16 items-center justify-center absolute inset-y-0'
        )}
      >
        <div className="">
          {success ? <CheckCircle color={'#2A3D4A'} size={24} /> : <AlertCircle color={'#2A3D4A'} size={24} />}
        </div>
      </div>

      <AutoColumn gap="8px" className="p-4 relative left-16 mr-20">
        <div className="text-base text-ink-green font-medium">
          <TransactionSummary info={tx.info} />
        </div>
        {chainId && (
          <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
            <Text fontWeight={400} fontSize={12}>
              <Trans>View on Conflux Scan</Trans>
            </Text>
          </ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}
