import { currencyMapping } from 'utils'

import { EVM_SPACE } from './addresses'
import { SupportedChainId } from './chains'
import { tokensOnChains } from './tokens'

const POOLS = EVM_SPACE[SupportedChainId.ESPACE].POOLS as any
const DUAL_POOLS = EVM_SPACE[SupportedChainId.ESPACE].DUAL_POOLS as any

const getTokenByName = (name: string) => {
  const chainId = SupportedChainId.ESPACE
  return currencyMapping({ address: tokensOnChains[chainId][name]['address'] }, chainId)
}

const generatePools = (poolsConfig: any) => {
  return Object.keys(poolsConfig).map((key: string, index: number) => {
    const poolAddress = poolsConfig[key]
    const displayName = key.split(' ')[1]
    const pid = key.split(' ')[2].split('-')[1]

    return {
      pid,
      token: poolAddress,
      pair: {
        displayName,
        token0: getTokenByName(displayName.split('-')[0]),
        token1: getTokenByName(displayName.split('-')[1]),
      },
    }
  })
}

export const pools = generatePools(POOLS)
export const dualPools = generatePools(DUAL_POOLS)
