import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Percent } from '@uniswap/sdk-core'
import { Token } from '@uniswap/sdk-core'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { FeeAmount } from '@uniswap/v3-sdk'
import { providers, Signer } from 'ethers/lib/ethers'
import JSBI from 'jsbi'
import { ChainTokenMap } from 'lib/hooks/useTokenList/utils'

import { EVM_SPACE, V2_ROUTER_ADDRESS } from '../constants/addresses'
import { SupportedChainId } from '../constants/chains'
import { tokensOnChains } from '../constants/tokens'
import { simpleRpcProvider } from './providers'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}
export function getContractFromSigner(address: string, ABI: any, signer?: Signer | providers.Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(chainTokenMap: ChainTokenMap, token?: Token): boolean {
  return Boolean(token?.isToken && chainTokenMap[token.chainId]?.[token.address])
}

export function formattedFeeAmount(feeAmount: FeeAmount): number {
  return feeAmount / 10000
}

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

// Don't re-assign this object as it refers the original object.
// Use its value only or do the shadow copy or deep clone on demand.
export const currencyMapping = (currency: any, chainId: number | undefined) => {
  if (!currency || !chainId) return null

  let re = currency
  const tokens = tokensOnChains[chainId] as any
  Object.keys(tokens).forEach((key: string) => {
    const item = tokens[key]

    if (currency.address && currency.address.toLowerCase() === item.address.toLowerCase()) {
      re = item
    }
  })

  return re
}

export const isTokenAddressOnChain = (address: string, chainId: number | undefined) => {
  if (!address || !chainId) return null

  let isFound = false
  const tokens = tokensOnChains[chainId] as any
  Object.keys(tokens).forEach((key: string) => {
    const item = tokens[key]
    if (address && address.toLowerCase() === item.address.toLowerCase()) {
      isFound = true
    }
  })

  return isFound
}

export const numberWithCommas = (x: number | string) => {
  const idx = x.toString().indexOf('.')
  return idx !== -1
    ? x
        .toString()
        .slice(0, idx)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + x.toString().slice(idx)
    : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const isInteger = (num: number) => {
  return parseInt(num.toString(), 10) === parseFloat(num.toString())
}

export const rangeMapping = ({ oMin, oMax, nMin, nMax, oValue }: any) => {
  const val = ((nMax - nMin) / (oMax - oMin)) * (oValue - oMin) + nMin
  return val < 1e-18 ? 0 : val
}

export const getDisplayName = (token0Symbol: string, token1Symbol: string) => {
  return `${token0Symbol === 'WCFX' ? 'CFX' : token0Symbol}-${token1Symbol === 'WCFX' ? 'CFX' : token1Symbol}`
}

export const getAddressOrName = (address: string, chainId: number | undefined) => {
  if (!chainId) return address

  return address.toLowerCase() === EVM_SPACE[chainId as SupportedChainId].WCFX.toLowerCase() ? 'CFX' : address
}

// account is optional
export function getRouterContract(
  _: number,
  library: Web3Provider,
  chainId: SupportedChainId,
  account?: string
): Contract {
  return getContractFromSigner(V2_ROUTER_ADDRESS[chainId], IUniswapV2Router02ABI, getProviderOrSigner(library, account))
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export const urlPrefix = window.location.protocol + '//' + window.location.host

export function debounce(func: any, wait: number, immediate = false) {
  let timeout: any
  return function (this: any, ...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

// time string should contain timezone, eg: '2022-05-20T23:12:00.000+08:00'
export const getUnixTimeSecond = (time?: string) => {
  const valueOf = time ? new Date(time).valueOf() : new Date().valueOf()
  return parseInt((valueOf / 1000).toString(), 10)
}

// should * 1000 if time is unix time second
export const getTimeString = (time?: number) => {
  return new Date(time ? time : new Date().getTime()).toISOString()
}

export const cdnPrefix = 'https://d3c14dparwtpf4.cloudfront.net'

const truncate = (n: number, decimals = 2) => {
  return Math.trunc(n * 10 ** decimals) / 10 ** decimals
}

export const displayVal = (val: number | undefined, decimals: number, prefix = '') => {
  return val === 0 || !!val ? (prefix ? `${prefix} ` : '') + numberWithCommas(truncate(val, decimals)) : '-'
}
