import { formatUnits } from '@ethersproject/units'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import HeadlessModal from 'components/Modal/HeadlessModal'
import Spinner from 'components/Spinner'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { MAX_INT } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, classNames, displayVal, getProviderOrSigner } from 'utils'

import PPIToken from '../../abi/swappi-farm/PPIToken.sol/PPIToken.json'
import SwappiLottery from '../../abi/swappi-lottery/SwappiLottery.sol/SwappiLottery.json'
import { PPI } from '../../constants/tokens'

const randTicketNumber = () => {
  return 1000000 + Math.floor(Math.random() * 1000000)
}

const getDiscountedCost = (n: number, priceTicketInPPI: number, discountDivisor: number) => {
  return (priceTicketInPPI * n * (discountDivisor + 1 - n)) / discountDivisor
}

const getSiblingPos = (curRow: number, curCol: number, dir: 1 | -1) => {
  let row = curRow
  let col = curCol + dir

  if (dir === 1 && col > 6) {
    col = 1
    row = curRow + 1
  } else if (dir === -1 && col < 1) {
    col = 6
    row = curRow - 1
  }

  return {
    row,
    col,
  }
}

export default function Index({ onClose, item, onAfterAction }: any) {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const [isInnerView, setIsInnerView] = useState(false)
  const [ticketNumbersState, setTicketNumbersState] = useState<number[]>([])
  const [isProcessingState, setIsProcessingState] = useState(false)
  const [hasEnoughAllowanceState, setHasEnoughAllowanceState] = useState(true)

  const [states, setStates] = useState<any>({})

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: 'onChange' })

  const addTransaction = useTransactionAdder()
  const cSwappiLottery = useContract(EVM_SPACE[chainId]?.SwappiLottery, SwappiLottery.abi, false)
  const cPPIToken = useContract(EVM_SPACE[chainId]?.PPI, PPIToken.abi, false)

  const checkAllowance = useCallback(async () => {
    if (!cPPIToken) return

    const { priceTicketInPPI, discountDivisor } = item
    const n = +getValues()['actionAmount'] || 0

    const allowance = await cPPIToken.callStatic.allowance(account, EVM_SPACE[chainId].SwappiLottery)
    const alignedAllowance = +formatUnits(allowance, PPI[chainId].decimals)

    const discountedCost = getDiscountedCost(n, priceTicketInPPI, discountDivisor)

    setHasEnoughAllowanceState(alignedAllowance >= discountedCost)
  }, [chainId, account, cPPIToken, item, getValues])

  const setTicketNumbersForm = useCallback(
    (ticketNumbers: number[]) => {
      const n = +getValues()['actionAmount'] || 0

      for (let i = 0; i < n; i++) {
        const numberStr = ticketNumbers[i].toString()
        for (let j = 0; j < 6; j++) {
          setValue(`ticket-number-${i + 1}-${j + 1}`, numberStr[numberStr.length - 1 - j])
        }
      }
    },
    [setValue, getValues]
  )

  const getAndValidateTicketNumbersForm = useCallback(() => {
    const n = +getValues()['actionAmount'] || 0

    const ticketNumbers = []
    let isValid = true

    for (let i = 0; i < n; i++) {
      let numberStr = ''
      for (let j = 0; j < 6; j++) {
        const inputName = `ticket-number-${i + 1}-${6 - j}`
        const val = getValues()[inputName]
        if (!val) {
          isValid = false
          const inputItem = document.querySelector<HTMLInputElement>(`input[name=${inputName}]`)
          if (inputItem) inputItem.focus()
        } else {
          numberStr += val.toString()
        }
      }
      ticketNumbers.push('1' + numberStr)
    }

    return {
      isValid,
      ticketNumbers,
    }
  }, [getValues])

  const randomize = useCallback(() => {
    const n = +getValues()['actionAmount'] || 0

    if (n) {
      const ticketNumbers = []
      for (let i = 0; i < n; i++) {
        ticketNumbers.push(randTicketNumber())
      }

      setTicketNumbersState(ticketNumbers)
    }
  }, [getValues])

  useEffect(() => {
    if (ticketNumbersState && ticketNumbersState.length > 0) {
      setTicketNumbersForm(ticketNumbersState)
    }
  }, [ticketNumbersState, setTicketNumbersForm])

  useEffect(() => {
    ;(async () => {
      const { priceTicketInPPI } = item
      let maxNumber = 0

      if (cPPIToken && priceTicketInPPI) {
        const tokenBalance = await cPPIToken.callStatic.balanceOf(account)
        const alignedTokenBalance = +formatUnits(tokenBalance, PPI[chainId].decimals)

        const maxCanAfford = parseInt((alignedTokenBalance / priceTicketInPPI).toString(), 10)

        maxNumber =
          maxCanAfford > item.maxNumberTicketsPerBuyOrClaim ? item.maxNumberTicketsPerBuyOrClaim : maxCanAfford
      }

      setStates({
        maxNumber,
      })
    })()
  }, [account, chainId, item, cPPIToken])

  const isInValid = useCallback(() => {
    const n = +getValues()['actionAmount'] || 0

    return n <= 0 || n > item.maxNumberTicketsPerBuyOrClaim
  }, [item.maxNumberTicketsPerBuyOrClaim, getValues])

  const updateStates = useCallback(() => {
    const n = +getValues()['actionAmount'] || 0
    const { priceTicketInPPI, discountDivisor } = item

    const cost = n * priceTicketInPPI
    const discountedCost = getDiscountedCost(n, priceTicketInPPI, discountDivisor)

    const statesItem = {
      maxNumber: states.maxNumber,
      cost,
      savingRatio: 1 - discountedCost / cost,
      discountedCost,
    }
    setStates(statesItem)
  }, [item, getValues, states.maxNumber])

  const approve = useCallback(async () => {
    try {
      if (!library || !cSwappiLottery || !cPPIToken || !account) return

      setIsProcessingState(true)

      const cPPITokenSigner = cPPIToken.connect(getProviderOrSigner(library, account))

      const methodName = 'approve'
      const methodParams = [cSwappiLottery.address, MAX_INT]

      const gasLimit = calculateGasMargin(await cPPITokenSigner.estimateGas[methodName](...methodParams))
      const gasPrice = undefined

      const response = await cPPITokenSigner[methodName](...methodParams, {
        gasLimit,
        gasPrice,
      })
      addTransaction(response, {
        type: TransactionType.APPROVAL,
        spender: cSwappiLottery.address,
        tokenAddress: cPPIToken.address,
      })

      await response.wait()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [library, account, cSwappiLottery, cPPIToken, addTransaction])

  const buy = useCallback(async () => {
    try {
      if (!cSwappiLottery || !account || !library) return

      setIsProcessingState(true)

      const cSwappiLotterySigner = cSwappiLottery.connect(getProviderOrSigner(library, account))

      const ticketNumbersForm = getAndValidateTicketNumbersForm()
      if (!ticketNumbersForm.isValid) return

      const methodName = 'buyTickets'
      const methodParams = [item.currentLotteryId, ticketNumbersForm.ticketNumbers]
      const gasLimit = await cSwappiLotterySigner.estimateGas[methodName](...methodParams)

      const gasPrice = undefined

      const response = await cSwappiLotterySigner[methodName](...methodParams, {
        gasLimit,
        gasPrice,
      })
      addTransaction(response, { type: TransactionType.BUY_TICKETS })

      await response.wait()

      onClose()
      onAfterAction()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [
    cSwappiLottery,
    account,
    library,
    addTransaction,
    getAndValidateTicketNumbersForm,
    item.currentLotteryId,
    onClose,
    onAfterAction,
  ])

  const max = useCallback(async () => {
    if (!states.maxNumber) return

    setValue('actionAmount', states.maxNumber)
    clearErrors('actionAmount')

    updateStates()
    if (states.maxNumber) {
      await checkAllowance()
      randomize()
    }
  }, [setValue, checkAllowance, randomize, updateStates, states.maxNumber, clearErrors])

  return (
    <>
      <HeadlessModal
        title={isInnerView ? t`< Edit Numbers` : t`Buy Tickets`}
        onTitleClick={() => {
          if (isInnerView) setIsInnerView(false)
        }}
        isOpen={true}
        onDismiss={onClose}
      >
        {!isInnerView && (
          <>
            <div className="border border-grass bg-linear-egg p-3 rounded-xl mt-6">
              <div className="flex flex-col space-y-5">
                <div className="flex justify-between text-ink-green text-[13px]">
                  <span className="text-ink-green text-opacity-60">
                    <Trans>Cost</Trans>
                  </span>
                  <span>{displayVal(states.cost, 4, '~')} PPI</span>
                </div>
                <div className="flex justify-between items-center">
                  <input
                    type="number"
                    autoComplete="no"
                    className="w-full text-[22px] text-ink-green bg-transparent disabled:opacity-75 hover:outline-none focus:outline-none"
                    placeholder="0"
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    {...register('actionAmount', {
                      min: 0,
                      max: states.maxNumber,
                      onChange: async (e) => {
                        const n =
                          +e.target.value > item.maxNumberTicketsPerBuyOrClaim
                            ? item.maxNumberTicketsPerBuyOrClaim
                            : +e.target.value === 0
                            ? ''
                            : +e.target.value

                        setValue('actionAmount', n)

                        updateStates()
                        if (n) {
                          await checkAllowance()
                          randomize()
                        }
                      },
                    })}
                  />
                  <span className="flex items-center">
                    <span className="text-opacity-80 text-base font-normal text-ink-green">
                      <Trans>Tickets</Trans>
                    </span>
                    <span className="flex">
                      <button className="btn btn-default text-xs ml-2 py-0.5 rounded bg-bluegreen" onClick={max}>
                        <Trans>Max</Trans>
                      </button>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <span className="text-[13px] text-orange">
                <Trans>Max of {displayVal(item.maxNumberTicketsPerBuyOrClaim, 0)} tickets per purchase</Trans>
              </span>
            </div>
          </>
        )}

        <div className="border border-grass bg-while py-2 px-3 rounded-xl mt-5">
          <div className="flex flex-col space-y-1.5">
            <div className="flex justify-between text-ink-green text-[13px]">
              <span className="text-ink-green text-opacity-60">
                <Trans>Cost (PPI)</Trans>
              </span>
              <span>{displayVal(states.cost, 4)} PPI</span>
            </div>
            <div className="flex justify-between border-b border-grass border-dashed text-[13px] pb-2.5">
              <div className="">
                <span className="text-orange mr-1">
                  {!isNaN(states.savingRatio) ? parseFloat((states.savingRatio * 100).toFixed(3).slice(0, -1)) : '-'}%
                </span>
                <span className="text-ink-green text-opacity-60">
                  <Trans>Bulk discount</Trans>
                </span>
              </div>
              <span>{displayVal(states.cost - states.discountedCost, 4, '~')} PPI</span>
            </div>
            <div className="flex justify-between text-ink-green text-sm font-medium">
              <span>
                <Trans>You pay</Trans>
              </span>
              <span>{displayVal(states.discountedCost, 4, '~')} PPI</span>
            </div>
          </div>
        </div>

        <div className={classNames(isInnerView ? 'flex flex-col' : 'hidden')}>
          <div className="text-medigreen text-xs mt-4">
            <Trans>
              Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits:
              0-9
            </Trans>
          </div>

          <button
            className="gradient-border-golden-green p-2.5 flex justify-center tracking-widest font-medium mt-8"
            onClick={randomize}
          >
            <span className="gradient-text-golden-green">
              <Trans>Randomize</Trans>
            </span>
          </button>

          <div className="flex flex-col space-y-2 mt-4">
            {ticketNumbersState.map((n: number, idx: number) => (
              <div key={idx} className="border border-grass rounded-xl bg-linear-egg py-1 px-3">
                <div className="flex items-center space-x-2 sm:space-x-6">
                  <span className="text-xs font-normal text-medigreen min-w-[35px]">#{idx + 1}</span>
                  <div className="flex space-x-6 sm:space-x-7 text-ink-green text-[22px] leading-[30px] font-medium">
                    {[...Array(6).keys()].map((i: number) => (
                      <input
                        key={i}
                        type="number"
                        autoComplete="no"
                        className="w-[16px] text-center bg-transparent disabled:opacity-75 hover:outline-none focus:outline-none"
                        defaultValue={n.toString()[6 - i]}
                        onKeyDown={(e) => {
                          if (e.key === 'Backspace') {
                            setValue(`ticket-number-${idx + 1}-${i + 1}`, '')
                            e.preventDefault()
                          } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                            const pos = getSiblingPos(idx + 1, i + 1, e.key === 'ArrowLeft' ? -1 : 1)
                            const sibling = document.querySelector<HTMLInputElement>(
                              `input[name=ticket-number-${pos.row}-${pos.col}]`
                            )
                            if (sibling) sibling.focus()

                            e.preventDefault()
                          } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || !/[0-9]/.test(e.key)) {
                            e.preventDefault()
                          } else {
                            setValue(`ticket-number-${idx + 1}-${i + 1}`, '')
                          }
                        }}
                        {...register(`ticket-number-${idx + 1}-${i + 1}`, {
                          onChange: async (e) => {
                            setValue(`ticket-number-${idx + 1}-${i + 1}`, e.target.value)

                            const nextPos = getSiblingPos(idx + 1, i + 1, 1)
                            const nextSibling = document.querySelector<HTMLInputElement>(
                              `input[name=ticket-number-${nextPos.row}-${nextPos.col}]`
                            )
                            if (nextSibling) nextSibling.focus()
                          },
                        })}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {!isInnerView && (
          <>
            <div className="text-medigreen text-xs mt-4">
              <Trans>
                {`"Buy Instantly" chooses random numbers, with no duplicates among your tickets. Prices are set before each round starts, equal to $2 at that time. Purchases are final.`}
              </Trans>
            </div>
            {hasEnoughAllowanceState ? (
              <>
                {!states.maxNumber || errors.actionAmount?.type === 'max' ? (
                  <button disabled className="btn btn-disabled mt-4 justify-center py-3 rounded-xl">
                    <Trans>Insufficient PPI balance</Trans>
                  </button>
                ) : (
                  <>
                    <button
                      className={classNames(
                        isInValid() ? 'btn-disabled' : '',
                        'btn btn-primary mt-4 justify-center py-3 rounded-xl'
                      )}
                      onClick={async () => {
                        if (!isInValid() && !isProcessingState) {
                          await buy()
                        }
                      }}
                    >
                      {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
                      <Trans>Buy Instantly</Trans>
                    </button>
                    <button
                      className={classNames(
                        isInValid() ? 'btn-disabled opacity-30' : '',
                        'btn btn-gray mt-4 justify-center py-3 rounded-xl'
                      )}
                      onClick={() => {
                        if (!isInValid()) setIsInnerView(true)
                      }}
                    >
                      <Trans>View/Edit Numbers</Trans>
                    </button>
                  </>
                )}
              </>
            ) : (
              <button
                className={classNames(
                  isProcessingState ? 'cursor-not-allowed' : '',
                  'btn btn-primary mt-4 justify-center py-3 rounded-xl'
                )}
                disabled={isProcessingState}
                onClick={async () => {
                  if (!isProcessingState) {
                    await approve()
                    await checkAllowance()
                  }
                }}
              >
                {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
                <Trans>Approve</Trans>
              </button>
            )}
          </>
        )}

        {isInnerView && (
          <div className="flex justify-between mt-4 space-x-6">
            <button
              className="btn btn-primary flex flex-1 justify-center rounded-xl py-2.5 whitespace-nowrap"
              onClick={async () => {
                if (!isProcessingState) {
                  await buy()
                }
              }}
            >
              {isProcessingState && <Spinner className="ml-1 mr-3 text-white" />}
              <Trans>Confirm & Buy</Trans>
            </button>
            <button
              className="btn btn-secondary flex flex-1 justify-center rounded-xl py-2.5"
              onClick={() => {
                setIsInnerView(false)
              }}
            >
              <Trans>Go Back</Trans>
            </button>
          </div>
        )}
      </HeadlessModal>
    </>
  )
}
