import { Trans } from '@lingui/macro'
import { ExternalLink as LinkIcon } from 'react-feather'

export default function AnnouncementBannerBottom() {
  return (
    <div className="flex lg:hidden absolute bottom-[-100px] -ml-2">
      <div className="flex w-full">
        <div className="flex items-center">
          <div className="text-xs space-y-1">
            <div className="flex">
              <span className="text-orange">
                <Trans>Bridge Your Assets</Trans>
              </span>
              <div className="flex text-medigreen ml-1">
                <span>from Conflux Core:</span>
              </div>
              <a
                className="flex items-center text-bluegreen underline ml-1"
                target="_blank"
                href="https://confluxhub.io/cross-space"
                rel="noreferrer"
              >
                <span>
                  <Trans>confluxhub.io</Trans>
                </span>
                <LinkIcon size={12} />
              </a>
            </div>
            <div className="flex text-medigreen">
              <span>from Ethereum and Others:</span>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="flex space-x-1">
                <a
                  className="flex items-center space-x-0.5 text-bluegreen underline"
                  target="_blank"
                  href="https://cbridge.celer.network/"
                  rel="noreferrer"
                >
                  <span>
                    <Trans>cbridge.celer.network</Trans>
                  </span>
                  <LinkIcon size={12} />
                </a>
                <a
                  className="flex items-center space-x-0.5 text-bluegreen underline"
                  target="_blank"
                  href="https://meson.fi"
                  rel="noreferrer"
                >
                  <span>
                    <Trans>meson.fi</Trans>
                  </span>
                  <LinkIcon size={12} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
