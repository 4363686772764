const getChainIdFromEnv = (): number | undefined => {
  return process.env.REACT_APP_NETWORK_CHAIN_ID ? parseInt(process.env.REACT_APP_NETWORK_CHAIN_ID, 10) : undefined
}

export const ESPACE_MAINNET_CHAIN_ID = 1030
export const ESPACE_TESTNET_CHAIN_ID = 71

export const isESpaceMainnet = () => {
  return getChainIdFromEnv() === ESPACE_MAINNET_CHAIN_ID
}

export enum SupportedChainId {
  ESPACE = isESpaceMainnet() ? ESPACE_MAINNET_CHAIN_ID : ESPACE_TESTNET_CHAIN_ID,
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [SupportedChainId.ESPACE]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [SupportedChainId.ESPACE] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]
