import { parseUnits } from '@ethersproject/units'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import HeadlessModal from 'components/Modal/HeadlessModal'
import QuestionHelper from 'components/QuestionHelper'
import Spinner from 'components/Spinner'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useAllowance, useApprove } from 'hooks/useAllowance'
import { useContract } from 'hooks/useContract'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { useCallback, useEffect, useState } from 'react'
import { CheckCircle } from 'react-feather'
import { useForm } from 'react-hook-form'

import { TransactionType } from '.../../state/transactions/actions'
import SwappiIdoplatform from '../../../../abi/swappi-idoplatform/idoplatform.sol/idoplatform.json'
import { EVM_SPACE } from '../../../../constants/addresses'
import { SupportedChainId } from '../../../../constants/chains'
import { ExtendedEther, WCFX } from '../../../../constants/tokens'
import { useTransactionAdder } from '../../../../state/transactions/hooks'
import { calculateGasMargin, classNames, displayVal } from '../../../../utils'
import { AuctionStageEnum } from '../../utils'

const isNumberKey = (e: any) => {
  const charCode = e.which ? e.which : e.keyCode
  if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false

  return true
}

export default function Index({ onClose, item, info, onAfterAction, isGenesisNftHolder }: any) {
  const activeWeb3React = useActiveWeb3React()
  const { account } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const requiredThreshold = Math.max(
    0,
    item.alignedVeTokenThreshold - (isGenesisNftHolder ? item.alignedNFTThreshold : 0)
  )

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: 'onChange' })
  const addTransaction = useTransactionAdder()

  const wcfxToken = WCFX[chainId]
  const contractAddress = EVM_SPACE[chainId]?.SwappiIdoplatform
  const { allowance, setHasOutdated } = useAllowance({
    token: wcfxToken,
    account,
    contractAddress,
  })

  const { approve, isProcessing: isApprovingState } = useApprove({ tokenAddress: wcfxToken.address, contractAddress })

  const cSwappiIdoplatformWithSigner = useContract(contractAddress, SwappiIdoplatform.abi)

  const [willPayAmountState, setwillPayAmountState] = useState('')
  const [isProcessingState, setIsProcessingState] = useState(false)
  const [inputMaximumState, setInputMaximumState] = useState(0)
  const [actionErrorState, setActionErrorState] = useState('')

  const CFX = new ExtendedEther(chainId)
  const cfxBalanceStr = useCurrencyBalance(account ?? undefined, CFX)?.toExact()
  const cfxBalance = cfxBalanceStr ? +cfxBalanceStr : 0

  const hasEnoughAllowance = useCallback(() => {
    const actionAmount = +getValues()['actionAmount'] || 0

    return allowance >= actionAmount
  }, [allowance, getValues])

  const isInputValid = useCallback(() => {
    const actionAmount = +getValues()['actionAmount'] || 0

    return (
      actionAmount > 0 &&
      (item.currentStage === AuctionStageEnum.privateSale ? actionAmount <= inputMaximumState : true) &&
      new BigNumber(willPayAmountState).lte(new BigNumber(cfxBalance))
    )
  }, [getValues, cfxBalance, inputMaximumState, item.currentStage, willPayAmountState])

  const updatewillPayAmountState = useCallback(() => {
    const actionAmount = new BigNumber(getValues()['actionAmount']).toFixed(18, 1)

    if (actionAmount === 'NaN' || !item.alignedPriPrice || !item.alignedPubPrice) return

    const price =
      item.currentStage === AuctionStageEnum.privateSale
        ? item.alignedPriPrice
        : item.currentStage === AuctionStageEnum.publicSale
        ? item.alignedPubPrice
        : 0

    if (price > 0) {
      const willPay = new BigNumber(actionAmount).multipliedBy(price).toFixed(18, 1)
      setwillPayAmountState(willPay)
    }
  }, [getValues, item.alignedPriPrice, item.alignedPubPrice, item.currentStage])

  const commit = useCallback(async () => {
    try {
      if (!cSwappiIdoplatformWithSigner || !info) return

      setIsProcessingState(true)

      const actionAmount = new BigNumber(getValues()['actionAmount']).toFixed(18, 1)
      if (actionAmount === 'NaN' || willPayAmountState === 'NaN') return

      const methodName =
        item.currentStage === AuctionStageEnum.privateSale
          ? 'privateSale'
          : item.currentStage === AuctionStageEnum.publicSale
          ? 'publicSale'
          : ''
      if (!methodName) return

      const methodParams = [info.address, parseUnits(actionAmount, info.decimals)]
      const value = parseUnits(willPayAmountState, wcfxToken.decimals)

      const gasLimit = calculateGasMargin(
        await cSwappiIdoplatformWithSigner.estimateGas[methodName](...methodParams, { value })
      )

      const gasPrice = undefined

      const response = await cSwappiIdoplatformWithSigner[methodName](...methodParams, {
        gasLimit,
        gasPrice,
        value,
      })
      addTransaction(response, {
        type: TransactionType.COMMIT_AUCTION,
        symbol: info.symbol,
        amount: +actionAmount,
      })

      await response.wait()

      onClose()
      onAfterAction()
    } catch (error) {
      if (error?.code === 4001) return
      setActionErrorState(error?.message ?? 'Unknown error.')
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [
    addTransaction,
    cSwappiIdoplatformWithSigner,
    getValues,
    info,
    item.currentStage,
    onAfterAction,
    onClose,
    wcfxToken.decimals,
    willPayAmountState,
  ])

  const max = useCallback(() => {
    if (!inputMaximumState) return

    setValue('actionAmount', inputMaximumState)
    clearErrors('actionAmount')
  }, [clearErrors, inputMaximumState, setValue])

  useEffect(() => {
    ;(async () => {
      if (!item.currentStage) return
      setInputMaximumState(
        item.currentStage === AuctionStageEnum.privateSale ? item.alignedMaxValidAmountInPrivateSale : item.alignedAmt
      )
    })()
  }, [item.alignedAmt, item.alignedMaxValidAmountInPrivateSale, item.currentStage])

  return (
    <>
      <HeadlessModal title={t`Participate`} isOpen={true} onDismiss={onClose}>
        <div className="border border-grass bg-linear-egg p-3 rounded-xl mt-6">
          <div className="flex flex-col space-y-2">
            <div className="flex justify-between text-ink-green text-[13px] items-center">
              <span className="text-ink-green text-opacity-60">
                <Trans>Commit</Trans>
              </span>
              <div className="flex items-center text-ink-green">
                <span>
                  <Trans>
                    {displayVal(
                      (item.currentStage === AuctionStageEnum.privateSale
                        ? item.alignedMaxValidAmountInPrivateSale
                        : item.alignedAmt) || undefined,
                      4
                    )}{' '}
                    {info.symbol}
                  </Trans>
                </span>
                <button
                  className="btn btn-default text-xs ml-2 py-0.5 rounded bg-bluegreen"
                  onClick={() => {
                    max()
                    updatewillPayAmountState()
                  }}
                >
                  <Trans>Max Buy</Trans>
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center space-x-1">
              {!!inputMaximumState ? (
                <input
                  type="text"
                  onKeyPress={(e) => !isNumberKey(e) && e.preventDefault()}
                  autoComplete="no"
                  className="w-full text-[22px] text-ink-green bg-transparent disabled:opacity-75 hover:outline-none focus:outline-none"
                  placeholder="0"
                  {...register('actionAmount', {
                    min: 0,
                    max: inputMaximumState,
                    onChange: async (e) => {
                      setValue('actionAmount', e.target.value)

                      updatewillPayAmountState()
                    },
                  })}
                />
              ) : (
                <input
                  type="number"
                  autoComplete="no"
                  className="w-full text-[22px] text-ink-green bg-transparent disabled:opacity-75 hover:outline-none focus:outline-none"
                  placeholder="0"
                  disabled={true}
                />
              )}
              <div className="flex items-center space-x-1">
                <img src={info?.icon} alt="icon" className="w-[18px] h-[18px] min-w-[18px] rounded-full" />
                <span className="text-ink-green text-[15px] font-medium">{info?.symbol}</span>
              </div>
            </div>
          </div>
        </div>

        {!!actionErrorState && (
          <div className="mt-2">
            <span className="text-[13px] text-fail capitalize">{actionErrorState}</span>
          </div>
        )}

        <div className="border border-grass bg-while py-2 px-3 rounded-xl mt-5 mb-3">
          <div className="flex flex-col space-y-1.5">
            {item.currentStage === AuctionStageEnum.privateSale && (
              <div className="flex justify-between border-b border-grass border-dashed text-[13px] pb-2 items-baseline">
                <span className="text-ink-green text-opacity-60">
                  <Trans>Requirement</Trans>
                </span>
                <div className="flex items-center space-x-1 text-bluegreen">
                  <span>
                    {item?.isInWhitelist ? (
                      <Trans>Whitelist Users</Trans>
                    ) : (
                      <span>
                        {displayVal(requiredThreshold ?? 0, 2)} Score required
                        {isGenesisNftHolder && (
                          <span className="inline-flex translate-y-[-1px]">
                            <QuestionHelper
                              color=" rgb(77, 175, 158)"
                              text={`Genesis NFT Holder offers, originally {item.alignedVeTokenThreshold} score required.`}
                            />
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                  <CheckCircle width={12} />
                </div>
              </div>
            )}
            <div className="flex justify-between text-ink-green text-sm font-medium">
              <span>
                <Trans>You pay</Trans>
              </span>
              <span>{displayVal(+willPayAmountState, wcfxToken.decimals)} CFX</span>
            </div>
          </div>
        </div>

        {hasEnoughAllowance() ? (
          <>
            {!inputMaximumState ||
            errors.actionAmount?.type === 'max' ||
            cfxBalance <= 0 ||
            new BigNumber(willPayAmountState).gt(new BigNumber(cfxBalance)) ? (
              <button disabled className="btn btn-disabled mt-4 justify-center py-3 rounded-xl">
                {cfxBalance <= 0 || new BigNumber(willPayAmountState).gt(new BigNumber(cfxBalance)) ? (
                  <Trans>Insufficient CFX balance</Trans>
                ) : (
                  <>
                    {(+getValues()['actionAmount'] || 0) > inputMaximumState ? (
                      <Trans>Amount exceed max</Trans>
                    ) : (
                      <Trans>Insufficient IDO token balance</Trans>
                    )}
                  </>
                )}
              </button>
            ) : (
              <button
                className={classNames(
                  isInputValid() ? '' : 'btn-disabled',
                  'btn btn-primary mt-6 justify-center py-3 rounded-xl'
                )}
                disabled={!isInputValid() || isProcessingState}
                onClick={commit}
              >
                {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
                <Trans>Commit</Trans>
              </button>
            )}
          </>
        ) : (
          <button
            className={classNames(
              isApprovingState ? 'cursor-not-allowed' : '',
              'btn btn-primary mt-6 justify-center py-3 rounded-xl'
            )}
            disabled={isApprovingState}
            onClick={async () => {
              if (!isApprovingState) {
                await approve()
                setHasOutdated(true)
              }
            }}
          >
            {isApprovingState && <Spinner className="-ml-1 mr-3 text-white" />}
            <Trans>Approve</Trans>
          </button>
        )}
      </HeadlessModal>
    </>
  )
}
