import { Menu, Transition } from '@headlessui/react'
import { Trans } from '@lingui/macro'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { classNames } from 'utils'

import { isMobile } from '../../utils/userAgent'

export default function NavLinkSelector() {
  const [hash, setHash] = useState<string>('')

  const updateHash = useCallback(() => {
    const path = window.location.hash
    if (!path.startsWith('#/')) setHash('')
    setHash(window.location.hash.substring(1))
  }, [])

  useEffect(() => {
    updateHash()
    window.addEventListener('hashchange', updateHash, false)
    return () => {
      window.removeEventListener('hashchange', updateHash, false)
    }
  }, [updateHash])

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={classNames(
            isMobile ? 'font-normal dark:text-white p-2 rounded-xl' : 'font-medium dark:text-white text-ink-green',
            hash === '/farming' || hash === '/dual-farming'
              ? isMobile
                ? 'text-[#4daf9e] bg-[#f0fcf8]'
                : 'text-[#7fbf4e]'
              : 'text-ink-green'
          )}
        >
          <Trans>Farming</Trans>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            isMobile ? 'top-auto left-[-8px] bottom-[46px]' : 'left-[-14px] top-[47px]',
            'absolute w-max rounded-md shadow-lg bg-white focus:outline-none'
          )}
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  id={`farming-nav-link`}
                  to={'/farming'}
                  className={classNames(
                    hash === '/farming' ? (isMobile ? 'text-[#4daf9e]' : 'text-[#7fbf4e]') : 'text-gray-700',
                    active ? 'bg-gray-100' : '',
                    isMobile ? 'font-normal' : 'font-medium',
                    'block px-4 py-2 dark:text-white text-ink-green'
                  )}
                >
                  <Trans>Farming</Trans>
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  id={`dual-farming-nav-link`}
                  to={'/dual-farming'}
                  className={classNames(
                    hash === '/dual-farming' ? (isMobile ? 'text-[#4daf9e]' : 'text-[#7fbf4e] ') : 'text-gray-700',
                    active ? 'bg-gray-100' : '',
                    isMobile ? 'font-normal' : 'font-medium',
                    'block px-4 py-2 dark:text-white text-ink-green'
                  )}
                >
                  <Trans>Dual Farming</Trans>
                </NavLink>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
