// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import HeadlessModal from 'components/Modal/HeadlessModal'
import Spinner from 'components/Spinner'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { useCallback, useMemo, useState } from 'react'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, classNames, displayVal, getProviderOrSigner, shortenAddress } from 'utils'

import SwappiLottery from '../../abi/swappi-lottery/SwappiLottery.sol/SwappiLottery.json'
import YayyIcon from '../../assets/images/yayy.png'

const getRewardBracket = (rewardBrackets: any, winningTicket: any) => {
  return rewardBrackets.find((i: any, idx: number) => idx + 1 === winningTicket.matches)
}

export default function Index({ onClose, item, userInfoItem, onAfterAction }: any) {
  const activeWeb3React = useActiveWeb3React()
  const { account, library } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const addTransaction = useTransactionAdder()
  const cSwappiLottery = useContract(EVM_SPACE[chainId]?.SwappiLottery, SwappiLottery.abi, false)

  const [isProcessingState, setIsProcessingState] = useState(false)

  const winningTickets = useMemo(() => {
    return userInfoItem.userTickets
      .filter((i: any) => i.matches > 0)
      .map((i: any) => ({
        ...i,
        rewardBracket: getRewardBracket(item.rewardBrackets, i),
      }))
  }, [item, userInfoItem])

  const totalPrize = useMemo(() => {
    return winningTickets.reduce((memo: number, current: any) => {
      return memo + current.rewardBracket.each
    }, 0)
  }, [winningTickets])

  const areAllClaimed = useMemo(() => {
    return winningTickets.filter((i: any) => i.claimed === false).length === 0
  }, [winningTickets])

  const claim = useCallback(async () => {
    try {
      if (!cSwappiLottery || !account || !library) return

      setIsProcessingState(true)

      const cSwappiLotterySigner = cSwappiLottery.connect(getProviderOrSigner(library, account))

      const methodName = 'claimTickets'
      const methodParams = [
        item.id,
        winningTickets.map((i: any) => i.id),
        winningTickets.map((i: any) => i.matches - 1),
      ]
      const gasLimit = calculateGasMargin(await cSwappiLotterySigner.estimateGas[methodName](...methodParams))

      const gasPrice = undefined

      const response = await cSwappiLotterySigner[methodName](...methodParams, {
        gasLimit,
        gasPrice,
      })
      addTransaction(response, {
        type: TransactionType.CLAIM,
        recipient: shortenAddress(account),
        uniAmountRaw: totalPrize,
      })

      await response.wait()

      onClose()
      onAfterAction()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [cSwappiLottery, account, library, addTransaction, item, winningTickets, totalPrize, onClose, onAfterAction])

  return (
    <>
      <HeadlessModal title={t`Collect Winnings`} isOpen={true} onDismiss={onClose}>
        <span className="mt-6 text-ink-green text-opacity-60 font-normal">
          <Trans>You Won</Trans>
        </span>

        <div className="flex flex-col items-center space-y-3">
          <img src={YayyIcon} alt="icon" className="w-[168px] mt-3" />
          <span className="text-limo font-medium text-[40px]">{displayVal(totalPrize, 4)} PPI</span>
        </div>

        <div className="border border-grass py-2 px-2.5 rounded-xl mt-7">
          <div className="border-b border-dashed border-grass pb-2">
            <div className="flex flex-col space-y-1">
              {winningTickets.map((i: any, idx: number) => (
                <div key={idx} className="flex justify-between text-[13px]">
                  <div className="flex space-x-2 text-ink-green text-opacity-60">
                    <span># {i.id}</span>
                    <span>
                      <Trans>{i.rewardBracket.name}</Trans>
                    </span>
                  </div>
                  <span className="text-ink-green">{displayVal(i.rewardBracket.each, 4)} PPI</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between text-[15px] text-ink-green font-medium mt-2">
            <span>
              <Trans>Total Prize</Trans>
            </span>
            <div className="">
              {areAllClaimed && (
                <span className="mr-2 text-limo">
                  <Trans>(Claimed)</Trans>
                </span>
              )}
              <span>{displayVal(totalPrize, 4)} PPI</span>
            </div>
          </div>
        </div>

        <button
          className={classNames(
            areAllClaimed ? 'btn-disabled' : '',
            'btn btn-primary mt-8 justify-center py-3 rounded-xl'
          )}
          onClick={() => {
            if (!isProcessingState && !areAllClaimed) {
              claim()
            }
          }}
        >
          {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
          <Trans>Claim</Trans>
        </button>
      </HeadlessModal>
    </>
  )
}
