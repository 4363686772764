import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Fraction, Percent } from '@uniswap/sdk-core'
import { Text } from 'rebass'

import CurrencyLogo from '../../components/CurrencyLogo'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  return (
    <>
      <div className="border border-grass p-4 rounded-xl mt-4 space-y-1.5">
        <RowBetween>
          <div className="text-ink-green text-opacity-60 text-sm flex">
            <Trans>{currencies[Field.CURRENCY_A]?.symbol} Deposited</Trans>
            <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_A]} style={{ marginLeft: '8px' }} />
          </div>
          <RowFixed>
            <div className="text-ink-green text-sm flex">{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</div>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <div className="text-ink-green text-opacity-60 text-sm flex">
            <Trans>{currencies[Field.CURRENCY_B]?.symbol} Deposited</Trans>
            <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_B]} style={{ marginLeft: '8px' }} />
          </div>
          <RowFixed>
            <div className="text-ink-green text-sm flex">{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</div>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <div className="text-ink-green text-opacity-60 text-sm flex">
            <Trans>Rates</Trans>
          </div>
          <div className="text-ink-green text-sm flex">
            {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
              currencies[Field.CURRENCY_B]?.symbol
            }`}
          </div>
        </RowBetween>
        <RowBetween style={{ justifyContent: 'flex-end' }}>
          <div className="text-ink-green text-sm flex">
            {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
              currencies[Field.CURRENCY_A]?.symbol
            }`}
          </div>
        </RowBetween>
        <RowBetween>
          <div className="text-ink-green text-opacity-60 text-sm flex">
            <Trans>Share of Pool:</Trans>
          </div>
          <div className="text-ink-green text-sm flex">
            <Trans>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</Trans>
          </div>
        </RowBetween>
      </div>

      <button className="btn btn-primary py-3 flex justify-center" onClick={onAdd}>
        <Text>{noLiquidity ? <Trans>Create Pool & Supply</Trans> : <Trans>Confirm Supply</Trans>}</Text>
      </button>
    </>
  )
}
