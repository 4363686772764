import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber, BigNumberish } from '@ethersproject/bignumber'
import { MaxUint256, Zero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { formatUnits, parseUnits } from '@ethersproject/units'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import HeadlessModal from 'components/Modal/HeadlessModal'
import Spinner from 'components/Spinner'
import { EVM_SPACE } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { LOCK_DURATION_OPTIONS } from 'constants/misc'
import dayjs from 'dayjs'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useApproveCallback } from 'hooks/useApproveCallback'
import { useTokenContract } from 'hooks/useContract'
import useGasPrice from 'hooks/useGasPrice'
import JSBI from 'jsbi'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { classNames, getProviderOrSigner } from 'utils'
import { calculateGasMargin } from 'utils/calculateGasMargin'

type PPIWithBalance = {
  contract: any
  token: Token
  name: string
  symbol: string
  decimals: number
  balance: string
} | null
/**
 *
 * @param tokenAddress token address
 * @returns PPIWithBalance
 */
export const usePPIWithBalance = (tokenAddress?: string | undefined): PPIWithBalance => {
  const { account, chainId } = useActiveWeb3React()
  const PPIContract = useTokenContract(tokenAddress, false)

  const [result, setResult] = useState(null as PPIWithBalance)
  useEffect(() => {
    const getPPIResult = async () => {
      try {
        if (!account || !PPIContract || !tokenAddress) {
          setResult(null)
        } else {
          const [name, symbol, decimals, balance] = await Promise.all([
            PPIContract.name(),
            PPIContract.symbol(),
            PPIContract.decimals(),
            PPIContract.balanceOf(account),
          ])
          const token = new Token(chainId as number, tokenAddress, +decimals as any, symbol, name)
          setResult({
            contract: PPIContract,
            token,
            name,
            symbol,
            decimals: +decimals,
            balance: formatUnits(balance, +decimals),
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    getPPIResult()
  }, [account, PPIContract, chainId, tokenAddress])
  return useMemo(() => result, [result])
}

enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

enum ModalMode {
  CreateLock,
  IncreaseAmount,
  IncreaseUnlockTime,
}

const getSafeNumberString = (value: string, unitName?: BigNumberish | undefined) => {
  if (!value || !unitName) return ''
  const re = new RegExp(`(\\d+\\.\\d{${unitName}})(\\d+)`)
  value = value.replace(re, '$1')
  return value
}

export default function StakeModal({
  isOpen,
  farmControllerContract,
  votingEscrowContract,
  disabledAmount,
  disabledLocktime,
  currentUnlockTime,
  onConfirm,
  onDismiss,
}: {
  isOpen: boolean
  farmControllerContract?: Contract | null
  votingEscrowContract?: Contract | null
  disabledAmount?: boolean
  disabledLocktime?: boolean
  currentUnlockTime?: number
  onConfirm: (...args: any[]) => void
  onDismiss: () => void
}) {
  const { account, chainId, library } = useActiveWeb3React()
  const PPIData = usePPIWithBalance(EVM_SPACE[chainId as SupportedChainId]?.PPI)
  const [lockAmountDisplayed, setLockAmountDispleyed] = useState('0')
  const modalMode = useMemo(() => {
    if (!disabledAmount && !disabledLocktime) return ModalMode.CreateLock
    if (!!disabledAmount) return ModalMode.IncreaseUnlockTime
    return ModalMode.IncreaseAmount
  }, [disabledAmount, disabledLocktime])

  const {
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: 'onChange' })

  const [selectedDuration, setSelectedDuration] = useState('no selected')
  useEffect(() => {
    if (!!isOpen && !disabledLocktime) {
      setValue('lockDuration', LOCK_DURATION_OPTIONS[0].value, { shouldValidate: true })
      setSelectedDuration(LOCK_DURATION_OPTIONS[0].text)
    }
  }, [setValue, isOpen, disabledLocktime])
  const lockAmountBigNumber = useMemo(() => {
    return lockAmountDisplayed && !isNaN(lockAmountDisplayed as any)
      ? parseUnits(lockAmountDisplayed, PPIData?.token.decimals)
      : ''
  }, [PPIData?.token.decimals, lockAmountDisplayed])
  const lockAmountBigintIsh = lockAmountBigNumber ? JSBI.BigInt(lockAmountBigNumber.toString()) : ''
  const unlockDuration = LOCK_DURATION_OPTIONS.find(({ text, value }) => text === selectedDuration)

  const rawAmount: CurrencyAmount<Token> | undefined =
    PPIData?.token && lockAmountBigintIsh
      ? CurrencyAmount.fromRawAmount(PPIData?.token as Token, lockAmountBigintIsh)
      : undefined
  const [approvalState, approve] = useApproveCallback(rawAmount, EVM_SPACE[chainId as SupportedChainId]?.VotingEscrow)

  const [methodName, methodParams, disabledSubmit, unlockTime] = useMemo(() => {
    let methodName,
      methodParams: any[] = [],
      unlockTime,
      disabledSubmit = true
    const currentTimestamp: number = Math.ceil(new Date().valueOf() / 1000)
    const SECONDS_IN_WEEK: number = 7 * 24 * 3600

    if (modalMode === ModalMode.CreateLock) {
      methodName = 'createLock'
      // unlockTime = unlockDuration?.value ? Math.ceil(new Date().valueOf() / 1000) + unlockDuration?.value : null
      unlockTime = unlockDuration?.value
        ? Math.ceil((currentTimestamp + unlockDuration?.value) / SECONDS_IN_WEEK) * SECONDS_IN_WEEK
        : null
      methodParams = [lockAmountBigNumber, unlockTime]
      disabledSubmit = !lockAmountBigNumber || !unlockTime || !+lockAmountBigNumber
    } else if (modalMode === ModalMode.IncreaseUnlockTime) {
      methodName = 'increaseUnlockTime'
      unlockTime = currentUnlockTime && unlockDuration?.value ? currentUnlockTime + unlockDuration?.value : null
      methodParams = [unlockTime]
      disabledSubmit = !unlockTime
    } else if (modalMode === ModalMode.IncreaseAmount) {
      methodName = 'increaseAmount'
      methodParams = [account, lockAmountBigNumber]
      disabledSubmit = !account || !lockAmountBigNumber || !+lockAmountBigNumber
    }
    return [methodName, methodParams, disabledSubmit, unlockTime]
  }, [unlockDuration?.value, modalMode, lockAmountBigNumber, currentUnlockTime, account])

  const formattedUnlockTime = useMemo(
    () => (unlockTime ? dayjs.unix(unlockTime).format('YYYY-MM-DD HH:mm:ss') : null),
    [unlockTime]
  )

  const gasPrice = useGasPrice()
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const addTransaction = useTransactionAdder()
  const txType = useMemo(() => {
    if (modalMode === ModalMode.CreateLock) return TransactionType.CREATE_LOCK
    if (modalMode === ModalMode.IncreaseAmount) return TransactionType.INCREASE_AMOUNT
    if (modalMode === ModalMode.IncreaseUnlockTime) return TransactionType.INCREASE_UNLOCK_TIME
    return TransactionType.STAKING
  }, [modalMode])

  const onSubmit = useCallback(async () => {
    if (!methodName || !!disabledSubmit || !methodParams || !methodParams.length) return
    if (!library || !account) return

    const votingEscrowContractWriter = votingEscrowContract?.connect(getProviderOrSigner(library, account))

    const estimateGasFunction = votingEscrowContractWriter?.estimateGas[methodName]
    if (!estimateGasFunction) return
    const safeGasEstimate: BigNumber | undefined = await estimateGasFunction(...methodParams)
      .then(calculateGasMargin)
      .catch((err) => {
        console.error(`estimateGas failed`, methodName, methodParams, err)
        return undefined
      })

    setAttemptingTxn(true)
    await votingEscrowContractWriter?.[methodName](...methodParams, {
      gasLimit: safeGasEstimate,
      gasPrice,
    })
      .then((response: TransactionResponse) => {
        setAttemptingTxn(false)
        addTransaction(response, { type: txType })
        onConfirm(response, true)
      })
      .catch((err: Error) => {
        setAttemptingTxn(false)
        console.error(err)
      })
  }, [
    methodName,
    disabledSubmit,
    methodParams,
    votingEscrowContract,
    gasPrice,
    addTransaction,
    txType,
    onConfirm,
    account,
    library,
  ])

  const onApprovePacked = useCallback(
    // listen to approve tx
    async (response?: TransactionResponse) => {
      const _temp = lockAmountDisplayed
      response?.wait().then(() => {
        setLockAmountDispleyed('')
        setTimeout(() => setLockAmountDispleyed(_temp), 200)
      })
    },
    [lockAmountDisplayed]
  )

  const errMsg = useMemo(() => {
    if (errors?.lockAmount?.type === 'validate' && errors?.lockAmount?.message) {
      return errors?.lockAmount?.message
    }
    if (errors?.lockDuration?.type === 'validate' && errors?.lockDuration?.message) {
      return errors?.lockDuration?.message
    }
    return ''
  }, [errors?.lockAmount?.message, errors?.lockAmount?.type, errors?.lockDuration?.message, errors?.lockDuration?.type])

  return (
    <HeadlessModal title={'Stake PPI'} isOpen={isOpen} onDismiss={onDismiss}>
      <div className="mt-5">
        {!disabledAmount && (
          <div className="rounded-xl border border-[#D9ECCA] p-3 flex justify-between from-[#FAFAEA] to-[#FBFFDF] bg-gradient-to-r">
            <div className="flex flex-col w-full relative">
              <div className="flex justify-between items-center">
                <label className="text-xs opacity-60">
                  <Trans>Lock Amount</Trans>
                </label>

                <div className="flex items-center">
                  <span className="text-xs">
                    <Trans>Balance: {PPIData ? ` ${PPIData.balance}` : ` 0`}</Trans>
                  </span>
                  {PPIData?.balance && +PPIData.balance > 0 && (
                    <button
                      id="staking-button-6"
                      className="btn btn-default text-xs ml-2 py-0.5 rounded bg-bluegreen"
                      onClick={() => {
                        if (PPIData) {
                          setValue('lockAmount', PPIData.balance)
                          clearErrors('lockAmount')
                          setLockAmountDispleyed(PPIData.balance)
                        }
                      }}
                    >
                      <Trans>Max</Trans>
                    </button>
                  )}
                </div>
              </div>
              <div className="flex justify-between mt-6">
                <input
                  type="text"
                  autoComplete="no"
                  className="w-full text-lg text-[#2A3D4A] font-medium bg-transparent disabled:opacity-75 hover:outline-none focus:outline-none"
                  placeholder="0"
                  disabled={disabledAmount}
                  {...register('lockAmount', {
                    required: !!disabledAmount,
                    validate: (value: string) => {
                      if (!!disabledAmount) return true
                      if (isNaN(value as any)) return t`Amount should be a number`
                      if (+value <= 0) return t`Amount should greater than zero`
                      const decimals = PPIData?.token.decimals
                      if (decimals) value = getSafeNumberString(value, decimals)
                      const valueInBN =
                        value && PPIData?.token.decimals ? parseUnits(value, PPIData?.token.decimals) : Zero
                      const balanceInBN =
                        PPIData?.balance && PPIData?.token.decimals
                          ? parseUnits(PPIData?.balance, PPIData?.token.decimals)
                          : MaxUint256
                      return valueInBN?.lte(balanceInBN) ? true : t`Amount exceed max`
                    },
                    onChange: async (e) => {
                      let value = e.target.value
                      const decimals = PPIData?.token.decimals
                      if (!isNaN(value) && decimals) {
                        value = getSafeNumberString(value, decimals)
                        // is number string, should check decimals longer than token decimals or not
                        setLockAmountDispleyed(value)
                      } else {
                        setLockAmountDispleyed(value)
                      }
                    },
                  })}
                />

                <span>PPI</span>
              </div>
              {/* {errors?.lockAmount?.type === 'validate' && (
                <div className="absolute left-0 -bottom-2.5 flex justify-between mt-1">
                  <small className="text-red-400">{errors?.lockAmount?.message}</small>
                </div>
              )} */}
            </div>
          </div>
        )}
        {!disabledLocktime && (
          <>
            <div className="rounded-xl border border-[#D9ECCA] p-3 flex justify-between from-[#FAFAEA] to-[#FBFFDF] bg-gradient-to-r mt-3">
              <div className="flex flex-col w-full relative">
                <div className="flex justify-between">
                  <label className="text-xs opacity-60">
                    <Trans>Lock Duration</Trans>
                  </label>
                </div>
                <div className="flex justify-between mt-2">
                  <span className="text-base text-[#2A3D4A] font-medium">{selectedDuration}</span>
                </div>
                <div className="flex justify-between mt-2">
                  <span className="text-xs text-[#2A3D4A] font-normal">
                    <Trans>Lock until {formattedUnlockTime || '-'}</Trans>
                  </span>
                </div>
                {/* {errors?.lockDuration?.type === 'validate' && (
                  <div className="absolute left-0 -bottom-2.5 flex justify-between mt-1">
                    <small className="text-red-400">{errors?.lockDuration?.message}</small>
                  </div>
                )} */}
              </div>
            </div>
            <fieldset className="mt-3">
              {LOCK_DURATION_OPTIONS.map(({ text, value }) => {
                return (
                  <label htmlFor={text} key={text}>
                    <input
                      hidden
                      type="radio"
                      value={value}
                      id={text}
                      name="lockDuration"
                      {...(register('lockDuration'),
                      {
                        required: !!disabledLocktime,
                        validate: (value: number) => {
                          if (!!disabledLocktime) return true
                          return !!value ? true : t`Lock duration is required`
                        },
                        onChange: (e) => {
                          setSelectedDuration(text)
                        },
                      })}
                    />
                    <span
                      className={classNames(
                        'inline-block btn cursor-pointer text-base font-normal rounded-full border border-gray-400 hover:border-gray-300 mr-2 mb-2 leading-5 tracking-normal',
                        selectedDuration === text ? 'from-[#FAFAEA] to-[#FBFFDF] bg-gradient-to-r' : ''
                      )}
                    >
                      {text}
                    </span>
                  </label>
                )
              })}
            </fieldset>
          </>
        )}
      </div>
      {errMsg ? (
        <div className="mt-4 flex justify-between w-full space-x-2">
          <button
            id="staking-button-7"
            className={classNames(
              'btn btn-primary w-full flex justify-center py-2 text-base leading-7',
              'cursor-not-allowed opacity-20'
            )}
            disabled={true}
          >
            {errMsg}
          </button>
        </div>
      ) : approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING ? (
        <div className="mt-4 flex justify-between w-full space-x-2">
          <button
            id="staking-button-8"
            className={classNames(
              'btn btn-primary w-full flex justify-center py-2 text-base leading-7',
              approvalState === ApprovalState.PENDING ? 'cursor-not-allowed opacity-50' : 'hover:opacity-90'
            )}
            disabled={approvalState === ApprovalState.PENDING}
            onClick={() => approve().then((res) => onApprovePacked(res))}
          >
            {approvalState === ApprovalState.PENDING && <Spinner className="-ml-1 mr-3 text-white" />}
            <Trans>Approve PPI</Trans>
          </button>
        </div>
      ) : (
        <div className="mt-4 flex justify-between w-full space-x-2">
          <button
            id="staking-button-9"
            className="btn btn-secondary w-full flex justify-center py-2 text-base leading-6 border-2 border-[#2A3D4A] hover:opacity-90"
            onClick={() => onDismiss()}
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            id="staking-button-10"
            className={classNames(
              'btn btn-primary w-full flex justify-center py-2 text-base leading-7',
              disabledSubmit || approvalState === ApprovalState.UNKNOWN
                ? 'cursor-not-allowed opacity-50'
                : 'hover:opacity-90'
            )}
            disabled={disabledSubmit || approvalState === ApprovalState.UNKNOWN}
            onClick={() => onSubmit()}
          >
            {attemptingTxn && <Spinner className="-ml-1 mr-3 text-white" />}
            <Trans>Confirm</Trans>
          </button>
        </div>
      )}
    </HeadlessModal>
  )
}
