// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import HeadlessModal from 'components/Modal/HeadlessModal'
import Spinner from 'components/Spinner'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { useCallback, useState } from 'react'

import { TransactionType } from '.../../state/transactions/actions'
import SwappiIdoplatform from '../../../../abi/swappi-idoplatform/idoplatform.sol/idoplatform.json'
import { EVM_SPACE } from '../../../../constants/addresses'
import { SupportedChainId } from '../../../../constants/chains'
import { useTransactionAdder } from '../../../../state/transactions/hooks'
import { calculateGasMargin, classNames, displayVal, shortenAddress } from '../../../../utils'

export default function Index({ onClose, item, info, onAfterAction }: any) {
  const activeWeb3React = useActiveWeb3React()
  const { account } = activeWeb3React
  const chainId = activeWeb3React.chainId as SupportedChainId

  const contractAddress = EVM_SPACE[chainId]?.SwappiIdoplatform
  const cSwappiIdoplatformWithSigner = useContract(contractAddress, SwappiIdoplatform.abi)
  const addTransaction = useTransactionAdder()

  const [isProcessingState, setIsProcessingState] = useState(false)

  const claim = useCallback(async () => {
    try {
      if (!cSwappiIdoplatformWithSigner || !account || !item.idoId || !info) return

      setIsProcessingState(true)

      const methodName = 'claimAllTokens'
      const methodParams = [info.address, item.idoId]

      const gasLimit = calculateGasMargin(await cSwappiIdoplatformWithSigner.estimateGas[methodName](...methodParams))

      const gasPrice = undefined

      const response = await cSwappiIdoplatformWithSigner[methodName](...methodParams, {
        gasLimit,
        gasPrice,
      })
      addTransaction(response, {
        type: TransactionType.CLAIM,
        recipient: shortenAddress(account),
        uniAmountRaw: item.alignedClaimableAmt,
      })

      await response.wait()

      onClose()
      onAfterAction()
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessingState(false)
    }
  }, [
    account,
    addTransaction,
    cSwappiIdoplatformWithSigner,
    info,
    item.alignedClaimableAmt,
    item.idoId,
    onAfterAction,
    onClose,
  ])

  return (
    <>
      <HeadlessModal title={t`Claim Tokens`} isOpen={true} onDismiss={onClose}>
        <div className="mt-6">
          <span className="text-bluegreen">
            Token Sale is Finished!
          </span>
        </div>

        <div className="mt-6">
          <span className="text-ink-green text-opacity-60">
            Your rewards:
          </span>
        </div>

        <div className="mt-4 mx-auto text-center flex items-baseline space-x-2">
          <span className="text-limo text-[40px]">{displayVal(item.alignedClaimableAmt, 4)}</span>
          <span className="text-ink-green">{info.symbol}</span>
        </div>

        <button
          className={classNames(
            item.alignedClaimableAmt > 0 ? '' : 'btn-disabled',
            'btn btn-primary mt-7 justify-center py-3 rounded-xl'
          )}
          disabled={item.alignedClaimableAmt <= 0 || isProcessingState}
          onClick={claim}
        >
          {isProcessingState && <Spinner className="-ml-1 mr-3 text-white" />}
          Claim
        </button>
      </HeadlessModal>
    </>
  )
}
